import { render, staticRenderFns } from "./CDismissDialog.vue?vue&type=template&id=3340f25c&scoped=true&"
import script from "./CDismissDialog.vue?vue&type=script&lang=ts&"
export * from "./CDismissDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3340f25c",
  null
  
)

export default component.exports