import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdiSvg",
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                accent: "#878787",
                delete: "#FF5252",
                error: "#FF5252",
                info: "#2196F3",
                primary: "#0f5384",
                secondary: "#e6e4e4",
                success: "#4CAF50",
                summary: "#f7f7f7",
                test: "#0f5384",
                warning: "#FFC107",
            },
            // dark: {
            //     primary: '#878787',
            //     secondary: '#e6e4e4',
            //     accent: '#0f5384',
            //     test: '#0f5384',
            //     error: '#FF5252',
            //     info: '#2196F3',
            //     success: '#4CAF50',
            //     warning: '#FFC107',
            //     delete: '#FF5252'
            // }
        },
    },
});
