import { DateTime } from "luxon";
import { ICustomerEmailDto } from "./ICustomerEmailDto";
import { ICustomerEmailItemViewModel } from "./ICustomerEmailItemViewModel";

export class CustomerEmailItemViewModel implements ICustomerEmailItemViewModel {
    public id = 0;
    public sendDate = "";
    public ecomOrderId = "";
    public emailFrom = "";
    public emailTo = "";
    public emailBcc = "";
    public emailSubject = "";
    public storeID = 0;

    public constructor(dto?: ICustomerEmailDto) {
        if (dto) {
            this.id = dto.id;
            this.sendDate = `${DateTime.fromISO(dto.sendDate).toFormat("MM/dd/yyyy hh:mm a")} `;
            this.ecomOrderId = dto.ecomOrderId;
            this.emailFrom = dto.emailFrom;
            this.emailTo = dto.emailTo;
            this.emailBcc = dto.emailBcc;
            this.emailSubject = dto.emailSubject;
            this.storeID = dto.storeID;
        }
    }
}
