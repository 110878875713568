import { ITextValue } from "chipply-common";

export enum ZeroSellPriceDisplay {
    DisplayAsZero = 0,
    DoNotDisplay = 1,
    CustomLabel = 2,
}

export class ZeroSellPriceDisplayHelper {
    public static getList(): ITextValue<number>[] {
        return [
            {
                text: "Display As $0",
                value: ZeroSellPriceDisplay.DisplayAsZero,
            },
            {
                text: "Do Not Display",
                value: ZeroSellPriceDisplay.DoNotDisplay,
            },
            { text: "Custom Label", value: ZeroSellPriceDisplay.CustomLabel },
        ];
    }
}
