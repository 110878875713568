import { IColorAssignment } from "@/chipply/interface/IColorAssignment";
import { IColorAssignmentGroup } from "@/chipply/store/color-assignment/IColorAssignmentGroup";
import { ViewModel } from "chipply-common";

export class ColorAssignmentViewModel extends ViewModel {
    public id = "";
    public isChanged = false;
    public artName = "";
    public colorName = "";
    public standardColor = "";
    public standardColorId = 0;
    public eventArtworkId = 0;
    public eventArtworkVariationId = 0;
    public variationImage: string | null = null;
    public colorSelected = false;
    public originalValues: { [key: string]: any } = {};

    constructor(model: IColorAssignment) {
        super();
        this.id = model.id;
        this.artName = model.artName;
        this.colorName = model.colorName;
        this.eventArtworkId = model.eventArtworkId;
        this.eventArtworkVariationId = model.eventArtworkVariationId;
        this.variationImage = model.variationImage;
        this.standardColor = model.standardColor;
        this.standardColorId = model.standardColorId;
    }

    public toModel(): IColorAssignment {
        return {
            id: this.id,
            artName: this.artName,
            colorName: this.colorName,
            eventArtworkId: this.eventArtworkId,
            eventArtworkVariationId: this.eventArtworkVariationId,
            variationImage: this.variationImage,
            isChanged: this.isChanged,
            standardColor: this.standardColor,
            standardColorId: this.standardColorId,
        };
    }
}
