
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    name: "CSummaryCard",
    props: {
        label: String,
        value: [String, Number],
    },
})
export default class CSummaryCard extends Vue {
    @Prop()
    loading!: boolean;
}
