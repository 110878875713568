import { AsyncInteractionViewModel, FieldType } from "chipply-common";

export class AddFieldViewModel extends AsyncInteractionViewModel {
    public FieldType = FieldType;

    public get fieldTypes() {
        return [
            { text: "Text", value: FieldType.Text },
            { text: "Yes/No", value: FieldType.Boolean },
            { text: "List", value: FieldType.List },
        ];
    }

    public selectedFieldType: FieldType | null = null;
}
