import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import valid from "card-validator";
import { mask } from "vue-the-mask";
let CCreditCard = class CCreditCard extends Vue {
    validateCardNumber(val) {
        const validationResults = valid.number(val);
        if (validationResults.isPotentiallyValid) {
            return true;
        }
        return "You must enter a valid card number";
    }
    validateCardName(val) {
        const validationResult = valid.cardholderName(val);
        if (validationResult.isValid) {
            return true;
        }
        return "You must enter a valid cardholder name";
    }
    validateExpiration(val) {
        const validationResult = valid.expirationDate(val);
        if (validationResult.isValid) {
            return true;
        }
        return "You must enter a valid expiration";
    }
    expirationInput(evt) {
        const number = Number(evt.key);
        if (Number.isNaN(number)) {
            return;
        }
        const input = evt.currentTarget;
        if (input.value.length > 0) {
            return;
        }
        if (number > 1 && number < 10) {
            input.value = "0" + number.toString() + "/";
            evt.preventDefault();
        }
    }
};
CCreditCard = __decorate([
    Component({
        directives: { mask },
        props: {
            card: Object,
            enableAutocomplete: Boolean,
            disabled: Boolean,
        },
    })
], CCreditCard);
export default CCreditCard;
