import IAutomatedMergeTemplate from "@/chipply/merge/IAutomatedMergeTemplate";
import IMergeTemplateSms from "@/chipply/merge/IMergeTemplateSms";
import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import IMergeEmailRecipient from "@/chipply/merge/IMergeEmailRecipient";
import { MessageType } from "@/chipply/merge/MessageType";

export abstract class AutomatedMergeTemplate implements IAutomatedMergeTemplate {
    public abstract $typeHint: string;
    public bccRecipients: IMergeEmailRecipient[] = [];
    public days = 0;
    public emailContent = "";
    public hours = 0;
    public id = 0;
    public messageTypes: MessageType[] = [];
    public prettyPrintDateTime: string | null = null;
    public replyToRecipients: IMergeEmailRecipient[] = [];
    public sms: IMergeTemplateSms | null = null;
    public staticDate: string | null = null;
    public staticTime: string | null = null;
    public subject = "";
    public templateName = "";
    public toRecipients: IMergeEmailRecipient[] = [];
    public trigger: EmailTemplateTriggerTypeEnum = EmailTemplateTriggerTypeEnum.StaticDate;
}
