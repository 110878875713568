


















































































































































import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import ErrorReporter from "@/components/utility/ErrorReporter.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { Prop, Watch } from "vue-property-decorator";
import CChildPane from "@/components/ui/CChildPane.vue";
@Component({
    components: {
        TextHeading,
        LongRunningOperationDialog,
        ErrorReporter,
        CChildPane,
    },
    props: {
        errorMessage: String,
        isChildPaneVisible: Boolean,
        isLoading: Boolean,
        showBack: Boolean,
        statusMessage: String,
        noScroll: Boolean,
    },
})
export default class EditLayout extends mixins(ScrollableContentMixin) {
    public name = "EditLayout";
    public chipplyIcons = chipplyIcons;
    public errorMessage!: string;
    public errorReporterOpened = false;
    public isChildPaneVisible!: boolean;

    @Prop({
        default: true,
    })
    public declare allowScroll: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public hasNoTopNavigation!: boolean;
    public noTopNavigation = false;

    public raiseBack() {
        this.$emit("back");
    }

    public created() {
        this.noTopNavigation = this.hasNoTopNavigation;
    }

    @Watch("hasNoTopNavigation")
    public hasNoTopNavigationChanged() {
        this.noTopNavigation = this.hasNoTopNavigation;
    }

    public data() {
        return {};
    }
    @Watch("errorMessage")
    protected onErrorMessageChanged() {
        if (this.errorMessage) {
            this.errorReporterOpened = true;
        } else {
            this.errorReporterOpened = false;
        }
    }

    protected errorReporterChange(nv: boolean) {
        this.$emit("error-reporter-change", nv);
    }
}
