















import { EventType } from "@/chipply/event/EventType";
import IBatchedCopyEventArgs from "@/chipply/event/IBatchedCopyEventArgs";
import IServerStatus from "@/chipply/IServerStatus";
import { Utils, WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import * as userInfoStore from "@/store/UserInfoStore";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import StoreCreationOptions from "@/components/events/StoreCreationOptions.vue";

@Component({
    components: { LongRunningOperationDialog, StoreCreationOptions },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        copyArgs: Object,
        eventId: Number,
        eventName: String,
        eventType: String,
        value: Boolean,
    },
})
export default class EventCopier extends Vue {
    public eventId: number | undefined;
    public eventName: string | undefined;
    public eventType: string | undefined;
    public selectedEventId: number | undefined;
    public selectedEventName: string | undefined;
    public selectedEventType: string | undefined;
    public selectedCopyArgs: IBatchedCopyEventArgs | undefined;
    public value!: boolean;
    public showCopyingDialog!: boolean;
    public showStoreOptionsDialog!: boolean;
    public copyArgs: IBatchedCopyEventArgs | undefined;
    public name = "EventCopier";
    public statusMessage!: string | undefined;
    public userId!: number;

    public async created() {
        await userInfoStore.initialize(this.$store);
        this.userId = userInfoStore.getUserId(this.$store);
    }

    public data() {
        return {
            selectedEventId: this.eventId,
            selectedEventName: this.eventName,
            selectedEventType: this.eventType,
            showCopyingDialog: false,
            showStoreOptionsDialog: false,
            statusMessage: "",
            userId: 0,
        };
    }

    @Watch("eventName")
    protected onEventNameChanged() {
        this.selectedEventName = this.eventName;
    }

    @Watch("eventType")
    protected onEventTypeChanged() {
        this.selectedEventType = this.eventType;
    }

    @Watch("eventId")
    protected onEventIdChanged() {
        this.selectedEventId = this.eventId;
    }

    @Watch("value")
    protected async onShowChanged(nv: boolean) {
        if (!nv) {
            return;
        }

        if (this.copyArgs) {
            await this.beginCopyEvent(this.copyArgs);
        } else {
            this.showStoreOptionsDialog = true;
        }
    }

    protected async copyEvent(copyArgs: IBatchedCopyEventArgs): Promise<IBatchedCopyEventArgs> {
        if (!copyArgs.serverStatus) {
            this.statusMessage = "Copying store...";
        } else {
            if (copyArgs.serverStatus.workCompleted || copyArgs.serverStatus.errorOccurred) {
                return copyArgs;
            }
            this.statusMessage = copyArgs.serverStatus.statusMessage;
        }
        const jsonResponse = await WebHelper.postJsonData(`/api/Events/CopyEvent`, copyArgs);
        copyArgs = JSON.parse(jsonResponse);
        return await this.copyEvent(copyArgs);
    }

    private async copyStore(args: { storeName: string; eventType: number; accepted: boolean }) {
        const newStoreName = args.storeName;
        const newEventType = Number(args.eventType);
        const copyFromEventId = Number(this.selectedEventId);

        this.showStoreOptionsDialog = false;
        this.selectedEventId = undefined;

        if (!args.accepted) {
            this.$emit("change", false);
            return;
        }

        const copyArgs: IBatchedCopyEventArgs = {
            copyFromEventId,
            copyToEventType: newEventType,
            newEventName: newStoreName,
        };

        await this.beginCopyEvent(copyArgs);
    }

    private async beginCopyEvent(copyArgs: IBatchedCopyEventArgs) {
        this.showCopyingDialog = true;
        const returnedArgs = await this.copyEvent(copyArgs);
        const serverStatus = returnedArgs.serverStatus as IServerStatus;

        if (serverStatus.errorMessage) {
            this.statusMessage = Utils.ServerErrorMessage;
            return;
        }
        if (serverStatus.workCompleted) {
            this.statusMessage = serverStatus.statusMessage;

            document.location.href = "/ng/settings.html?eventid=" + returnedArgs.copyToEventId;
        }
    }

    @Watch("copyArgs")
    private async onCopyArgsChanged(newValue: IBatchedCopyEventArgs, oldValue: IBatchedCopyEventArgs) {
        if (oldValue === newValue) {
            return;
        }

        await this.beginCopyEvent(this.copyArgs as IBatchedCopyEventArgs);
    }
}
