import { DateTime } from "luxon";
import { Serializer } from "./Serializer";

export class WebHelper {
    public static async getJsonDataBlob(path: string, noStore?: boolean) {
        let options: RequestInit | undefined;
        noStore = noStore ?? true; // Default to not cache, caching usually creates more problems for us than it solves
        if (noStore) {
            options = { cache: "no-store" };
        }

        const response = await fetch(path, options);
        if (response.status === 401) {
            location.assign(WebHelper.loginUrl);
        }

        return await response.blob();
    }

    public static async getJsonData(path: string, noStore?: boolean) {
        let options: RequestInit | undefined;

        noStore = noStore ?? true; // Default to not cache, caching usually creates more problems for us than it solves
        if (noStore) {
            options = { cache: "no-store" };
        }

        const response = await fetch(path, options);
        if (response.status === 401) {
            location.assign(WebHelper.loginUrl);
        }

        const textResponse = await response.text();
        return Serializer.deserialize(textResponse);
    }

    public static async delete(path: string, data?: object) {
        const serializedData = data ? JSON.stringify(data) : null;
        const init: RequestInit = { method: "DELETE", body: serializedData, headers: { "Content-Type": "text/json" } };
        const response = await fetch(path, init);

        if (!response.ok) {
            if (response.status === 401) {
                location.assign(WebHelper.loginUrl);
            }

            const err = await response.json();
            throw err;
        }

        return await response.text();
    }

    public static async postJsonDataBlob(path: string, data?: object) {
        const response = await this.sendPost(path, data);
        return await response.blob();
    }

    public static async postJsonData(path: string, data?: object) {
        const response = await this.sendPost(path, data);
        return await response.text();
    }

    public static async postJson<TResult>(path: string, data?: object) {
        const response = await this.sendPost(path, data);
        return Serializer.deserialize<TResult>(await response.text());
    }

    public static async post(path: string, data: BodyInit) {
        const response = await this.sendPost(path, data, false);
        return await response.text();
    }

    protected static async sendPost(path: string, data?: object | BodyInit, isJson = true) {
        data = data || {};
        const body = isJson ? JSON.stringify(data) : (data as BodyInit);
        const init: RequestInit = { method: "POST", body: body };
        if (isJson) {
            init.headers = { "Content-Type": "text/json" };
        }
        const response = await fetch(path, init);

        if (!response.ok) {
            if (response.status === 401) {
                location.assign(WebHelper.loginUrl);
            }

            const err = await response.json();
            throw err;
        }

        return response;
    }

    public static downloadAsAttachment(blob: Blob, fileName: string) {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
    }

    private static loginUrl = "/Default.aspx?expired=1";

    public static getDate(): DateTime {
        return DateTime.local();
    }
}
