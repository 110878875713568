import SpreadsheetProductViewModel from "@/chipply/view-model/SpreadsheetProductViewModel";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class SelectorMixin extends Vue {
    public selected!: Array<{ [index: string]: number }>;
    public items!: Array<{ [index: string]: number }>;

    @Prop({ default: false, type: Boolean })
    public multipleSelect!: boolean;

    public disabledIds!: number[];
    public initialSelectedIds!: number[];
    public selectedIds: number[] = this.initialSelectedIds || [];
    public loading!: boolean;

    public getItemIdPath(): string {
        throw new Error("You must override getItemIdPath to use the SelectorMixin");
    }

    public created() {
        const idsCopy = [];
        if (this.initialSelectedIds) {
            idsCopy.push(...this.initialSelectedIds);
        }
        this.selectedIds = idsCopy;
    }

    public check(item: { [index: string]: number }, currentValue: boolean) {
        if (currentValue) {
            this.selected.push(item);
        } else {
            const removalIndex = this.selected.indexOf(item);
            this.selected.splice(removalIndex, 1);
        }
    }

    protected rowClicked(row: { [index: string]: number }, args: any) {
        if (this.multipleSelect) {
            return;
        }
        this.$emit("close", true, row.eventProductId);
    }

    protected tileClicked(productId: number) {
        if (this.multipleSelect) {
            return;
        }
        this.$emit("close", true, productId);
    }

    protected resetSelectedItems() {
        const selectedItems = [];
        for (const item of this.items) {
            if (this.selectedIds.indexOf(item[this.getItemIdPath()]) > -1) {
                selectedItems.push(item);
            }
        }

        this.selected = selectedItems;
    }

    @Watch("selected")
    protected onSelectedChanged() {
        if (this.loading) {
            return;
        }

        if (this.selected.length === 0) {
            this.selectedIds.splice(0);
            return;
        }

        const currentItemIds: number[] = [];
        this.items.forEach((v) => currentItemIds.push(v[this.getItemIdPath()]));
        const currentPageSelectedItemIds: number[] = [];
        this.selected.forEach((v) => currentPageSelectedItemIds.push(v[this.getItemIdPath()]));

        const selectedProductIdsCopy = [];
        selectedProductIdsCopy.push(...this.selectedIds);
        for (let i = 0; i < selectedProductIdsCopy.length; i++) {
            const id = selectedProductIdsCopy[i];
            if (currentItemIds.indexOf(id) === -1) {
                continue;
            }
            if (currentPageSelectedItemIds.indexOf(id) === -1) {
                this.selectedIds.splice(i, 1);
            }
        }

        for (const selectedVm of this.selected) {
            if (this.selectedIds.indexOf(selectedVm[this.getItemIdPath()]) > -1) {
                continue;
            }
            this.selectedIds.push(selectedVm[this.getItemIdPath()]);
        }
    }

    protected close(accepted: boolean) {
        this.$emit("close", accepted, this.selectedIds);
    }

    protected isDisabled(id: number) {
        return (this.disabledIds || []).indexOf(id) > -1;
    }

    protected checkAll(currentValue: boolean) {
        const newSelected = [];
        for (const item of this.items) {
            if (this.isDisabled(item[this.getItemIdPath()])) {
                if (this.initialSelectedIds.indexOf(item[this.getItemIdPath()]) > -1) {
                    newSelected.push(item);
                }
            } else if (currentValue) {
                newSelected.push(item);
            }
        }
        this.selected = newSelected;
    }
}
