



























































































































































































import Component from "vue-class-component";
import Vue from "vue";
import CDate from "@/components/ui/CDate.vue";
import { DateTime } from "luxon";
import Validation from "@/validation";
import IPurchasingViewModel from "@/chipply/purchasing/IPurchasingViewModel";

@Component({
    props: {
        vm: Object,
        step: Number,
        isPurchaseOrderVisible: Boolean,
    },
    components: {
        CDate,
    },
})
export default class PurchasingFiltersEditor extends Vue {
    public vm!: IPurchasingViewModel;
    public step!: number;
    public isPurchaseOrderVisible!: boolean;
    public Validation = Validation;
    public vendorSearchInput: string | null = null;

    public dateBeforeToDate(value: string) {
        if (!this.vm.filters.ordersToDate) {
            return true;
        }
        if (!this.vm.filters.ordersFromDate) {
            return true;
        }
        const toDate = DateTime.fromISO(this.vm.filters.ordersToDate);
        const fromDate = DateTime.fromISO(this.vm.filters.ordersFromDate);
        return toDate > fromDate ? true : "PO From Date must be before PO To Date";
    }
}
