import IArtwork from "@/chipply/process/IArtwork";
import { AsyncInteractionViewModel } from "chipply-common";

export class AddBoxViewModel extends AsyncInteractionViewModel {
    public isValid = false;
    public positions: IArtwork[] = [];
    public selectedPosition = 0;
    public constructor() {
        super();
    }
}
