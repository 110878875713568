import { IAddress } from "./IAddress";

export class Address implements IAddress {
    public firstName = "";
    public lastName = "";
    public companyName = "";
    public addressLine1 = "";
    public addressLine2 = "";
    public city = "";
    public state: string | null = null;
    public zipCode: string | null = null;
    public country = "";

    public constructor(address?: IAddress) {
        if (!address) {
            return;
        }
        this.firstName = address.firstName;
        this.lastName = address.lastName;
        this.companyName = address.companyName;
        this.addressLine1 = address.addressLine1;
        this.addressLine2 = address.addressLine2;
        this.city = address.city;
        this.state = address.state;
        this.zipCode = address.zipCode;
        this.country = address.country;
    }
}
