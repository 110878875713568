import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import { IListViewModel } from "@/chipply/view-model/IListViewModel";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import _ from "lodash";
import PageState from "@/chipply/PageState";

export abstract class ListPageViewModel extends PageViewModel implements IListViewModel {
    public dataTableFixedHeader = true;
    public dataTableDisableSort = false;
    public dataTableMultiSort = false;
    public abstract dataTableHeaders: object[];
    public abstract dataTableItemKey: string;
    public dataTableLoading = false;
    public dataTableShowExpand = false;
    public dataTableShowSelect = false;
    public hideDefaultFooter = true;
    public isDetailViewVisible = false;
    public dataTableDisablePagination = true;
    public abstract items: object[];
    public dataTableExpandedItems: object[] = [];
    public abstract pagination: IVuetifyTableOptions;
    public selectedItems: object[] = [];
    public serverItemsLength: number | null = null;
    public abstract pageState: PageState;
    public abstract list(isNewSearch?: boolean): Promise<any>;

    protected isPaginationEquals(a: IVuetifyTableOptions, b: IVuetifyTableOptions) {
        if (_.isEqual(a, b)) {
            return true;
        }

        if (!a || !b) {
            return false;
        }

        if (a.page != b.page) {
            return false;
        }
        if (a.itemsPerPage != b.itemsPerPage) {
            return false;
        }
        if ((!a.multiSort && b.multiSort == true) || (a.multiSort == true && !b.multiSort)) {
            return false;
        }

        if ((!a.mustSort && b.mustSort == true) || (a.mustSort == true && !b.mustSort)) {
            return false;
        }

        if ((!a.sortBy && b.sortBy && b.sortBy.length > 0) || (a.sortBy && a.sortBy.length > 0 && !b.sortBy)) {
            return false;
        }
        if (a.sortBy && b.sortBy && a.sortBy.join(",") != b.sortBy.join(",")) {
            return false;
        }

        if (
            (!a.sortDesc && b.sortDesc && b.sortDesc.length > 0) ||
            (a.sortDesc && a.sortDesc.length > 0 && !b.sortDesc)
        ) {
            return false;
        }

        if (a.sortDesc && b.sortDesc && a.sortDesc.join(",") != b.sortDesc.join(",")) {
            return false;
        }

        if ((!a.groupBy && b.groupBy && b.groupBy.length > 0) || (a.groupBy && a.groupBy.length > 0 && !b.groupBy)) {
            return false;
        }
        if (a.groupBy && b.groupBy && a.groupBy.join(",") != b.groupBy.join(",")) {
            return false;
        }

        if (
            (!a.groupDesc && b.groupDesc && b.groupDesc.length > 0) ||
            (a.groupDesc && a.groupDesc.length > 0 && !b.groupDesc)
        ) {
            return false;
        }

        if (a.groupDesc && b.groupDesc && a.groupDesc.join(",") != b.groupDesc.join(",")) {
            return false;
        }

        return true;
    }
}
