import IPurchasingMergeTemplate from "@/chipply/merge/IPurchasingMergeTemplate";
import { AutomatedMergeTemplate } from "@/chipply/merge/AutomatedMergeTemplate";
import { typeHint, viewModel } from "chipply-common";
import AutomatedDealerStoreMergeTemplateViewModel from "@/chipply/merge/AutomatedDealerStoreMergeTemplateViewModel";
import PurchasingMergeTemplateViewModel from "@/chipply/merge/PurchasingMergeTemplateViewModel";

@typeHint({ typeHint: "PurchasingMergeTemplate" })
@viewModel(PurchasingMergeTemplateViewModel)
export class PurchasingMergeTemplate extends AutomatedMergeTemplate implements IPurchasingMergeTemplate {
    public shouldIncludePrice = true;
    public shouldIncludeUpc = true;
    public lastSendDate = "";
    public orderIds: number[] | null = null;
    public resend = false;
    public $typeHint = "PurchasingMergeTemplate";
}
