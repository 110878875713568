import { IColorAssignment } from "@/chipply/interface/IColorAssignment";
import { ColorAssignmentViewModel } from "@/chipply/store/color-assignment/ColorAssignmentViewModel";
import { IColorAssignmentGroup } from "@/chipply/store/color-assignment/IColorAssignmentGroup";
import { ViewModel } from "chipply-common";

export class GroupColorAssignmentViewModel extends ViewModel {
    public id = "";
    public name = "";
    public assignments: ColorAssignmentViewModel[] = [];
    public standardColorId = 0;

    constructor(model: IColorAssignmentGroup) {
        super();
        this.name = model.name;
        this.standardColorId = model.standardColorId;
        this.id = model.id!;
        for (const assignment of model.assignments) {
            this.assignments.push(new ColorAssignmentViewModel(assignment));
        }
    }

    public selectedChanged = (isSelected: boolean) => {
        for (const childAssignment of this.assignments) {
            childAssignment.colorSelected = isSelected;
        }
    };

    public toModel(): IColorAssignmentGroup {
        const model: IColorAssignmentGroup = {
            name: this.name,
            id: this.id,
            standardColorId: this.standardColorId,
            assignments: [],
        };

        for (const assignment of this.assignments) {
            model.assignments.push(assignment.toModel());
        }

        return model;
    }
}
