

























































































































































































































































































































import { ColorAssignmentViewModel } from "@/chipply/store/color-assignment/ColorAssignmentViewModel";
import { ProductColorAssignmentViewModel } from "@/chipply/store/color-assignment/ProductColorAssignmentViewModel";
import Component from "vue-class-component";
import Vue from "vue";
import PurchasingColorAssignmentPageViewModel from "@/chipply/purchasing/PurchasingColorAssignmentPageViewModel";
import ColorAssignment from "@/components/events/ColorAssignment.vue";
import { Prop, Watch } from "vue-property-decorator";
import { IProductColorAssignment } from "@/chipply/interface/i-product-color-assignment";
import { IColorAssignment } from "@/chipply/interface/IColorAssignment";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import CYesNoDialog from "@/components/dialogs/CYesNoDialog.vue";
@Component({
    components: {
        ColorAssignment,
        CToolbarBtn,
        CYesNoDialog,
    },
})
export default class PurchasingColorAssignment extends Vue {
    @Prop({
        type: Object,
    })
    public vm!: PurchasingColorAssignmentPageViewModel;
    public chipplyIcons = chipplyIcons;
    // @ts-ignore
    public $refs!: {
        variationFilter: HTMLSelectElement;
        variationFilterByProduct: HTMLSelectElement;
    };

    public artworkChanged(artworkId: number) {
        this.vm.artworkChanged(artworkId);
    }

    public assignmentByColorVariationChanged(newValue: number, item: ColorAssignmentViewModel) {
        this.vm.assignmentByColorVariationChanging(newValue, item);
    }

    public assignmentByProductVariationChanged(newValue: number, item: ProductColorAssignmentViewModel) {
        this.vm.assignmentByProductVariationChanging(newValue, item);
    }
    @Watch("vm.filterByVariationIds")
    public watchFilterByVariationIds() {
        if (this.$refs.variationFilter) {
            this.$refs.variationFilter.blur();
        }
        if (this.$refs.variationFilterByProduct) {
            this.$refs.variationFilterByProduct.blur();
        }
        this.vm.getColorAssignments();
    }

    public async tabsChanged(index: number) {
        this.vm.tabsChanged(index);
    }
}
