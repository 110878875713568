import ITextValueDisabled from "@/chipply/interface/ITextValueDisabled";
import { Utils } from "chipply-common";
import { DateTime } from "luxon";
export default class Validation {
    public static requireUrlPart(value: string) {
        if (!value) {
            return "You must enter a value";
        }

        for (let i = 0; i < value.length; i++) {
            const charCode = value.charCodeAt(i);
            if (charCode <= 122 && charCode >= 65) {
                continue;
            }
            if (charCode <= 57 && charCode >= 48) {
                continue;
            }
            const character = value.charAt(i);
            if (character === "_" || character === "-") {
                continue;
            }
            return "Only alphanumeric, underscore and hyphen characters are allowed";
        }

        return true;
    }

    public static requireArrayValue(value: any[]) {
        return !value || value.length === 0 ? "You must enter a value" : true;
    }

    public static requireNotDisabled(values: Array<ITextValueDisabled<number>>) {
        return (value: number) => {
            for (const val of values) {
                if (value !== val.value) {
                    continue;
                }
                if (!val.disabled) {
                    return true;
                }
                return "The selected value has been disabled";
            }

            return true;
        };
    }
    public static requireValidEmailOptional(email: string) {
        if (!email) {
            return true;
        }
        return Validation.requireValidEmail(email);
    }

    public static requireValidEmail(email: string) {
        if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
            )
        ) {
            return true;
        } else {
            return "Please enter a valid email address";
        }
    }

    /**
     * Designed to be used with drop downs and places where 0 may mean an unassigned item
     * @param value
     */
    public static requireValueNonZeroList(value: any) {
        const num = parseFloat(value);
        if (Number.isNaN(num)) {
            return "You must enter a value";
        }

        return num !== 0 ? true : "You must enter a value";
    }

    /**
     * Designed to be used with input fields for numbers
     * @param value
     */
    public static requireValueNonZero(value: any) {
        const num = parseFloat(value);

        if (Number.isNaN(num)) {
            return "You must enter a nonzero value";
        }
        return num !== 0 ? true : "You must enter a nonzero value";
    }

    public static requireTrue(value: boolean) {
        return value || "Validation failed.";
    }

    public static requireValueMoreThanZero(value: any) {
        const num = parseFloat(value);
        if (Number.isNaN(num)) {
            return "You must enter a value greater than 0";
        }

        return num > 0 ? true : "You must enter a value greater than 0";
    }

    public static requireValueNonNegative(value: number) {
        return value >= 0 ? true : "You must enter a positive value";
    }

    public static requireValue(value: any) {
        const num = parseFloat(value);
        if (!Number.isNaN(num)) {
            return true;
        }

        if ((value || "").length > 0) {
            return true;
        }
        return "You must enter a value";
    }

    public static requireNumericLengthOf(length: number) {
        return (value: string) => {
            return Utils.getNumericLength(value) === length ? true : "Number must be " + length + " digits";
        };
    }

    public static requireMinimumLengthOf(length: number) {
        return (value: string) => {
            return (value || "").length >= length ? true : "Value must be at least " + length + " characters long";
        };
    }

    public static requireHundredOrLess(value: any) {
        return Number(value) <= 100 ? true : "Value must be less than 100";
    }

    public static requireValueLessThan(lessThan: number) {
        return (value: string) => {
            return Number(value) <= lessThan ? true : "Value must be less than " + lessThan;
        };
    }

    public static requireUniqueValue(values: string[]) {
        return (testValue: string) => {
            for (const value of values) {
                if (value.trim().toLowerCase() === testValue.trim().toLowerCase()) {
                    return "Value must be unique";
                }
            }

            return true;
        };
    }

    public static requireDateDifferenceLessThan(startDate: string, endDate: string, months: number) {
        return (testValue: string) => {
            if (!startDate || !endDate) {
                return true;
            }

            const endDateTime = DateTime.fromISO(endDate);
            const startDateTime = DateTime.fromISO(startDate);

            const diffInMonths = endDateTime.diff(startDateTime, "months");
            const diff = diffInMonths.toObject();

            return diff.months! > months ? `The selected time period must be less than ${months} months.` : true;
        };
    }
}
