
















































































































































import DepositSummary from "@/chipply/deposits/DepositSummary";
import IDepositSummary from "@/chipply/deposits/IDepositSummary";
import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import CSummaryCard from "@/components/ui/CSummaryCard.vue";
import { Utils, WebHelper } from "chipply-common";
import EditLayout from "@/components/layout/EditLayout.vue";

@Component({
    components: { EditLayout, TextHeading, CSummaryCard },
    props: {
        eventId: Number,
    },
})
export default class Deposits extends Vue {
    public chipplyIcons = chipplyIcons;
    public Utils = Utils;

    public eventId!: number;
    public salesSummary: DepositSummary | null = null;

    protected depositHeaders = [
        { text: "Deposit Date", value: "depositDate" },
        { text: "Date Generated", value: "dateGenerated" },
        { text: "Statement Period", value: "statementPeriod" },
        { text: "Status", value: "status" },
    ];

    protected depositAmountHeaders = [
        { text: "Deposit Date", value: "depositDate" },
        { text: "Amount Collected", value: "amountCollected" },
        { text: "Chipply Fees", value: "chipplyFees" },
        { text: "CC Processing Fees", value: "processingFees" },
        { text: "Amount Deposited", value: "amountPaid" },
    ];

    public async created() {
        const data: IDepositSummary = await WebHelper.getJsonData(`/api/Deposits/Event/${this.eventId}`);
        this.salesSummary = new DepositSummary(data);
    }

    protected print() {
        window.print();
    }
}
