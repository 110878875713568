import EditableViewModel from "@/chipply/EditableViewModel";
import { IEdit, IOrderAdjustment, TaxType } from "chipply-common";

export default class OrderAdjustmentViewModel extends EditableViewModel implements IEdit<OrderAdjustmentViewModel> {
    public amount!: number;
    public description!: string;
    public orderItemId!: number;
    public taxRate!: number | null;
    public taxType!: TaxType;
    public qty!: number;
    public name!: string;
    public productPrice!: number;
    public fundraising!: number;
    public processCost!: number;
    public isPayLater!: boolean;
    public isRefundLater!: boolean;
    public eventProductId!: number;

    constructor(adj: IOrderAdjustment) {
        super();
        Object.assign(this, adj);
    }

    public apply(vm: IOrderAdjustment): void {
        Object.assign(this, vm);
    }

    public createEditViewModel(): OrderAdjustmentViewModel {
        const data = JSON.parse(JSON.stringify(this));
        return new OrderAdjustmentViewModel(data);
    }

    public toModel(): IOrderAdjustment {
        return {
            amount: this.amount,
            description: this.description,
            eventProductId: this.eventProductId,
            fundraising: this.fundraising,
            isPayLater: this.isPayLater,
            isRefundLater: this.isRefundLater,
            name: this.name,
            orderItemId: this.orderItemId,
            processCost: this.processCost,
            productPrice: this.productPrice,
            qty: this.qty,
            taxRate: this.taxRate,
            taxType: this.taxType,
        };
    }
}
