























import { DateTime } from "luxon";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import { Utils } from "chipply-common";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CTime extends Vue {
    public chipplyIcons = chipplyIcons;
    public Utils = Utils;
    @Prop({
        type: String,
    })
    public value!: string | number;
    public inputValue: string | number | null = null;
    @Prop()
    public label!: string;
    public showMenu = false;
    @Prop()
    public rules!: any[];

    protected displayValue = "";

    public mounted() {
        this.onValueChanged();
    }

    @Watch("value")
    public onValueChanged() {
        this.inputValue = this.value;
        this.updateDisplayValue();
    }

    @Watch("inputValue")
    public onInputValueChanged() {
        this.updateDisplayValue();
        this.$emit("change", this.inputValue);
    }

    protected updateDisplayValue() {
        if (!this.inputValue) {
            this.displayValue = "";
            return;
        }

        this.displayValue = DateTime.fromISO(this.inputValue as string).toLocaleString(DateTime.TIME_SIMPLE);
    }
}
