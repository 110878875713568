import IPurchaseOrderArgs from "./IPurchaseOrderArgs";
import PurchasingFilters from "./PurchasingFilters";
import { PurchasingReportType } from "./PurchasingReportType";
import VendorPurchaseOrder from "./VendorPurchaseOrder";

export default class PurchaseOrderReportArgs implements IPurchaseOrderArgs {
    public purchaseOrder: VendorPurchaseOrder = new VendorPurchaseOrder();
    public filters: PurchasingFilters = new PurchasingFilters();
    public purchaseOrderIds: number[] = [];
    public reportType: PurchasingReportType = "Details";
    public shouldIncludeUpc = true;
    public shouldIncludePrice = true;
}
