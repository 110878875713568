import { render, staticRenderFns } from "./EcomOrdersGrid.vue?vue&type=template&id=460f50d7&scoped=true&"
import script from "./EcomOrdersGrid.vue?vue&type=script&lang=ts&"
export * from "./EcomOrdersGrid.vue?vue&type=script&lang=ts&"
import style0 from "./EcomOrdersGrid.vue?vue&type=style&index=0&id=460f50d7&prod&lang=css&"
import style1 from "./EcomOrdersGrid.vue?vue&type=style&index=1&id=460f50d7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460f50d7",
  null
  
)

export default component.exports