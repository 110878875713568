














import Component from "vue-class-component";
import Vue from "vue";

@Component({
    props: {
        show: Boolean,
        viewModel: Object,
        hideSave: Boolean,
    },
})
export default class CYesNoDialog extends Vue {}
