import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import AutomatedDealerStoreMergeTemplateViewModel from "@/chipply/merge/AutomatedDealerStoreMergeTemplateViewModel";
import IAutomatedMergeTemplate from "@/chipply/merge/IAutomatedMergeTemplate";
import IMergeEmailRecipient from "@/chipply/merge/IMergeEmailRecipient";
import IMergePhoneRecipient from "@/chipply/merge/IMergePhoneRecipient";
import IMergeTemplateDto from "@/chipply/merge/IMergeTemplateDto";
import IMergeTemplateViewModel from "@/chipply/merge/IMergeTemplateViewModel";
import { MergeTemplateRecipientType } from "@/chipply/merge/MergeTemplateRecipientType";
import { MessageType } from "@/chipply/merge/MessageType";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class EmailTemplatesGridMixin extends Vue {
    public items!: IMergeTemplateViewModel[];
    public selectedTemplate: IMergeTemplateViewModel | undefined;
    public showTemplateDialog!: boolean;
    public loading!: boolean;
    public statusMessage!: string | undefined;
    public deletionTemplate!: IAutomatedMergeTemplate | undefined;
    public showDeleteTemplateDialog!: boolean;
    public allowSms = false;

    public data() {
        return {
            EmailTemplateTriggerTypeEnum,
            deletionTemplate: undefined,
            headers: this.getHeaders(),
            items: [],
            loading: false,
            selectedTemplate: undefined,
            showDeleteTemplateDialog: false,
            showTemplateDialog: false,
            statusMessage: undefined,
        };
    }

    public async refreshData() {
        const templates = await this.getTemplates();

        this.items = templates;
    }

    protected getHeaders() {
        return [
            { text: "", sortable: false, value: "edit" },
            { text: "Send Date", sortable: false, value: "sendDate" },
            { text: "Message Type", sortable: false, value: "messageType" },
            { text: "Message Name", sortable: false, value: "messageName" },
            { sortable: false, text: "Type", value: "type" },
            { sortable: false, text: "Recipients", value: "recipients" },
            { sortable: false, text: "", value: "delete" },
        ];
    }

    protected async getTemplates(): Promise<IMergeTemplateViewModel[]> {
        return new Promise<IMergeTemplateViewModel[]>(() => null);
    }

    protected async saveEmailTemplate(template: IMergeTemplateDto): Promise<any> {
        return new Promise<any>(() => null);
    }

    protected async deleteTemplate(confirmed: boolean): Promise<any> {
        return new Promise<any>(() => null);
    }

    protected editItem(item: IMergeTemplateViewModel) {
        this.selectedTemplate = item.clone();
        this.showTemplateDialog = true;
    }

    protected getMessageTypes(types: MessageType[]) {
        let typeDisplay = "";
        if (types.indexOf(MessageType.Email) > -1) {
            typeDisplay += "Email";
        }
        if (this.allowSms && types.indexOf(MessageType.Sms) > -1) {
            if (typeDisplay.length > 0) {
                typeDisplay += ", ";
            }
            typeDisplay += "SMS (Text)";
        }
        return typeDisplay;
    }

    protected getRecipientList(recipients: IMergeEmailRecipient[]) {
        if (!recipients) {
            return;
        }

        let recipientText = "";
        for (const recipient of recipients) {
            let recipientName = "";
            if (recipient.customEmailAddress) {
                recipientName += recipient.customEmailAddress;
            } else {
                if (recipient.recipientType === MergeTemplateRecipientType.Custom) {
                    continue;
                }
                recipientName += this.getRecipientTypeDisplay(recipient.recipientType);
            }

            if (recipientName) {
                if (recipientText) {
                    recipientText += "; ";
                }

                recipientText += recipientName;
            }
        }

        return recipientText;
    }

    protected getSmsRecipientList(recipients: IMergePhoneRecipient[]) {
        if (!recipients) {
            return;
        }

        let recipientText = "";
        for (const recipient of recipients) {
            let recipientName = "";
            if (recipient.customPhone) {
                recipientName += recipient.customPhone;
            } else {
                if (recipient.recipientType === MergeTemplateRecipientType.Custom) {
                    continue;
                }
                recipientName += this.getRecipientTypeDisplay(recipient.recipientType);
            }

            if (recipientName) {
                if (recipientText) {
                    recipientText += "; ";
                }

                recipientText += recipientName;
            }
        }

        return recipientText;
    }

    protected getRecipientTypeDisplay(recipientType: number) {
        switch (recipientType) {
            case 1:
                return "Organization Contact";
            case 2:
                return "Store Salesperson";
            case 3:
                return "Contact Information";
        }
    }

    protected getTriggerDisplay(trigger: number) {
        switch (trigger) {
            case 0:
                return "After Open Date";
            case 1:
                return "Before Open Date";
            case 2:
                return "After Close Date";
            case 3:
                return "Before Close Date";
            case 4:
                return "Static Date";
            case 5:
                return "Abandoned Cart";
        }
    }

    protected async emailTemplateAccepted(accepted: boolean, emailTemplate: IMergeTemplateViewModel) {
        if (!accepted) {
            this.showTemplateDialog = false;
            this.selectedTemplate = undefined;
            return;
        }

        this.statusMessage = "Saving Template...";
        this.loading = true;
        await this.saveEmailTemplate(emailTemplate!.toModel());
        this.statusMessage = "Loading Templates...";
        await this.refreshData();
        this.loading = false;

        this.showTemplateDialog = false;
        this.selectedTemplate = undefined;
    }

    protected deleteItem(template: IAutomatedMergeTemplate) {
        this.deletionTemplate = template;
        this.showDeleteTemplateDialog = true;
    }

    protected addTemplate() {
        const template = new AutomatedDealerStoreMergeTemplateViewModel();
        template.setTemplate();
        this.selectedTemplate = template;
        this.showTemplateDialog = true;
    }
}
