import ISpreadsheetProductDto from "@/chipply/interface/i-spreadsheet-product-dto";
import ITreeItem from "@/chipply/interface/i-tree-item";
import IProcessCost from "@/chipply/process/IProcessCost";
import { ZeroSellPriceDisplay } from "@/chipply/store/ZeroSellPriceDisplay";
import { ITextValue, TaxType, Utils } from "chipply-common";
import { EventBus } from "../EventBus";

export interface ISelectableListItem {
    selected: boolean;
    name: string;
    id: number;
    disabled: boolean;
}

export default class SpreadsheetProductViewModel {
    [key: string]: any;

    get taxTypeDisplay() {
        const taxRate = this.taxRate;
        const taxType = this.taxType;
        if (taxType === TaxType.Auto) {
            return "Auto";
        }
        if (taxType === TaxType.TaxExempt) {
            return "Exempt";
        }
        return !taxRate ? "%" : taxRate + "%";
    }

    get hideVendor() {
        return this._hideVendor;
    }

    set hideVendor(value: boolean) {
        this._hideVendor = value;
        this.setShowDetailsMenu();
    }

    get hideStyle() {
        return this._hideStyle;
    }

    set hideStyle(value: boolean) {
        this._hideStyle = value;
        this.setShowDetailsMenu();
    }

    get hideProductName() {
        return this._hideProductName;
    }

    set hideProductName(value: boolean) {
        this._hideProductName = value;
        this.setShowDetailsMenu();
    }

    get required() {
        return this._required;
    }

    set required(value: boolean) {
        this._required = value;
        this.setShowDetailsMenu();
    }
    public isArtworkValid = false;
    public costStyle: object = {};
    public hasInventory!: boolean;
    public vendorName!: string;
    public vendorId!: number;
    public eventProductId!: number;
    public colorSizeAssigned!: boolean;
    public selectAllChecked = false;
    public imageUrl!: string;
    public categoryTree!: number[];
    public hidePersonalization!: boolean;
    public styleNumber!: string;
    public productName!: string;
    public processName!: string;
    public sortOrder!: string | number;
    public processCostDisplay: string;
    public msrpDisplay: string;
    public combinedDisplay: string;
    public fundraisingPercentDisplay: string;
    public sellPriceDisplay: number;
    public autoPricedPrice!: number;
    public priceManuallyAdjusted!: boolean;
    public categories!: ITreeItem[];
    public processId!: number;
    public categoryIds!: number[];
    public processes: Array<{ text: string; value: number }> = [];
    public useFeedsBasedQty!: boolean;
    public availableColorSizePercentage!: string;
    public hasOrders!: boolean;
    public vendorDiscountUnset!: boolean;
    public get showDetailsMenu() {
        const hideStyle = this._hideStyle;
        const hideVendor = this._hideVendor;
        const required = this._required;
        const hideProductName = this._hideProductName;
        const ignoreSizeUpcharges = this.ignoreSizeUpcharge;
        const zeroSellPriceOtherThanDefault = this.zeroSellPriceDisplay !== ZeroSellPriceDisplay.DisplayAsZero;
        return (
            hideStyle ||
            hideVendor ||
            required ||
            hideProductName ||
            ignoreSizeUpcharges ||
            zeroSellPriceOtherThanDefault
        );
    }
    public showHideOptions!: boolean;

    public ignoreSizeUpcharge!: boolean;
    public taxType!: TaxType;
    public taxRate: number | null = 0;
    public taxMenu!: boolean;
    public groupMarkup!: number;
    public cost!: number;
    public cogs!: number;
    public weight: number | null = null;

    public zeroSellPriceDisplay!: ZeroSellPriceDisplay;
    public zeroSellPriceLabel: string | null = null;

    public children!: SpreadsheetProductViewModel[];
    public selectedProcesses: ISelectableListItem[] = [];
    public processMenuOpen = false;

    private selectedCategories!: string;
    private basePrice!: number;

    private enabled!: boolean;
    private msrp!: number;
    private processCost!: number;
    private parentCategories: number[];
    private processCosts: IProcessCost;

    private _required!: boolean;
    private _hideVendor!: boolean;
    private _hideStyle!: boolean;
    private _hideProductName!: boolean;

    constructor(
        dto: ISpreadsheetProductDto,
        categories: ITreeItem[],
        processCosts: { [processId: number]: IProcessCost },
        processes: Array<ITextValue<number>>
    ) {
        Object.assign(this, dto);

        this.processes = processes;
        this.processCosts = processCosts[this.processId] ?? {
            artworkAndRequiredOptionsPrice: 0,
            artworkPrice: 0,
            requiredOptionsPrice: 0,
        };

        this._required = dto.required;
        this._hideStyle = dto.hideStyle;
        this._hideVendor = dto.hideVendor;
        this._hideProductName = dto.hideProductName;
        this.setShowDetailsMenu();

        this.taxMenu = false;
        this.processCostDisplay = Utils.getCurrencyValue(this.processCost);
        this.msrpDisplay = Utils.getCurrencyValue(this.msrp);
        const combinedPrice = this.cost + this.processCost;
        this.combinedDisplay = Utils.getCurrencyValue(combinedPrice);
        this.fundraisingPercentDisplay = Utils.getCurrencyValue((this.groupMarkup / combinedPrice) * 100);
        this.sellPriceDisplay = combinedPrice + this.groupMarkup;
        this.categoryTree = [];
        this.categories = categories;
        this.parentCategories = this.getParentCategories(categories); // TODO: could optimize

        this.children = [];

        const childProcesses: Array<ITextValue<number>> = [];
        childProcesses.push({ value: 0, text: "Delete" });
        childProcesses.push(...processes);

        if (dto.children) {
            for (const child of dto.children) {
                this.children.push(new SpreadsheetProductViewModel(child, categories, processCosts, childProcesses));
            }
        }

        this.resetSelectedProcesses();
    }

    public multipleProcessClosed(accepted: boolean) {
        if (!accepted) {
            this.processMenuOpen = false;
            this.resetSelectedProcesses();
            return;
        }

        const selectedProcessIds: number[] = [];
        for (const process of this.selectedProcesses) {
            if (process.selected) {
                selectedProcessIds.push(process.id);
            }
        }

        this.processMenuOpen = false;
        EventBus.$emit("product-multiple-process-closed", [this.eventProductId], selectedProcessIds);
    }

    public toDto(): ISpreadsheetProductDto {
        const childDtos: ISpreadsheetProductDto[] = [];
        for (const child of this.children) {
            childDtos.push(child.toDto());
        }

        const dto: ISpreadsheetProductDto = {
            isArtworkValid: this.isArtworkValid,
            enabled: this.enabled,
            eventProductId: this.eventProductId,
            hidePersonalization: this.hidePersonalization,
            productName: this.productName,
            sortOrder: Number(this.sortOrder),
            styleNumber: this.styleNumber,

            basePrice: this.sellPriceDisplay,
            cogs: this.cogs,
            cost: this.cost,
            children: childDtos,

            groupMarkup: this.groupMarkup,
            msrp: Utils.getNumberFromString(this.msrpDisplay),
            processCost: Utils.getNumberFromString(this.processCostDisplay),

            autoPricedPrice: this.autoPricedPrice,
            categoryIds: [],
            colorSizeAssigned: this.colorSizeAssigned,
            hasOrders: this.hasOrders,
            imageUrl: this.imageUrl,
            priceManuallyAdjusted: this.priceManuallyAdjusted,
            processId: this.processId,
            processName: this.processName,
            useFeedsBasedQty: this.useFeedsBasedQty,
            hasInventory: this.hasInventory,
            availableColorSizePercentage: this.availableColorSizePercentage,
            vendorDiscountUnset: this.vendorDiscountUnset,
            vendorName: this.vendorName,
            vendorId: this.vendorId,

            hideStyle: this.hideStyle,
            hideVendor: this.hideVendor,
            hideProductName: this.hideProductName,
            ignoreSizeUpcharge: this.ignoreSizeUpcharge,
            required: this.required,
            showHideOptions: this.showHideOptions,
            taxRate: this.taxRate,
            taxType: this.taxType,
            weight: Number(this.weight),
            zeroSellPriceLabel: this.zeroSellPriceLabel,
            zeroSellPriceDisplay: this.zeroSellPriceDisplay,
        };

        if (!this.categoryIds) {
            return dto;
        }
        for (const cat of this.categoryIds) {
            if (this.parentCategories.indexOf(cat) > -1) {
                continue;
            }
            dto.categoryIds.push(cat);
        }

        return dto;
    }

    public sellPriceChanged = (newValue: number) => {
        const formattedSellPrice = Utils.getCurrencyValue(newValue);
        const sellPrice = Utils.getNumberFromString(formattedSellPrice);
        this.sellPriceDisplay = sellPrice;
        this.basePrice = sellPrice;
        const cost = this.cost;
        const processCost = Utils.getNumberFromString(this.processCostDisplay);

        const fundraisingDollars = sellPrice - cost - processCost;
        this.groupMarkup = fundraisingDollars;
        const fundraisingPercent = fundraisingDollars / (cost + processCost);
        this.fundraisingPercentDisplay = Utils.getCurrencyValue(fundraisingPercent * 100);
    };

    public priceChanged(recalculateFundraisingDollars?: boolean) {
        const newCost = this.cost;
        const processCost = Utils.getNumberFromString(this.processCostDisplay);
        const combinedCost = newCost + processCost;
        let fundraisingDollar = this.groupMarkup;
        let fundraisingPercent = fundraisingDollar / combinedCost;
        if (recalculateFundraisingDollars) {
            fundraisingPercent = Utils.getNumberFromString(this.fundraisingPercentDisplay) / 100;
            fundraisingDollar = combinedCost * fundraisingPercent;
        }

        this.groupMarkup = fundraisingDollar;
        this.fundraisingPercentDisplay = Utils.getCurrencyValue(fundraisingPercent * 100);
        this.combinedDisplay = Utils.getCurrencyValue(combinedCost);
        this.sellPriceDisplay = combinedCost + fundraisingDollar;

        for (const child of this.children) {
            child.cost = this.cost;
            child.priceChanged(recalculateFundraisingDollars);
        }
    }

    public hideOptionsChanged = (newValue: boolean) => {
        let processCost = 0;
        if (newValue) {
            processCost = this.processCosts.artworkPrice;
        } else {
            processCost = this.processCosts.artworkAndRequiredOptionsPrice;
        }

        this.processCostDisplay = Utils.getCurrencyValue(processCost);
        this.priceChanged();
    };

    public recalculatePrices = (newValue: string) => {
        this.priceChanged();
    };

    public fundraisingPercentDisplayChanged = (newValue: string) => {
        this.fundraisingPercentDisplay = newValue;
        this.priceChanged(true);
    };

    protected resetSelectedProcesses() {
        const orderedProcessIds: number[] = [];
        if (this.hasOrders) {
            orderedProcessIds.push(this.processId);
        }

        const selectedProcessIds: number[] = [];
        selectedProcessIds.push(this.processId);
        for (const child of this.children) {
            selectedProcessIds.push(child.processId);
            if (child.hasOrders) {
                orderedProcessIds.push(child.processId);
            }
        }

        this.selectedProcesses = [];
        for (const process of this.processes) {
            this.selectedProcesses.push({
                disabled: orderedProcessIds.indexOf(process.value) > -1,
                id: process.value,
                name: process.text,
                selected: selectedProcessIds.indexOf(process.value) > -1,
            });
        }
    }

    protected setShowDetailsMenu() {
        // this.showDetailsMenu = this._hideStyle || this._hideVendor || this._required || this._hideProductName;
    }

    protected getParentCategories(categories: ITreeItem[]) {
        const parentCats: number[] = [];
        if (categories == null) {
            return [];
        }
        for (const cat of categories) {
            if (!cat.children || cat.children.length === 0) {
                continue;
            }
            parentCats.push(cat.id);
        }

        return parentCats;
    }

    private numberFocused(event: FocusEvent) {
        if (!event) {
            return;
        }
        (event.currentTarget as HTMLInputElement).select();
    }

    private getZeroCostStyle() {
        if (this.cost === 0) {
            return "red";
        }
        if (
            (this.cost !== this.autoPricedPrice && !this.priceManuallyAdjusted) ||
            (this.vendorDiscountUnset && !this.priceManuallyAdjusted)
        ) {
            return "yellow";
        } else {
            return "";
        }
    }

    private getSellPriceColor() {
        return this.basePrice < 0 ? "red" : "";
    }

    private getWeightColor() {
        const validationColor = "red";
        if (this.weight === null) {
            return validationColor;
        } else if (this.weight <= 0) {
            return validationColor;
        }
        return "";
    }
}
