




















import IArtwork from "@/chipply/process/IArtwork";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CArtworkSelect extends Vue {
    @Prop({
        default: false,
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: Array,
    })
    public rules!: any[];

    @Prop({
        default: [],
        type: Array,
    })
    public artworks!: IArtwork[];

    @Prop({
        type: Number,
    })
    public value!: number | null;

    public inputValue = this.value;

    public created() {
        this.inputValue = this.value;
    }

    @Watch("inputValue")
    protected emitChange() {
        this.$emit("change", this.inputValue);
    }
}
