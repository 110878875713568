import IPurchasingMergeTemplate from "../merge/IPurchasingMergeTemplate";
import PurchasingFilters from "./PurchasingFilters";
import { PurchasingReportType } from "./PurchasingReportType";
import VendorPurchaseOrder from "./VendorPurchaseOrder";

export class SendPurchasingEmailArgs {
    public purchaseOrder: VendorPurchaseOrder;

    public filters: PurchasingFilters;

    public purchasingMergeTemplate: IPurchasingMergeTemplate;

    public purchaseOrderIds: number[] = [];

    public reportType: PurchasingReportType = "Details";

    public constructor(
        purchaseOrder: VendorPurchaseOrder,
        filters: PurchasingFilters,
        purchasingMergeTemplate: IPurchasingMergeTemplate,
        purchaseOrderIds: number[],
        reportType: PurchasingReportType
    ) {
        this.purchaseOrder = purchaseOrder;
        this.filters = filters;
        this.purchasingMergeTemplate = purchasingMergeTemplate;
        this.purchaseOrderIds = purchaseOrderIds;
        this.reportType = reportType;
    }
}
