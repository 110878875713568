import { EventType } from "@/chipply/event/EventType";

export default class StoreListFilters {
    public $type = "Chipply.Service.Events.EventListFilters, Chipply.Service";
    public organizationId?: number;
    public salesRepId?: number;
    public openDate?: Date;
    public closeDate?: Date;
    public storeName?: string;
    public saleOrder?: string;
    public status?: string;
    public storeType?: EventType;
    public hideDeleted = true;
}
