import { ITextValue } from "chipply-common";
import _ from "lodash";
import EmailTemplateMode from "@/chipply/emails/EmailTemplateMode";
import CheckAvailabilityArgs from "./CheckAvailabilityArgs";
import CheckAvailabilityResults from "./CheckAvailabilityResults";
import { IPurchaseOrderLineItem } from "./IPurchaseOrderLineItem";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import SubmitPurchaseOrderArgs from "./SubmitPurchaseOrderArgs";
import SubmitPurchaseOrderResults from "./SubmitPurchaseOrderResults";
import VendorPurchaseOrder from "./VendorPurchaseOrder";
import VendorPurchaseOrderArgs from "./VendorPurchaseOrderArgs";
import { BuildPurchaseOrderResults } from "./BuildPurchaseOrderResults";
import ListVendorPurchaseOrderArgs from "./ListVendorPurchaseOrderArgs";
import PurchasingConstants from "./PurchasingConstants";
import PurchasingViewModel from "./PurchasingViewModel";
import PurchasingFilters from "./PurchasingFilters";
import PurchaseOrderReportArgs from "./PurchaseOrderReportArgs";
import PurchasingMergeTemplateViewModel from "../merge/PurchasingMergeTemplateViewModel";
import { DateTime } from "luxon";
import { SendPurchasingEmailArgs } from "./SendPurchasingEmailArgs";
import IPurchasingMergeTemplate from "../merge/IPurchasingMergeTemplate";
import { SimpleAsyncInteractionViewModel, Utils, TextUtils } from "chipply-common";
import { WebHelper } from "chipply-common";
import GetPurchaseOrderItemDetailsArgs from "./GetPurchaseOrderItemDetailsArgs";
import { IGetPurchaseOrderItemDetailsResults } from "./IGetPurchaseOrderItemDetailsResults";
import IPurchaseOrderItemDetail from "./IPurchaseOrderItemDetail";
import { PurchasingType } from "./PurchasingConstants";
import ProductColorSizeSelectorViewModel from "../event/ProductColorSizeSelectorViewModel";
import PurchasingColorAssignmentPageViewModel from "./PurchasingColorAssignmentPageViewModel";
import ISubstituteProductResults from "./ISubstituteProductResults";
import SaveOrderItemDetailsArgs from "./SaveOrderItemDetailsArgs";
import SortProperty from "../data-access/SortProperty";
import IPurchasingParentViewModel from "./IPurchasingParentViewModel";
import PageState from "../PageState";
import BulkUpdateNoteArgs from "./BulkUpdateNoteArgs";
import { PurchasingReportType } from "./PurchasingReportType";
import IOrderItemHistoryDto from "./IOrderItemHistoryDto";
import ListOrderItemHistoryArgs from "./ListOrderItemHistoryArgs";
import IListOrderItemHistoryResults from "./IListOrderItemHistoryResults";
import SalesOrderInfo from "@/chipply/event/SalesOrderInfo";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import SanMarPurchaseOrderSettings from "./SanMar/SanMarPurchaseOrderSettings";
import { Serializer, typeDependencies } from "chipply-common";
import FounderPurchaseOrderSettings from "./Founder/FounderPurchaseOrderSettings";
import AugustaPurchaseOrderSettings from "./Augusta/AugustaPurchaseOrderSettings";
import SSActivewearPurchaseOrderSettings from "./SSActivewear/SSActivewearPurchaseOrderSettings";
import IPurchasingViewModel from "./IPurchasingViewModel";
import AlphabroderPurchaseOrderSettings from "./Alphabroder/AlphabroderPurchaseOrderSettings";
import ManualAddPurchasingItemArgs from "./ManualAddPurchasingItemArgs";
import { ManualAddPurchasingItemResults } from "./ManualAddPurchasingItemResults";
import ManualAddPurchasingItem from "./ManualAddPurchasingItem";
import AbstractPurchaseOrderViewModel from "./AbstractPurchaseOrderViewModel";

@typeDependencies({
    types: {
        AlphabroderPurchaseOrderSettings,
        SanMarPurchaseOrderSettings,
        FounderPurchaseOrderSettings,
        AugustaPurchaseOrderSettings,
        SSActivewearPurchaseOrderSettings,
    },
})
export default class PurchaseOrderViewModel extends AbstractPurchaseOrderViewModel implements IPurchasingViewModel {
    public bulkNote = "";
    public dismissDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public groupBy = "";
    public isBulkNoteVisible = false;
    public isTimelineVisible = false;
    public currentHistory: IOrderItemHistoryDto[] = [];
    public lines: IPurchaseOrderLineItem[] = [];
    public selectedLines: IPurchaseOrderLineItem[] = [];
    public reportTypes: Array<ITextValue<string>> = [
        { text: "Purchase Order Detail", value: "Details" },
        { text: "Purchase Order Summary", value: "Summary" },
    ];
    public reportType: PurchasingReportType = "Details";
    public purchasingViewModel: IPurchasingParentViewModel;
    public isEmailWindowVisible = false;
    public emailTemplate!: PurchasingMergeTemplateViewModel;
    public EventTemplateMode = EmailTemplateMode;
    public isPrintChecked = true;
    public isEmailChecked = false;
    public isViewingDetails = false;
    public details: IPurchaseOrderItemDetail[] = [];
    public selectedDetails: IPurchaseOrderItemDetail[] = [];
    public currentItem: IPurchaseOrderLineItem | null = null;
    public currentItemImageUrl = "";
    public productColorSizeSelectorViewModel: ProductColorSizeSelectorViewModel | null = null;
    public colorAssignmentViewModel: PurchasingColorAssignmentPageViewModel | null = null;
    public isEditNoteVisible = false;
    public currentDetails: IPurchaseOrderItemDetail | null = null;
    public isSelectAllDetails = false;
    public isSelectAllDetailsIndeterminate = false;
    public printOrEmailDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public salesOrderInfo: SalesOrderInfo | null = null;
    public shouldIncludePrice = true;
    public shouldIncludeUpc = true;

    public detailsSortProperties: SortProperty[] = [
        { displayName: "Order date (older)", propertyName: "OrderDate", isDescending: false },
        { displayName: "Order date (recent)", propertyName: "OrderDate", isDescending: true },
        { displayName: "Store", propertyName: "StoreName", isDescending: false },
        { displayName: "Process", propertyName: "ProcessName", isDescending: false },
    ];
    public selectedDetailsSortProperties: SortProperty[] = [this.detailsSortProperties[0]];

    public headers = [
        { text: "Vendor", value: "vendorName" },
        { text: "Style", value: "style" },
        { text: "Product Name", value: "productName" },
        { text: "Color", value: "color" },
        { text: "Size", value: "size" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
        { text: "Cost", value: "productCost" },
        { text: "Total Cost", value: "totalCost" },
        { text: "Availability", value: "availability" },
        { text: "Warehouse", value: "warehouse" },
        { text: "Order Details", value: "actions", sortable: false },
    ];

    public allHeaders = [
        { text: "Vendor", value: "vendorName" },
        { text: "Style", value: "style" },
        { text: "Product Name", value: "productName" },
        { text: "Color", value: "color" },
        { text: "Size", value: "size" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
        { text: "Cost", value: "productCost" },
        { text: "Total Cost", value: "totalCost" },
        { text: "Availability", value: "availability" },
        { text: "Warehouse", value: "warehouse" },
        { text: "Order Details", value: "actions", sortable: false },
    ];

    public simpleHeaders = [
        { text: "Style", value: "style" },
        { text: "Color", value: "color" },
        { text: "Size", value: "size" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
        { text: "Order Details", value: "actions", sortable: false },
    ];

    public augustaShipMethods = [
        { method: "901", description: "UPS Standard Ground" },
        { method: "902", description: "UPS 1 DAY" },
        { method: "903", description: "UPS 2 DAY" },
        { method: "904", description: "UPS 3 DAY Ground" },
    ];

    public founderShipMethods = [
        { method: "U01", description: "UPS Ground" },
        { method: "U02", description: "UPS 3 Day Select" },
        { method: "U03", description: "UPS 2 Day Air" },
        { method: "U04", description: "UPS 2nd Day Air A.M." },
        { method: "U06", description: "UPS Next Day Air Saver" },
        { method: "U07", description: "UPS Next Day Air" },
        { method: "U11", description: "UPS International" },
        { method: "F01", description: "FedEx Ground" },
        { method: "F02", description: "FedEx 3 Day" },
        { method: "F05", description: "FedEx Std Overnight" },
        { method: "F06", description: "FedEx Priority Overnight" },
        { method: "F11", description: "FedEx International Economy" },
    ];

    public sanMarShipMethods = [
        { method: "UPS", description: "UPS Standard Ground" },
        { method: "UPS 2ND DAY", description: "UPS 2nd business day delivery end of day" },
        { method: "UPS 2ND DAY AM", description: "UPS 2nd business day delivery 10:30 a.m." },
        { method: "UPS 3RD DAY", description: "UPS 3rd business day delivery end of day" },
        { method: "UPS NEXT DAY", description: "UPS next day delivery 10:30 a.m." },
        { method: "UPS NEXT DAY EA", description: "UPS Next business day delivery 8:00 a.m." },
        { method: "UPS NEXT DAY SV", description: "UPS next day delivery 3:00 pm" },
        { method: "UPS SATURDAY", description: "UPS Extends business day calculation to include Saturday" },
        { method: "USPS PP", description: "United States Postal Service Parcel Post" },
        { method: "USPS APP", description: "USPS Air Parcel Post" },
        { method: "PSST", description: "Pack Separately, Ship Together program" },
        { method: "TRUCK", description: "Truck carrier services are based on the destination zip code" },
    ];

    public ssShipMethods = [
        { method: "1", description: "Ground" },
        { method: "2", description: "UPS Next Day Air" },
        { method: "3", description: "UPS 2nd Day Air" },
        { method: "16", description: "UPS 3 Day Select" },
        { method: "6", description: "Will Call Or PickUp" },
        { method: "8", description: "Messenger Pickup Or PickUp" },
        { method: "54", description: "Misc Cheapest" },
        { method: "17", description: "UPS Next Day Air Early AM" },
        { method: "21", description: "UPS Next Day Air Saver" },
        { method: "19", description: "UPS Saturday" },
        { method: "20", description: "UPS Saturday Early" },
        { method: "22", description: "UPS 2nd Day Air AM" },
        { method: "14", description: "FedEx Ground" },
        { method: "27", description: "FedEx Next Day Standard" },
        { method: "26", description: "FedEx Next Day Priority" },
        { method: "40", description: "UPS Ground" },
        { method: "48", description: "FedEx 2nd Day Air" },
    ];

    public alphabroderShipMethods = [
        { method: "UPS-Blue", description: "UPS 2 Day" },
        { method: "UPS-Orange 3day", description: "UPS 3 Day" },
        { method: "UPS-Red", description: "UPS 1 Day" },
        { method: "UPS-RED.8:30am", description: "UPS 1 Day 8:30 AM" },
        { method: "UPS-Sat.Dlvry.", description: "UPS Saturday" },
        { method: "UPS-Surface", description: "UPS Ground" },
    ];

    protected detailsPageState = new PageState();
    public lastPurchasingReportType: PurchasingReportType | null = null;
    protected processHeader = { text: "Process", value: "processHeader", sortable: true };

    public constructor(purchasingViewModel: IPurchasingParentViewModel) {
        super();
        this.purchasingViewModel = purchasingViewModel;
        this.isStoreView = purchasingViewModel.isStoreView;
        this.emailTemplate = new PurchasingMergeTemplateViewModel();
        this.emailTemplate.setTemplate();
        this.emailTemplate.loadEditData();
        this.salesOrderInfo = purchasingViewModel.salesOrderInfo;
    }

    public async buildPurchaseOrder(): Promise<void> {
        const baseUrl = "/api/purchasing/buildpurchaseorder";
        let rowLimitExceeded = false;
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new VendorPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as BuildPurchaseOrderResults;
            if (results) {
                if (results.purchaseOrder) {
                    if (results.purchaseOrder.exceedsRowLimit) {
                        rowLimitExceeded = true;
                    }
                    this.toViewModel(results.purchaseOrder);
                }
                if (results.countries) {
                    this.countries = results.countries;
                }
                if (results.states) {
                    this.states = results.states;
                }
                this.stores.splice(0);
                this.stores.push(...results.stores);
                this.processes.splice(0);
                this.processes.push(...results.processes);
                this.vendors.splice(0);
                this.vendors.push(...results.vendors);
                this.salesReps.splice(0);
                this.salesReps.push(...results.salesReps);
                this.selectedPurchaseOrderType = PurchasingConstants.autoPurchaseType;
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        if (rowLimitExceeded) {
            this.purchasingViewModel.isLoading = false;
            setTimeout(async () => {
                await this.handleLargePurchaseOrder();
            });
            return;
        }
    }

    public async showPurchasingDialogOrCheckAvailabilityAsNeeded(type: PurchasingType): Promise<void> {
        if (this.isPurchaseOrderInfoRequiredForPresubmit) {
            await this.showPurchaseOrderDialog(type);
        } else {
            this.selectedPurchaseOrderType = type;
            await this.checkAvailability();
        }
    }

    public async showPurchaseOrderDialog(type: PurchasingType): Promise<void> {
        this.selectedPurchaseOrderType = type;
        if (!(await this.getDefaultVendorSettings())) {
            // Don't allow check availability if this false for some reason.
            return;
        }
        this.isPurchaseOrderDialogVisible = true;
        switch (this.selectedPurchaseOrderType) {
            case PurchasingConstants.autoPurchaseType:
                this.purchaseOrderDialogTitle = "Order Digitally";
                break;
            case PurchasingConstants.checkPurchaseType:
                this.purchaseOrderDialogTitle = "Check Availability";
                break;
            case PurchasingConstants.manualPurchaseType:
                this.purchaseOrderDialogTitle = "Manually Order";
                break;
            case PurchasingConstants.pulledPurchaseType:
                this.purchaseOrderDialogTitle = "Pull from Stock";
                break;
        }
    }

    public async closePurchaseOrderDialog(): Promise<void> {
        this.isPurchaseOrderDialogVisible = false;
        this.selectedPurchaseOrderType = null;
    }

    public async acceptPurchaseOrderDialog(): Promise<void> {
        if (!this.selectedPurchaseOrderType) {
            return;
        }
        this.isPurchaseOrderDialogVisible = false;
        switch (this.selectedPurchaseOrderType) {
            case PurchasingConstants.checkPurchaseType:
                await this.checkAvailability();
                break;
            default:
                await this.submitPurchaseOrder(this.selectedPurchaseOrderType);
                break;
        }
    }

    public async refresh(shouldMaintainAvailabilityStatus?: boolean): Promise<void> {
        this.lastPurchaseOrderId = 0;
        const previousLines: IPurchaseOrderLineItem[] = [];
        const manualLines: IPurchaseOrderLineItem[] = [];
        for (const currentLine of this.lines) {
            if (shouldMaintainAvailabilityStatus) {
                previousLines.push(currentLine);
            }
            if (currentLine.wasManuallyOrdered === true) {
                manualLines.push(currentLine);
            }
        }
        await this.list();
        for (const manualLine of manualLines) {
            this.lines.push(manualLine);
        }
        if (shouldMaintainAvailabilityStatus) {
            for (const line of this.lines) {
                let matchingLine: IPurchaseOrderLineItem | null = null;
                if (!line.chipplyProductColorSizeId) {
                    continue;
                }
                if (this.isGroupingByProcess) {
                    if (line.processId && line.processId <= 0) {
                        continue;
                    }
                    const matchinglines = previousLines.filter(
                        (x) =>
                            x.chipplyProductColorSizeId === line.chipplyProductColorSizeId &&
                            x.processId === x.processId
                    );
                    if (matchinglines.length > 0) {
                        matchingLine = matchinglines[0];
                    }
                } else {
                    const matchinglines = previousLines.filter(
                        (x) => x.chipplyProductColorSizeId === line.chipplyProductColorSizeId
                    );
                    if (matchinglines.length > 0) {
                        matchingLine = matchinglines[0];
                    }
                }
                if (matchingLine) {
                    line.availability = matchingLine.availability;
                    line.message = matchingLine.message;
                    line.hasError = matchingLine.hasError;
                    line.warehouse = matchingLine.warehouse;
                }
            }
        }
    }

    public async list(): Promise<void> {
        const baseUrl = this.getListUrl();
        try {
            this.selectedLines.splice(0);
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new ListVendorPurchaseOrderArgs();
            serviceArgs.shouldGroupByProcess = this.isGroupingByProcess;
            // We can only show one group at a time so we prefer the process group.
            if (this.isGroupingByProcess) {
                const fulfilledIndex = this.headers.indexOf(this.fulfillmentHeader);
                if (fulfilledIndex >= 0) {
                    this.headers.splice(fulfilledIndex, 1);
                }
                this.groupBy = "processHeader";
                const index = this.headers.indexOf(this.processHeader);
                if (index < 0) {
                    this.headers.push(this.processHeader);
                }
            } else {
                this.groupBy = "";
                const index = this.headers.indexOf(this.processHeader);
                if (index >= 0) {
                    this.headers.splice(index, 1);
                }
                if (!this.filters.shouldExcludeFulfilledItems && this.canGroupByFulfillmentStatus) {
                    this.groupBy = "isFulfilled";
                    this.headers.push(this.fulfillmentHeader);
                } else {
                    const fulfilledIndex = this.headers.indexOf(this.fulfillmentHeader);
                    if (fulfilledIndex >= 0) {
                        this.headers.splice(fulfilledIndex, 1);
                    }
                }
            }
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.shouldGroupLikeItems = true;
            if (this.purchaseOrderId) {
                this.filters.purchaseOrderId = this.purchaseOrderId;
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as VendorPurchaseOrder;
            if (results) {
                this.toViewModel(results);
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async checkAvailability(): Promise<void> {
        const baseUrl = "/api/purchasing/checkavailability";
        let errorMessage = "";
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new CheckAvailabilityArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as CheckAvailabilityResults;
            if (results) {
                if (results.wasSuccessful === false && results.lines.length <= 0) {
                    errorMessage = results.message;
                    this.purchasingViewModel.errorMessage = errorMessage;
                    this.hasCheckedAvailability = false;
                    return;
                }
                this.hasCheckedAvailability = true;
                this.applyLines(results.lines);
                this.applySort();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
            this.selectedPurchaseOrderType = null;
        }
    }

    public async getDefaultVendorSettings(): Promise<boolean> {
        const baseUrl = "/api/purchasing/getvendorsettings";
        try {
            const previousVendorSettings = this.vendorSettings;
            this.vendorSettings = null;
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new VendorPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = Serializer.deserialize(resultsText) as IVendorPurchaseOrderSettings;
            if (results && previousVendorSettings && previousVendorSettings.vendorName === results.vendorName) {
                this.vendorSettings = previousVendorSettings;
            } else {
                this.shipMethodHint = "";
                this.vendorSettings = results;
            }
            if (this.vendorSettings) {
                this.vendorName = this.vendorSettings.vendorName;
            }
            return true;
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return false;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
        }
    }

    public async submitPurchaseOrder(purchaseOrderType: PurchasingType) {
        this.purchasingViewModel.errorMessage = "";
        this.isSubmitDialogVisible = false;
        this.selectedPurchaseOrderType = purchaseOrderType;
        const baseUrl = "/api/purchasing/submitpurchaseorder";
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new SubmitPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as SubmitPurchaseOrderResults;
            if (results) {
                if (results.wasSuccessful === false && results.lines.length <= 0) {
                    this.purchasingViewModel.errorMessage = results.message;
                    return;
                }
                this.applyLines(results.lines);
                this.isSubmitDialogVisible = false;
                this.lastPurchaseOrderNumber = this.purchaseOrderNumber;
                this.lastPurchaseOrderId = results.purchaseOrderId;
                this.purchaseOrderNumber = "";
                this.lastPurchaseOrderType = this.selectedPurchaseOrderType;
                this.printOrEmail();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isSubmitDialogVisible = false;
            this.selectedPurchaseOrderType = null;
        }
        if (this.currentItem) {
            await this.refreshDetails();
        }
    }

    public productCostChanged(line: IPurchaseOrderLineItem) {
        line.totalCost = line.quantityToOrder * line.productCost;
    }

    public async printOrEmail(): Promise<void> {
        try {
            this.printOrEmailDialogViewModel = new SimpleAsyncInteractionViewModel();
            this.isPrintEmailDialogVisible = true;
            const dialogResult = await this.printOrEmailDialogViewModel.interact();
            if (dialogResult !== "accept") {
                return;
            }
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;

            // Currently used by email since it is not async all the way through,
            // TODO:  Refactor email process to be async.
            this.lastPurchasingReportType = this.reportType;
            if (this.isPrintChecked) {
                const args = new PurchaseOrderReportArgs();
                args.shouldIncludePrice = this.shouldIncludePrice;
                args.shouldIncludeUpc = this.shouldIncludeUpc;
                args.reportType = this.reportType;
                await this.print(args);
            }
            if (this.isEmailChecked) {
                await this.email();
            } else {
                this.lastPurchasingReportType = null;
            }
        } finally {
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;
        }
    }

    public async substituteProduct(): Promise<void> {
        let selectedLine: IPurchaseOrderLineItem | null = null;
        if (this.currentItem) {
            selectedLine = this.currentItem;
        } else if (this.selectedLines.length > 0) {
            selectedLine = this.selectedLines[0];
        }
        const imageUrl = await this.getImageUrl();
        if (imageUrl) {
            this.purchasingViewModel.productsSelectorImage = imageUrl;
        } else {
            this.purchasingViewModel.productsSelectorImage = "";
        }
        if (selectedLine) {
            if (this.selectedLines.length > 1) {
                this.purchasingViewModel.productsSelectorHeading = `Replacing ${selectedLine.vendorName}  &#8226; ${selectedLine.style}  &#8226; ${selectedLine.color}`;
            } else {
                this.purchasingViewModel.productsSelectorHeading = `Replacing ${selectedLine.vendorName}  &#8226; ${selectedLine.style}  &#8226; ${selectedLine.color}  &#8226; ${selectedLine.size}`;
            }
        } else {
            this.purchasingViewModel.productsSelectorHeading = "";
        }
        //this.purchasingViewModel.isProductsSelectorVisible = true;
        const result = await this.purchasingViewModel.selectProducts();

        if (!result) {
            this.purchasingViewModel.isProductsSelectorVisible = false;
            return;
        }
        const selectedEventProductId = result;
        let isRefreshNeeded = false;
        let isDetailsRefreshNeeded = false;
        this.productColorSizeSelectorViewModel = new ProductColorSizeSelectorViewModel();
        this.productColorSizeSelectorViewModel.toReplaceProductImage = this.purchasingViewModel.productsSelectorImage;
        this.productColorSizeSelectorViewModel.eventProductId = selectedEventProductId;

        if (this.currentItem) {
            const selectedLine = this.currentItem;
            this.productColorSizeSelectorViewModel.originalProductStyle = selectedLine.style;
            this.productColorSizeSelectorViewModel.originalProductVendorName = selectedLine.vendorName;
            this.productColorSizeSelectorViewModel.originalProductColor = selectedLine.color;
            this.productColorSizeSelectorViewModel.originalProductSize = selectedLine.size;
        } else if (this.selectedLines.length > 0) {
            const selectedLine = this.selectedLines[0];
            this.productColorSizeSelectorViewModel.originalProductStyle = selectedLine.style;
            this.productColorSizeSelectorViewModel.originalProductVendorName = selectedLine.vendorName;
            this.productColorSizeSelectorViewModel.originalProductColor = selectedLine.color;
            this.productColorSizeSelectorViewModel.originalProductSize = selectedLine.size;
            if (this.selectedLines.length > 1) {
                // Allow the user to sub multiple lines at once if they are of the same vendor/style/color
                this.productColorSizeSelectorViewModel.initializeSizeMappings(this.selectedLines);
            }
        }
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        try {
            await this.productColorSizeSelectorViewModel.getColorSizeInfo(selectedEventProductId);
            this.purchasingViewModel.isProductsSelectorVisible = false;
            this.purchasingViewModel.isProductColorSizeSelectorVisible = true;
            this.purchasingViewModel.isLoading = false;

            const results = await this.productColorSizeSelectorViewModel.edit();
            if (!results.canceled) {
                const args = this.productColorSizeSelectorViewModel.toSubstituteProductArgs();
                args.filters = this.filters;
                args.purchaseOrder = this.toPurchaseOrder();
                if (this.isViewingDetails) {
                    args.ecomOrderItemIds = this.selectedDetails.map((x) => x.orderItemId);
                }
                if (args.replacementChipplyProductColorSizeId !== 0 || args.sizeMappings.length > 0) {
                    this.purchasingViewModel.statusMessage = "Substituting product...";
                    this.purchasingViewModel.isLoading = true;
                    const replacementResults = await WebHelper.postJsonData("/api/purchasing/substituteproduct", args);
                    const deserializedResults = JSON.parse(replacementResults) as ISubstituteProductResults;
                    this.purchasingViewModel.isLoading = false;
                    isRefreshNeeded = true;
                    if (this.isViewingDetails) {
                        isDetailsRefreshNeeded = true;
                    }
                    if (deserializedResults.newlyAddedProducts.length > 0) {
                        this.purchasingViewModel.isProductColorSizeSelectorVisible = false;
                        this.productColorSizeSelectorViewModel = null;
                        const vm = new SimpleAsyncInteractionViewModel();
                        vm.headerText = "Color Assignments";

                        const eventIds = deserializedResults.newlyAddedProducts.map((x) => x.eventId);
                        const uniqueEventIds: number[] = [];
                        for (const eventId of eventIds) {
                            if (!uniqueEventIds.includes(eventId)) {
                                uniqueEventIds.push(eventId);
                            }
                        }
                        if (uniqueEventIds.length > 1) {
                            vm.text = `A substitute product has been added to ${uniqueEventIds.length} stores, would you like to perform color assignments?`;
                        } else {
                            vm.text = `A substitute product has been added to your store, would you like to perform color assignments?`;
                        }
                        this.dialogViewModel = vm;
                        const dialogResults = await this.dialogViewModel.interact();
                        this.dialogViewModel = null;
                        if (dialogResults === "continue") {
                            await this.performColorAssignments(deserializedResults);
                        }
                    }
                }
                this.purchasingViewModel.isProductColorSizeSelectorVisible = false;
                this.productColorSizeSelectorViewModel = null;
            } else {
                this.back();
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        if (isRefreshNeeded) {
            await this.refresh(true);
        }
        if (isDetailsRefreshNeeded) {
            await this.refreshDetails();
            if (this.details.length === 0 && this.isViewingDetails) {
                this.closeDetails();
            }
        }
    }

    public async getImageUrl(): Promise<string> {
        if (this.selectedLines.length < 1) {
            return "";
        }
        const args = new GetPurchaseOrderItemDetailsArgs();
        args.filters = new PurchasingFilters(this.filters);
        args.pageState = this.detailsPageState;
        const line = this.selectedLines[0];
        args.filters.chipplyProductColorSizeId = line.chipplyProductColorSizeId as number;
        if (!args.filters.chipplyProductColorSizeId && this.currentItem?.eventProductColorSizeId) {
            // Handle custom products
            args.filters.eventProductColorSizeId = this.currentItem?.eventProductColorSizeId;
        } else if (!args.filters.chipplyProductColorSizeId && this.currentItem?.orderItemId) {
            // Handle bad data where there is no chipplyProductColorSizeId
            args.filters.orderItemId = this.currentItem?.orderItemId;
        }
        args.sortProperties = this.selectedDetailsSortProperties;
        const results = await WebHelper.postJsonData("/api/purchasing/listorderitemdetails", args);
        const deserializedResults = JSON.parse(results) as IGetPurchaseOrderItemDetailsResults;
        return deserializedResults.imageUrl;
    }

    public async productsSelectorClosed(accepted: boolean, selectedEventProductId: number): Promise<void> {}

    public getFileExportButtonName() {
        return `Export ${this.vendorName} File`;
    }

    public async createPurchaseOrderFile(): Promise<void> {
        this.purchasingViewModel.errorMessage = "";
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        const url = "/api/reports/purchaseorder/export";
        const filename = "PurchaseOrder.csv";

        try {
            const args = new PurchaseOrderReportArgs();
            if (this.lastPurchaseOrderId) {
                args.purchaseOrderIds.push(this.lastPurchaseOrderId);
            }
            if (this.purchaseOrderId) {
                args.purchaseOrderIds.push(this.purchaseOrderId);
            }
            args.filters = this.filters;
            args.purchaseOrder = this.toPurchaseOrder();
            args.purchaseOrder.purchaseOrderId = this.lastPurchaseOrderId;
            const attachment = await WebHelper.postJsonDataBlob(url, args);
            WebHelper.downloadAsAttachment(attachment, filename);
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async exportCsv(details: boolean): Promise<void> {
        this.purchasingViewModel.errorMessage = "";
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        const url = "/api/Reports/PurchaseOrder/csv";
        let filename = "";
        if (details) {
            filename = "PurchaseOrderDetail.csv";
        } else {
            filename = "PurchaseOrder.csv";
        }
        try {
            const args = new PurchaseOrderReportArgs();
            if (this.lastPurchaseOrderId) {
                args.purchaseOrderIds.push(this.lastPurchaseOrderId);
            }
            if (this.purchaseOrderId) {
                args.purchaseOrderIds.push(this.purchaseOrderId);
            }
            args.filters = this.filters;
            if (details) {
                args.reportType = "Details";
            } else {
                args.reportType = "Summary";
            }
            args.purchaseOrder = this.toPurchaseOrder();
            args.purchaseOrder.purchaseOrderId = this.lastPurchaseOrderId;
            const attachment = await WebHelper.postJsonDataBlob(url, args);
            WebHelper.downloadAsAttachment(attachment, filename);
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async print(args?: PurchaseOrderReportArgs): Promise<void> {
        this.purchasingViewModel.errorMessage = "";
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        try {
            if (!args) {
                args = new PurchaseOrderReportArgs();
                args.reportType = this.getPurchasingReportType();
            }
            if (this.lastPurchaseOrderId) {
                args.purchaseOrderIds.push(this.lastPurchaseOrderId);
            }
            if (this.purchaseOrderId) {
                args.purchaseOrderIds.push(this.purchaseOrderId);
            }

            args.filters = this.filters;
            args.purchaseOrder = this.toPurchaseOrder();
            args.purchaseOrder.purchaseOrderId = this.lastPurchaseOrderId;
            const attachment = await WebHelper.postJsonDataBlob("/api/Reports/PurchaseOrder", args);
            let filename = "PurchaseOrder.pdf";
            switch (args.reportType) {
                case "Details":
                    filename = "PurchaseOrderDetail.pdf";
                    break;
                case "Pulled":
                    filename = "PullFromStock.pdf";
                    break;
                default:
                    filename = "PurchaseOrder.pdf";
                    break;
            }
            WebHelper.downloadAsAttachment(attachment, filename);
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public email() {
        this.purchasingViewModel.isEmailWindowVisible = true;
    }

    public emailClose = (accepted: boolean, emailTemplate: PurchasingMergeTemplateViewModel) => {
        this.sendEmail(accepted, emailTemplate);
    };

    public async viewDetails(item: IPurchaseOrderLineItem): Promise<any> {
        this.harvestSelectedDetailsAsNeeded();
        if (this.currentItem === item) {
            this.closeDetails();
            return;
        }
        this.currentItem = item;
        this.isViewingDetails = true;
        this.headers.splice(0);
        this.headers.push(...this.simpleHeaders);
        await this.refreshDetails();
    }

    public editNote(item: IPurchaseOrderItemDetail) {
        this.currentDetails = item;
        this.isEditNoteVisible = true;
    }

    public async saveDetails(): Promise<void> {
        try {
            if (!this.currentDetails) {
                return;
            }
            this.isEditNoteVisible = false;
            this.purchasingViewModel.statusMessage = "Saving notes...";
            this.purchasingViewModel.isLoading = true;
            const args = new SaveOrderItemDetailsArgs();
            if (this.purchaseOrderId) {
                // We are updating an existing purchase order
                args.purchaseOrderId = this.purchaseOrderId;
            }
            args.details.push(this.currentDetails);
            const results = await WebHelper.postJsonData("/api/purchasing/saveorderitemdetails", args);
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        await this.refresh(true);
        await this.refreshDetails();
    }

    public async bulkUpdateNote(): Promise<void> {
        try {
            if (!this.selectedLines && !this.selectedDetails) {
                return;
            }
            if (!this.bulkNote) {
                return;
            }
            this.isBulkNoteVisible = false;
            this.purchasingViewModel.statusMessage = "Saving notes...";
            this.purchasingViewModel.isLoading = true;
            const args = new BulkUpdateNoteArgs();
            args.purchaseOrder = this.toPurchaseOrder();
            args.filters = this.filters;
            args.note = this.bulkNote;
            const results = await WebHelper.postJsonData("/api/purchasing/bulkupdatenote", args);
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.bulkNote = "";
        }
        if (this.currentItem) {
            await this.refreshDetails();
        }
    }

    public selectedDetailsChanged() {
        if (this.selectedDetails.length === 0) {
            this.isSelectAllDetails = false;
            this.isSelectAllDetailsIndeterminate = false;
        } else if (this.selectedDetails.length === this.details.length) {
            this.isSelectAllDetails = true;
            this.isSelectAllDetailsIndeterminate = false;
        } else {
            this.isSelectAllDetails = false;
            this.isSelectAllDetailsIndeterminate = true;
        }
        if (this.currentItem) {
            if (this.selectedDetails.length === 0 || this.selectedDetails.length >= this.details.length) {
                this.currentItem.quantityToOrder = Math.max(
                    this.currentItem.quantity - this.currentItem.quantityOrdered,
                    0
                );
            } else {
                let quantityToOrder = 0;
                for (const selectedDetail of this.selectedDetails) {
                    quantityToOrder += selectedDetail.quantityToOrder;
                }
                if (quantityToOrder > 0) {
                    this.currentItem.quantityToOrder = quantityToOrder;
                }
            }
        }
        if (this.selectedDetails.length > 0 && this.currentItem && !this.selectedLines.includes(this.currentItem)) {
            // If we are selecting details the current line needs to be selected
            // so the current line (and its selected details) can be sent to the server
            // for purchasing or bulk operations
            this.selectedLines.push(this.currentItem);
        }
    }

    public selectAllDetails() {
        this.selectedDetails.splice(0);
        if (this.isSelectAllDetails) {
            for (const item of this.details) {
                this.selectedDetails.push(item);
            }
        }
    }

    public async listDetails(): Promise<void> {
        try {
            if (this.detailsPageState.wasLastPageSelected) {
                return;
            }
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const args = new GetPurchaseOrderItemDetailsArgs();
            args.filters = new PurchasingFilters(this.filters);
            args.pageState = this.detailsPageState;
            args.filters.chipplyProductColorSizeId = this.currentItem?.chipplyProductColorSizeId as number;
            if (!args.filters.chipplyProductColorSizeId && this.currentItem?.eventProductColorSizeId) {
                // Handle custom products
                args.filters.eventProductColorSizeId = this.currentItem?.eventProductColorSizeId;
            } else if (!args.filters.chipplyProductColorSizeId && this.currentItem?.orderItemId) {
                // Handle bad data where there is no chipplyProductColorSizeId
                args.filters.orderItemId = this.currentItem?.orderItemId;
            }

            if (this.currentItem?.processId && this.currentItem.processId > 0) {
                args.filters.selectedProcessIds = [this.currentItem.processId];
            }
            args.sortProperties = this.selectedDetailsSortProperties;
            const results = await WebHelper.postJsonData("/api/purchasing/listorderitemdetails", args);
            const deserializedResults = JSON.parse(results) as IGetPurchaseOrderItemDetailsResults;
            this.currentItemImageUrl = deserializedResults.imageUrl;
            this.details.push(...deserializedResults.details);
            this.detailsPageState.next(deserializedResults.details.length);
            if (this.currentItem && this.currentItem.selectedDetails && this.currentItem.selectedDetails.length > 0) {
                for (const detail of this.details) {
                    for (const selectedDetail of this.currentItem.selectedDetails) {
                        if (detail.orderItemId === selectedDetail.orderItemId) {
                            this.selectedDetails.push(detail);
                        }
                    }
                }
            }
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async showOrderItemTimeline(item: IPurchaseOrderItemDetail): Promise<void> {
        this.currentHistory.splice(0);
        await this.listOrderItemHistory(item);
        this.isTimelineVisible = true;
    }

    public hideOrderItemTimeline() {
        this.isTimelineVisible = false;
        this.currentHistory.splice(0);
    }

    public isSubstituteProductEnabled(): boolean {
        if (this.selectedLines.length === 0) {
            return false;
        }
        for (const currentLine of this.selectedLines) {
            if (currentLine.wasManuallyOrdered) {
                return false;
            }
        }

        if (this.selectedLines.length === 1) {
            return true;
        }

        const firstLine = this.selectedLines[0];
        if (!firstLine.chipplyProductColorSizeId) {
            return false;
        }
        for (const line of this.selectedLines) {
            if (!line.chipplyProductColorSizeId) {
                return false;
            }
            if (
                line.vendorId !== firstLine.vendorId ||
                line.style !== firstLine.style ||
                line.color !== firstLine.color
            ) {
                return false;
            }
        }
        return true;
    }

    public async listOrderItemHistory(item: IPurchaseOrderItemDetail): Promise<void> {
        try {
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const args = new ListOrderItemHistoryArgs();
            args.orderItemId = item.orderItemId;
            const results = await WebHelper.postJsonData("/api/purchasing/listorderitemhistory", args);
            const deserializedResults = JSON.parse(results) as IListOrderItemHistoryResults;
            this.currentHistory.push(...deserializedResults.history);
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public sanMarShipMethodChange() {
        this.setSanMarHintAsNeeded();
    }

    public getTotals() {
        {
            let lines = this.lines;
            if (this.selectedLines.length > 0) {
                lines = this.selectedLines;
            }
            const totals = lines.reduce(
                (previousValue: any, currentValue: any) => {
                    previousValue.quantity += currentValue.quantity;
                    previousValue.quantityOrdered += currentValue.quantityOrdered;
                    previousValue.quantityToOrder += parseInt(currentValue.quantityToOrder, 10);
                    previousValue.totalCost += currentValue.totalCost;
                    return previousValue;
                },
                {
                    quantity: 0,
                    quantityOrdered: 0,
                    quantityToOrder: 0,
                    totalCost: 0,
                }
            );
            return totals;
        }
    }

    public async add(): Promise<void> {
        const result = await this.purchasingViewModel.selectProducts();
        if (!result) {
            return;
        }
        const selectedEventProductId = result;
        this.productColorSizeSelectorViewModel = new ProductColorSizeSelectorViewModel();
        this.productColorSizeSelectorViewModel.eventProductId = selectedEventProductId;
        this.purchasingViewModel.isProductColorSizeSelectorVisible = true;

        this.purchasingViewModel.isLoading = true;
        await this.productColorSizeSelectorViewModel.getColorSizeInfo(selectedEventProductId);

        this.purchasingViewModel.isLoading = false;
        this.productColorSizeSelectorViewModel.isPerformingManualAdd = true;
        const results = await this.productColorSizeSelectorViewModel.edit();
        const mappings = this.productColorSizeSelectorViewModel.toManualSizeMappings();

        const args = new ManualAddPurchasingItemArgs();
        for (const mapping of mappings) {
            if (mapping.chipplyProductColorSizeId && mapping.quantity > 0) {
                const item = new ManualAddPurchasingItem();
                item.eventProductColorSizeId = mapping.chipplyProductColorSizeId;
                item.quantity = mapping.quantity;
                args.items.push(item);
            }
        }

        const manualaddResults = await this.getManualAddItem(args);
        this.purchasingViewModel.isProductColorSizeSelectorVisible = false;
        this.productColorSizeSelectorViewModel = null;
        this.lines.push(...manualaddResults.lines);
        this.selectedLines.push(...manualaddResults.lines);
    }

    protected async getManualAddItem(args: ManualAddPurchasingItemArgs): Promise<ManualAddPurchasingItemResults> {
        const results = await WebHelper.postJson<ManualAddPurchasingItemResults>("/api/purchasing/manualadd", args);
        return results;
    }

    protected setSanMarHintAsNeeded() {
        if (this.vendorSettings) {
            const shipMethod = (this.vendorSettings as SanMarPurchaseOrderSettings).shipMethod;
            const item = this.sanMarShipMethods.find((x) => x.method === shipMethod);
            if (item) {
                this.shipMethodHint = item.description;
            } else {
                this.shipMethodHint = "";
            }
        } else {
            this.shipMethodHint = "";
        }
    }

    protected applySort() {
        if (this.sortBy.length === 0) {
            this.sortBy = ["availability", "vendorName", "style", "color", "size"];
            this.sortDesc = [true, false, false, false, false];
        }
    }

    protected async performColorAssignments(substituteProductResults: ISubstituteProductResults): Promise<void> {
        this.colorAssignmentViewModel = new PurchasingColorAssignmentPageViewModel(
            this.purchasingViewModel as PurchasingViewModel
        );
        this.colorAssignmentViewModel.newlyAddedProducts = substituteProductResults.newlyAddedProducts;
        this.colorAssignmentViewModel.total = substituteProductResults.newlyAddedProducts.length;
        this.purchasingViewModel.isColorAssignmentVisible = true;
        await this.colorAssignmentViewModel.next();
    }

    protected async refreshDetails(): Promise<void> {
        this.detailsPageState.reset();
        this.details.splice(0);
        this.selectedDetails.splice(0);
        for (const item of this.lines) {
            if (item.chipplyProductColorSizeId) {
                if (this.currentItem && this.currentItem.chipplyProductColorSizeId === item.chipplyProductColorSizeId) {
                    if (this.isGroupingByProcess) {
                        if (this.currentItem.processId === item.processId) {
                            this.currentItem = item;
                        }
                        break;
                    } else {
                        this.currentItem = item;
                        break;
                    }
                }
            } else if (item.eventProductColorSizeId) {
                if (this.currentItem && this.currentItem.eventProductColorSizeId === item.eventProductColorSizeId) {
                    if (this.isGroupingByProcess) {
                        if (this.currentItem.processId === item.processId) {
                            this.currentItem = item;
                        }
                        break;
                    } else {
                        this.currentItem = item;
                        break;
                    }
                }
            }
        }
        await this.listDetails();
    }

    protected async sendEmail(accepted: boolean, emailTemplate: PurchasingMergeTemplateViewModel) {
        this.purchasingViewModel.isEmailWindowVisible = false;
        this.purchasingViewModel.statusMessage = "Saving...";
        this.purchasingViewModel.isLoading = true;
        emailTemplate.shouldIncludeUpc = this.shouldIncludeUpc;
        emailTemplate.shouldIncludePrice = this.shouldIncludePrice;
        try {
            await this.saveEmailTemplate(emailTemplate.toModel());
        } catch {
            this.purchasingViewModel.isLoading = false;
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return;
        }
        this.purchasingViewModel.isLoading = false;
        const scheduledDate = emailTemplate.getDate(emailTemplate.staticDate!, emailTemplate.staticTime!);
        let vm: SimpleAsyncInteractionViewModel | null = null;
        if (DateTime.fromISO(scheduledDate) <= DateTime.local()) {
            vm = new SimpleAsyncInteractionViewModel();
            vm.headerText = "Message Sent";
            vm.text = "The message has successfully been sent.";
        } else {
            vm = new SimpleAsyncInteractionViewModel();
            vm.headerText = "Message Scheduled";
            vm.text = "The message has successfully been scheduled to be sent.";
        }
        this.dismissDialogViewModel = vm;
        await this.dismissDialogViewModel.interact();
        this.dismissDialogViewModel = null;
    }

    protected async saveEmailTemplate(template: IPurchasingMergeTemplate): Promise<any> {
        const purchaseOrderIds: number[] = [];
        if (this.lastPurchaseOrderId) {
            purchaseOrderIds.push(this.lastPurchaseOrderId);
        }
        if (this.purchaseOrderId) {
            purchaseOrderIds.push(this.purchaseOrderId);
        }
        const args = new SendPurchasingEmailArgs(
            this.toPurchaseOrder(),
            this.filters,
            template,
            purchaseOrderIds,
            this.lastPurchasingReportType != null ? this.lastPurchasingReportType : this.getPurchasingReportType()
        );
        args.purchaseOrder.purchaseOrderId = this.lastPurchaseOrderId;
        if (this.purchaseOrderId) {
            args.purchaseOrder.purchaseOrderId = this.purchaseOrderId;
        }
        await WebHelper.postJsonData("/api/purchasing/sendemail", args);
    }

    protected getPurchasingReportType(): PurchasingReportType {
        let reportType = this.reportType;
        if (this.lastPurchaseOrderType === "Pulled") {
            reportType = "Pulled";
        } else if (this.isGroupingByProcess) {
            reportType = "Details";
        } else {
            reportType = "Summary";
        }
        return reportType;
    }

    protected applyLines(lines: IPurchaseOrderLineItem[]) {
        const fulfilledItems: IPurchaseOrderLineItem[] = [];
        for (const line of lines) {
            for (const matchingLine of this.lines) {
                if (matchingLine.id !== line.id) {
                    continue;
                }
                matchingLine.quantityToOrder = line.quantityToOrder;
                matchingLine.message = line.message;
                matchingLine.hasError = line.hasError;
                matchingLine.availability = line.availability;
                matchingLine.quantityOrdered = line.quantityOrdered;
                matchingLine.warehouse = line.warehouse;
                matchingLine.productCost = line.productCost;
                matchingLine.totalCost = line.totalCost;
                matchingLine.isFulfilled = line.isFulfilled;
                if (
                    (matchingLine.hasError || matchingLine.availability === "Unavailable") &&
                    this.selectedLines.indexOf(matchingLine) >= 0
                ) {
                    const index = this.selectedLines.indexOf(matchingLine);
                    if (index >= 0) {
                        this.selectedLines.splice(index, 1);
                    }
                }
                if (line.isFulfilled) {
                    fulfilledItems.push(matchingLine);
                }

                if (matchingLine.isFulfilled && this.selectedLines.indexOf(matchingLine) >= 0) {
                    const index = this.selectedLines.indexOf(matchingLine);
                    if (index >= 0) {
                        this.selectedLines.splice(index, 1);
                    }
                }
            }
        }
        for (const fulfilledItem of fulfilledItems) {
            const index = this.lines.indexOf(fulfilledItem);
            if (index >= 0) {
                this.lines.splice(index, 1);
            }
        }
    }

    protected toPurchaseOrder(): VendorPurchaseOrder {
        this.harvestSelectedDetailsAsNeeded();
        const purchaseOrder = new VendorPurchaseOrder();
        purchaseOrder.shipEmail = this.shipEmail;
        purchaseOrder.lines = this.selectedLines;
        purchaseOrder.customerNumber = this.customerNumber;
        purchaseOrder.purchaseOrderNumber = this.purchaseOrderNumber;
        purchaseOrder.vendorName = this.vendorName;
        purchaseOrder.attention = this.attention;
        purchaseOrder.shipTo = this.shipTo;
        purchaseOrder.type = this.selectedPurchaseOrderType;
        if (this.purchaseOrderId) {
            purchaseOrder.purchaseOrderId = this.purchaseOrderId;
        }
        if (this.shouldShipToBranch) {
            purchaseOrder.dealerBranchId = this.dealerBranchId;
        } else {
            purchaseOrder.dealerBranchId = null;
        }
        purchaseOrder.vendorSettings = this.vendorSettings;
        return purchaseOrder;
    }

    protected toViewModel(purchaseOrder: IVendorPurchaseOrder<IPurchaseOrderLineItem>) {
        this.lines.splice(0);
        if (this.isGroupingByProcess) {
            for (const line of purchaseOrder.lines) {
                line.processHeader = `${line.saleOrder} ${line.processName}`;
                this.lines.push(line);
            }
        } else {
            this.lines.push(...purchaseOrder.lines);
        }
        this.applyPurchaseOrderHeader(purchaseOrder);
        this.selectedLines.splice(0);
        this.selectLinesAsNeeded();
        this.totals = purchaseOrder.totals;
        if (purchaseOrder.type) {
            this.type = purchaseOrder.type;
        }
    }

    protected selectLinesAsNeeded() {
        this.selectedLines.push(...this.lines);
    }

    protected harvestSelectedDetailsAsNeeded() {
        if (this.currentItem) {
            // If there are selected details, track them on the parent item for later processing
            this.currentItem.selectedDetails = [];
            if (this.selectedDetails.length > 0) {
                this.currentItem.selectedDetails.push(...this.selectedDetails);
            }
        }
    }

    protected getListUrl(): string {
        const baseUrl = "/api/purchasing/list";
        return baseUrl;
    }

    protected async handleLargePurchaseOrder(): Promise<void> {
        const vm = new SimpleAsyncInteractionViewModel();
        vm.headerText = "Select Stores";
        vm.text = `The configured purchase order exceeds the configured size limit.  Please select a smaller number of stores or processes.`;
        this.dismissDialogViewModel = vm;
        const dialogResults = await this.dismissDialogViewModel.interact();
        this.dialogViewModel = null;
        this.back();
    }

    private closeDetails(): void {
        this.currentItem = null;
        this.isViewingDetails = false;
        this.headers.splice(0);
        this.headers.push(...this.allHeaders);
    }
}
