var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card',[_c('v-card-title',[_c('text-heading',[_vm._v("Tax Settings")])],1),_c('v-card-text',[_c('div',{staticClass:"heading font-weight-bold",staticStyle:{"padding-top":"20px"}},[_vm._v("Products")]),_c('v-checkbox',{attrs:{"label":"Change Product Tax Type"},model:{value:(_vm.order.changeTaxType),callback:function ($$v) {_vm.$set(_vm.order, "changeTaxType", $$v)},expression:"order.changeTaxType"}}),_c('tax-type-selection',{attrs:{"disabled":!_vm.order.changeTaxType,"value":{
                    taxType: _vm.order.selectedTaxType,
                    taxRate: _vm.order.taxRate,
                }},on:{"input":_vm.selectedTaxSettingsChanged}}),_c('div',{staticClass:"heading font-weight-bold",staticStyle:{"padding-top":"20px"}},[_vm._v("Shipping")]),_c('tax-type-selection',{attrs:{"value":{
                    taxType: _vm.order.shippingTaxMethod,
                    taxRate: _vm.order.shippingTaxRate,
                }},on:{"input":_vm.selectedShippingTaxSettingsChanged}}),_c('div',{staticClass:"heading font-weight-bold",staticStyle:{"padding-top":"20px"}},[_vm._v("Handling")]),_c('tax-type-selection',{attrs:{"value":{
                    taxType: _vm.order.handlingTaxMethod,
                    taxRate: _vm.order.handlingTaxRate,
                }},on:{"input":_vm.selectedHandlingTaxSettingsChanged}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.close(false)}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":!_vm.isValid,"color":"primary","text":""},on:{"click":function($event){return _vm.close(true)}}},[_vm._v("Accept")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }