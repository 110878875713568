import IPurchasingFilters from "./IPurchasingFilters";

export default class PurchasingFilters implements IPurchasingFilters {
    public artworkIds: number[] = [];
    public artworkName = "";
    public externalId = "";
    public vendorIds: number[] = [];
    public style: string | null = null;
    public shouldExcludeFulfilledItems = true;
    public salesRepId: number | null = null;
    public saleOrder = "";
    public storeName = "";
    public color = "";
    public size = "";
    public chipplyProductColorSizeId: number | null = null;
    public eventProductColorSizeId: number | null = null;
    public purchaseOrderId: number | null = null;
    public purchaseOrderNumber = "";
    public ordersToDate: string | null = null;
    public ordersFromDate: string | null = null;
    public orderItemId: number | null = null;
    public processName = "";
    public selectedProcessIds: number[] = [];
    public shouldIncludeOnlyIntegratedVendors = false;
    public storeIds: number[] = [];
    public variationName = "";

    public constructor(filters?: IPurchasingFilters) {
        if (filters) {
            this.artworkIds = filters.artworkIds;
            this.artworkName = filters.artworkName;
            this.externalId = filters.externalId;
            this.storeIds = filters.storeIds;
            this.vendorIds = filters.vendorIds;
            this.style = filters.style;
            this.shouldExcludeFulfilledItems = filters.shouldExcludeFulfilledItems;
            this.salesRepId = filters.salesRepId;
            this.saleOrder = filters.saleOrder;
            this.storeName = filters.storeName;
            this.color = filters.color;
            this.size = filters.size;
            this.eventProductColorSizeId = filters.eventProductColorSizeId;
            this.chipplyProductColorSizeId = filters.chipplyProductColorSizeId;
            this.purchaseOrderNumber = filters.purchaseOrderNumber;
            this.purchaseOrderId = filters.purchaseOrderId;
            this.ordersFromDate = filters.ordersFromDate;
            this.ordersToDate = filters.ordersToDate;
            this.orderItemId = filters.orderItemId;
            this.processName = filters.processName;
            this.selectedProcessIds = filters.selectedProcessIds;
            this.shouldIncludeOnlyIntegratedVendors = filters.shouldIncludeOnlyIntegratedVendors;
            this.variationName = filters.variationName;
        }
    }
}
