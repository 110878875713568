






import Vue from "vue";
import Component from "vue-class-component";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { EventBus } from "@/chipply/EventBus";

@Component({
    props: {
        navigationLocation: String,
        postbackPath: String,
        buttonText: String,
        newWindow: Boolean,
        noHighlight: Boolean,
        matchQueryParameters: Array,
        ignoredQueryParameters: Array,
        notMatchWithQueryString: Boolean,
    },
})
export default class NavigationSideButton extends Vue {
    get isSelected() {
        if (this.noHighlight) {
            return false;
        }
        if (!this.navigationLocation) {
            return false;
        }
        const pageNameMatches = this.location.indexOf(this.getLocationRoot(this.navigationLocation)) > -1;
        if (!pageNameMatches) {
            return false;
        }
        return this.queryStringMatches();
    }
    public name = "NavigationSideButton";
    public navigationLocation!: string;
    public postbackPath!: string;
    public newWindow!: boolean;
    public location!: string;
    public noHighlight!: boolean;
    public matchQueryParameters!: string[];
    public ignoredQueryParameters!: string[];
    public notMatchWithQueryString!: boolean;

    public async buttonClicked(): Promise<void> {
        if (!this.postbackPath && !this.navigationLocation) {
            return;
        }

        if (!this.newWindow) {
            const eventArgs: IAsyncEventArgs = { promises: [] };
            EventBus.$emit("top-navigation-started", this.navigationLocation, eventArgs);
            await Promise.all(eventArgs.promises);
            if (eventArgs.cancel) {
                return;
            }
        }

        if (this.postbackPath && (window as any).__doPostBack) {
            (window as any).__doPostBack(this.postbackPath, "");
        } else if (this.newWindow) {
            window.open(this.navigationLocation, "_blank");
        } else {
            window.location.assign(this.navigationLocation);
        }
    }
    public mounted() {
        this.location = window.location.href;
    }

    public data() {
        return {
            location: "",
        };
    }

    private queryStringMatches() {
        const queryStringStartIndex = this.navigationLocation.indexOf("?");
        const hasNoQueryString = queryStringStartIndex < 0;

        const queryString = this.navigationLocation.substring(queryStringStartIndex);
        const navigationQueryString = new URLSearchParams(queryString);
        const pageQueryString = new URLSearchParams(window.location.search);

        if (this.notMatchWithQueryString) {
            let totalEntries = 0;
            for (const entry of pageQueryString.entries()) {
                totalEntries++;
            }

            if (pageQueryString.has("v") && totalEntries > 1) {
                return false;
            }
            if (totalEntries > 0) {
                return false;
            }
            return true;
        }

        const ignoreQueryString =
            (this.matchQueryParameters || []).length === 0 &&
            !this.notMatchWithQueryString &&
            (this.ignoredQueryParameters || []).length === 0;

        if (ignoreQueryString) {
            const baseUrl =
                queryStringStartIndex > -1
                    ? this.navigationLocation.substring(0, queryStringStartIndex)
                    : this.navigationLocation;

            return window.location.pathname === baseUrl;
        }

        if (hasNoQueryString) {
            return false;
        }

        for (const parameter of this.matchQueryParameters || []) {
            if (!pageQueryString.has(parameter)) {
                return false;
            }
            if (pageQueryString.get(parameter) !== navigationQueryString.get(parameter)) {
                return false;
            }
        }

        let ignoredParametersCount = pageQueryString.has("v") ? 1 : 0;
        if (pageQueryString.has("state")) {
            ignoredParametersCount++;
        }

        let totalPageQueryEntries = 0;
        for (const e of pageQueryString.entries()) {
            totalPageQueryEntries++;
        }

        for (const param of this.ignoredQueryParameters || []) {
            if (pageQueryString.has(param)) {
                ignoredParametersCount++;
            }
        }

        if ((this.matchQueryParameters || []).length === totalPageQueryEntries - ignoredParametersCount) {
            return true;
        }
        return false;
    }

    private getLocationRoot(location: string) {
        location = location || "";
        const extension = location.indexOf("?");
        if (extension > -1) {
            return location.substring(0, extension);
        }
        return location;
    }
}
