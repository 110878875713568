import { IOrderTotals } from "chipply-common";

export default class OrderTotalsViewModel {
    public cartTotal!: number;
    public ecertTotal!: number;
    public handlingTotal!: number;
    public orderTotal!: number;
    public shippingTotal!: number;
    public taxTotal!: number;
    public chipplyFeeTotal!: number;
    public couponTotal!: number;

    constructor(orderTotals?: IOrderTotals) {
        Object.assign(this, orderTotals);

        if (!orderTotals) {
            this.cartTotal = 0;
            this.ecertTotal = 0;
            this.handlingTotal = 0;
            this.orderTotal = 0;
            this.shippingTotal = 0;
            this.taxTotal = 0;
            this.chipplyFeeTotal = 0;
            this.couponTotal = 0;
        }
    }

    public toModel(): IOrderTotals {
        return {
            cartTotal: this.cartTotal,
            chipplyFeeTotal: this.chipplyFeeTotal,
            couponTotal: this.couponTotal,
            ecertTotal: this.ecertTotal,
            handlingTotal: this.handlingTotal,
            orderTotal: this.orderTotal,
            shippingTotal: this.shippingTotal,
            taxTotal: this.taxTotal,
        };
    }

    public get cardTotal() {
        const total =
            Math.round(this.orderTotal * 100) - Math.round(this.ecertTotal * 100) - Math.round(this.couponTotal * 100);
        return total / 100;
    }

    public get ecertOrderTotal() {
        const couponTotal = this.couponTotal;
        return (Math.round(this.ecertTotal * 100) - Math.round(couponTotal * 100)) / 100;
    }
}
