import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import { WebHelper, Serializer, Address, Utils } from "chipply-common";
import ITextValueDisabled from "../interface/ITextValueDisabled";
import DealerBranchModel from "./DealerBranchModel";
import ListDealerBranchesResults from "./ListDealerBranchesResults";
import OrganizationContactEditViewModel from "../organization/OrganizationContactEditViewModel";
import DealerBranchSaveArgs from "./DealerBranchSaveArgs";
import OrganizationContactSaveArgs from "../organization/OrganizationContactSaveArgs";
import StoreOrganizationContactModel from "../organization/StoreOrganizationContactModel";

export default class DealerBranchesViewModel extends PageViewModel {
    public items: DealerBranchModel[] = [];
    public isEdit = false;
    public isAdmin = false;
    public disableGutters = true;
    public currentItem: DealerBranchModel | null = null;
    public originalItem: DealerBranchModel | null = null;
    public saving = false;
    public sameAsBranchInfo = false;
    public addressTypes = [
        { value: 1, text: "Commercial" },
        { value: 2, text: "Residential" },
    ];
    public headers = [
        { value: "edit" },
        { text: "Active", value: "enabled", width: "8%" },
        { text: "Branch Name", value: "branchName", width: "25%" },
        { text: "Primary User", value: "primaryUser" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
    ];
    public addContact = false;
    public addNew = false;
    public newContact: OrganizationContactEditViewModel = new OrganizationContactEditViewModel();
    public showDialog = true;
    public companyUsers: Array<ITextValueDisabled<number>> = [];
    public branchNameValidation = {
        valid: true,
        loading: false,
    };

    public async list(companyId: number | null): Promise<void> {
        let baseUrl = "";
        if (this.isAdmin) {
            baseUrl = "/api/dealer/listadmindealerbranches?companyId=" + companyId;
        } else {
            if (companyId) {
                baseUrl = "/api/dealer/listorganizationbranches?companyId=" + companyId;
            } else {
                baseUrl = "/api/dealer/listdealerbranches";
            }
        }

        try {
            this.statusMessage = this.loadingMessage;
            this.loading = true;
            const results = (await WebHelper.getJsonData(baseUrl)) as ListDealerBranchesResults;
            if (results) {
                this.items = results.branches;
                this.companyUsers = results.companyUsers;
            }
        } finally {
            this.loading = false;
        }
    }

    public async listCompanyUsers(companyId: number | null): Promise<void> {
        let baseUrl = "";
        if (this.isAdmin) {
            baseUrl = "/api/dealer/listadmindealerbranches?companyId=" + companyId;
        } else {
            if (companyId) {
                baseUrl = "/api/dealer/listorganizationbranches?companyId=" + companyId;
            } else {
                baseUrl = "/api/dealer/listdealerbranches";
            }

            try {
                this.loading = true;
                const results = (await WebHelper.getJsonData(baseUrl)) as ListDealerBranchesResults;
                if (results) {
                    this.companyUsers = results.companyUsers;
                }
            } catch (error) {
                this.errorMessage = Utils.ServerErrorMessage;
            } finally {
                this.loading = false;
            }
        }
    }

    public updateSameAsBranchInfo() {
        if (!this.currentItem) {
            return;
        }

        if (!this.sameAsBranchInfo) {
            const billingAddress = new Address();
            this.currentItem.billingAddress = billingAddress;
            this.currentItem.billingAddressType = null;
            this.currentItem.billingAddress.country = "USA";
            this.currentItem.billingPoBox = "";
            this.currentItem.billingPhone = "";
            this.currentItem.billingFax = "";
            this.currentItem.billingEmail = "";
        }

        if (this.isValid) {
            return;
        }
    }

    public edit(item: DealerBranchModel) {
        const originalItemText = Serializer.serialize(item);
        this.originalItem = Serializer.deserialize(originalItemText);
        this.isEdit = true;
        this.currentItem = item;
        this.sameAsBranchInfo = !this.currentItem.billingAddress;
    }

    public cancelEdit() {
        if (this.originalItem && this.currentItem) {
            const index = this.items.indexOf(this.currentItem);
            this.currentItem = this.originalItem;
            this.items[index] = this.originalItem;
            this.originalItem = null;
        }
        this.isEdit = false;
        this.addNew = false;
        this.addContact = false;
    }

    public async saveBranch(companyId: number | null): Promise<void> {
        const args = new DealerBranchSaveArgs();
        let baseUrl = "";
        if (this.isAdmin) {
            baseUrl = "/api/dealer/saveadmindealerbranch";
            args.companyId = companyId;
        } else {
            if (companyId) {
                baseUrl = "/api/dealer/saveorganizationbranch";
                args.companyId = companyId;
            } else {
                baseUrl = "/api/dealer/savedealerbranch";
            }
        }
        this.isEdit = false;
        if (this.addNew && this.currentItem) {
            this.addNew = false;
        }
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            if (this.currentItem) {
                if (this.sameAsBranchInfo) {
                    this.currentItem.billingAddress = this.currentItem.address;
                    this.currentItem.billingAddressType = this.currentItem.addressType;
                    this.currentItem.billingPoBox = this.currentItem.poBox;
                    this.currentItem.billingPhone = this.currentItem.phone;
                    this.currentItem.billingFax = this.currentItem.fax;
                    this.currentItem.billingEmail = this.currentItem.email;
                }
                this.currentItem.phone = this.removePhoneFormatting(this.currentItem.phone);
                this.currentItem.billingPhone = this.removePhoneFormatting(this.currentItem.billingPhone);
                args.Model = this.currentItem;
                const result = await WebHelper.postJsonData(baseUrl, args);
                await this.list(companyId);
            }
        } catch (error) {
            this.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.loading = false;
        }
    }

    public addNewBranch() {
        const branchModel = new DealerBranchModel();
        branchModel.address = new Address();
        branchModel.billingAddress = new Address();
        branchModel.address.country = "USA";
        branchModel.billingAddress.country = "USA";
        this.currentItem = branchModel;
        this.currentItem.enabled = true;
        this.sameAsBranchInfo = false;
        this.isEdit = true;
        this.addNew = true;
    }

    public validateBranchName() {
        const branchNameExist = this.items.some(
            (item) =>
                item !== this.currentItem &&
                item.branchName.toLowerCase() === this.currentItem?.branchName.toLowerCase()
        );
        this.branchNameValidation.valid = !branchNameExist;
    }

    public get branchNameErrors() {
        this.validateBranchName();
        if (this.currentItem && !this.currentItem.branchName) {
            return "Branch name is required";
        }
        return this.branchNameValidation.valid ? "" : `Branch Name is already in use`;
    }

    public get phoneErrors() {
        if (this.currentItem && !this.currentItem.phone) {
            return "Phone is required";
        }
        return "";
    }

    public get billingPhoneErrors() {
        if (this.currentItem && !this.currentItem.billingPhone) {
            return "Phone is required";
        }
        return "";
    }

    public get emailErrors() {
        if (this.currentItem && !this.currentItem.email) {
            return "Email is required";
        }
        return "";
    }

    public get billingEmailErrors() {
        if (this.currentItem && !this.currentItem.billingEmail) {
            return "Billing Email is required";
        }
        return "";
    }

    public removePhoneFormatting(phoneNumber: string): string {
        return phoneNumber.replace(/[^0-9]/g, "");
    }
}
