






import { HelpCardViewModel } from "@/chipply/view-model/HelpCardViewModel";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import { AsyncInteractionViewModel } from "chipply-common";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { mask } from "vue-the-mask";

@Component({
    components: { CDecisionCard },
})
export default class CHelpCard extends Vue {
    @Prop()
    public viewModel!: HelpCardViewModel;
}
