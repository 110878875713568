import ArtworkPurchaseOrder from "./ArtworkPurchaseOrder";
import IArtworkPurchaseOrderArgs from "./IArtworkPurchaseOrderArgs";
import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import PurchasingFilters from "./PurchasingFilters";

export default class ArtworkPurchaseOrderArgs implements IArtworkPurchaseOrderArgs {
    public purchaseOrder: IVendorPurchaseOrder<IArtworkPurchasingLineItem> = new ArtworkPurchaseOrder();
    public filters = new PurchasingFilters();
    public userId = 0;
    public shouldGroupByProcess = false;
}
