















import Component from "vue-class-component";
import Vue from "vue";

@Component({})
export default class CChildPane extends Vue {}
