import { IProcessImageData } from "@/chipply/process/IProcessImageData";

export class ProcessLayoutCanvasViewModel {
    public gutters: { width: number; height: number } = {
        width: 100,
        height: 100,
    };
    public imageData: IProcessImageData | null = null;
    public imageNumber = 0;
}
