






































import Vue from "vue";
import Component from "vue-class-component";
import ITreeItem from "@/chipply/interface/i-tree-item";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    props: {
        ids: Array,
        treeItems: Array,
        defaultText: String,
        validate: Boolean,
        disabled: Boolean,
    },
    model: {
        prop: "ids",
        event: "change",
    },
})
export default class TreeSelector extends Vue {
    public name = "TreeSelector";
    public treeItems!: ITreeItem[];
    public selectedIds!: number[];
    public treeIds!: number[];
    public selectedItemsDisplay!: string;
    public defaultText!: string;
    public ids!: number[];
    public initialTreeViewInput = true;
    public validate!: boolean;

    @Prop({
        default: "400px",
    })
    public popupMaxHeight!: string;

    public $refs!: {
        treeView: Vue;
    };

    public data() {
        return {
            selectedItemsDisplay: undefined,
            selectAllChecked: false,
            selectedIds: this.ids,
            treeIds: [],
        };
    }

    public created() {
        const treeIds: number[] = [];
        treeIds.push(...this.ids);
        this.treeIds = treeIds;
        this.updateSelectedItemsDisplay();
    }

    @Watch("ids")
    public onIdsChanged() {
        this.initialTreeViewInput = true;
        this.selectedIds = this.ids;
        const treeIds: number[] = [];
        treeIds.push(...this.ids);
        this.treeIds = treeIds;
        this.updateSelectedItemsDisplay();
    }

    public treeViewInput(selectedIds: number[]) {
        this.selectedIds = selectedIds;
        this.updateSelectedItemsDisplay();
    }

    public subscribeToTreeView(open: boolean) {
        // if (open)
        //     this.$refs.treeView.$on("input", this.treeViewInput);
        // else
        //     this.$refs.treeView.$off("input", this.treeViewInput);
    }

    public beforeDestroy() {
        // this.$refs.treeView.$off("input", this.treeViewInput);
    }

    public updateSelectedItemsDisplay() {
        this.$emit("change", this.selectedIds);

        if (this.selectedIds.length === 0) {
            this.selectedItemsDisplay = this.defaultText || "";
            return;
        }

        let selectedCategoriesDisplay = "";
        for (const catId of this.selectedIds) {
            const catName = this.findCategoryName(this.treeItems, catId);
            if (catName) {
                if (selectedCategoriesDisplay.length > 0) {
                    selectedCategoriesDisplay += "; ";
                }
                selectedCategoriesDisplay += catName;
            }
        }

        this.selectedItemsDisplay = selectedCategoriesDisplay;
    }

    get display() {
        if (this.selectedItemsDisplay) {
            return this.selectedItemsDisplay;
        }
        return this.defaultText;
    }

    public isValid() {
        if (!this.validate) {
            return true;
        }
        return (this.selectedIds || []).length > 0;
    }

    private findCategoryName(categories: ITreeItem[], categoryId: number): string | null {
        for (const cat of categories) {
            if (cat.id === categoryId) {
                if (cat.children && cat.children.length > 0) {
                    return null;
                }
                return cat.name;
            }

            for (const subcat of cat.children) {
                if (subcat.id === categoryId) {
                    return subcat.name;
                }
            }
        }

        return null;
    }

    private checkAll(checked: boolean) {
        const allCats = [];

        if (checked) {
            for (const cat of this.treeItems) {
                allCats.push(cat.id);

                for (const subcat of cat.children) {
                    allCats.push(subcat.id);
                }
            }
        }

        this.treeIds = allCats;
        this.selectedIds = allCats;
    }
}
