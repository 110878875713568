import IColorAssignmentsModel from "@/chipply/interface/i-color-assignments-dto";
import { IEventArtworkVariation } from "@/chipply/interface/i-event-artwork-variation";
import { IProductColorAssignment } from "@/chipply/interface/i-product-color-assignment";
import IArtwork from "@/chipply/process/IArtwork";
import { ColorAssignmentsModel } from "@/chipply/store/color-assignment/ColorAssignmentsModel";
import { GroupColorAssignmentViewModel } from "@/chipply/store/color-assignment/GroupColorAssignmentViewModel";
import { ProductColorAssignmentViewModel } from "@/chipply/store/color-assignment/ProductColorAssignmentViewModel";
import { Serializer, ViewModel } from "chipply-common";

export class ColorAssignmentsViewModel extends ViewModel {
    public artworks: IArtwork[] = [];
    public assignmentsByColor: GroupColorAssignmentViewModel[] = [];
    public assignmentsByProduct: ProductColorAssignmentViewModel[] = [];
    public variations: IEventArtworkVariation[] = [];
    public isArtwork = false;
    public warningMessage = "";

    public created(model: ColorAssignmentsModel) {
        this.artworks = model.artworks;
        this.variations = model.variations;
        this.isArtwork = model.isArtwork;
        this.warningMessage = model.warningMessage;

        for (const assignment of model.assignmentsByProduct) {
            const vm = new ProductColorAssignmentViewModel(assignment);
            this.assignmentsByProduct.push(vm);
        }
        this.applyUniqueIds();

        for (const assignment of model.assignmentsByColor) {
            const vm = new GroupColorAssignmentViewModel(assignment);
            this.assignmentsByColor.push(vm);
        }
    }

    public toModel(): IColorAssignmentsModel {
        const model: IColorAssignmentsModel = {
            isArtwork: this.isArtwork,
            artworks: this.artworks,
            assignmentsByColor: [],
            assignmentsByProduct: [],
            variations: Serializer.copy(this.variations),
        };

        for (const assignment of this.assignmentsByColor) {
            model.assignmentsByColor.push(assignment.toModel());
        }

        for (const assignment of this.assignmentsByProduct) {
            model.assignmentsByProduct.push(assignment.toModel());
        }

        return model;
    }

    protected applyUniqueIds() {
        // Needed for uniqueness for item-key in vue data table for selection checkboxes
        for (const productAssignment of this.assignmentsByProduct) {
            productAssignment.id = this.assignmentsByProduct.indexOf(productAssignment);
        }
    }
}
