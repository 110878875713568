







































































































































































































































































































































































































































































































































import IBatchedCopyEventArgs from "@/chipply/event/IBatchedCopyEventArgs";
import chipplyIcons from "@/chipply/ImportIcons";
import { IGetStoreListArgs } from "@/chipply/interface/IGetStoreListArgs";
import ITextValue from "@/chipply/interface/ITextValueDisabled";
import StoreListSortBy from "@/chipply/interface/StoreListSortBy";
import CopyEventOptions from "@/components/events/StoreCreationOptions.vue";
import EventCopier from "@/components/events/EventCopier.vue";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import { Utils, WebHelper } from "chipply-common";

import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { IEcomOrderListArgs } from "@/chipply/interface/IEcomOrderListArgs";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import StoreListFilters from "@/chipply/interface/StoreListFilters";
import EcomOrderListFilters from "@/chipply/ecom-orders/EcomOrderListFilters";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import IEcomOrderResult from "@/chipply/ecom-orders/IEcomOrderResult";
import StoreGridMixin from "@/components/events/StoreGridMixin";
import TextHeading from "@/components/utility/TextHeading.vue";
import CreateShippingIntegrationOrdersArgs from "@/chipply/ecom-orders/CreateShippingIntegrationOrdersArgs";

interface ISpreadsheetFilters {
    vendorId: number | null;
    filter: string | null;
    categoryIds: number[];
    processId: number | undefined;
}

@Component({
    components: {
        LongRunningOperationDialog,
        TextHeading,
        EventCopier,
        CopyEventOptions,
    },
    props: {
        initialOrderStatus: String,
        initialEventId: Number,
        state: Object,
    },
})
export default class EcomOrdersGrid extends mixins(StoreGridMixin) {
    public $refs!: {
        datatable: Vue;
    };

    public chipplyIcons = chipplyIcons;
    public events!: object[];
    public filters!: EcomOrderListFilters;
    public initialEventId!: number | undefined;
    public initialOrderStatus!: string | undefined;
    public organizations!: ITextValue<number>;
    public rosterNameFilter!: string | undefined;
    public items!: IEcomOrderResult[];
    public isShippingIntegrationAvailable!: boolean;
    public loading!: boolean;
    public totalStores!: number;
    public pagination!: IVuetifyTableOptions;
    public showStoreOptionsDialog!: boolean;
    public showOrderFromDateMenu!: boolean;
    public showOrderToDateMenu!: boolean;
    public showCopyDialog!: boolean;
    public storeCopyArgs!: IBatchedCopyEventArgs;
    public deletionStoreName!: string | undefined;
    public deletionStoreId!: number | undefined;
    public showDeleteStoreDialog!: boolean;
    public tableHeight!: string | undefined;
    public selectedStoreStatus!: string;
    public state!: IPageReferrerSettings | undefined;
    public selected!: IEcomOrderResult[];
    public longRunningMessage: string | null = null;

    public hasCreated!: boolean;
    public showPrintSalesOrderWarning!: boolean;
    public showSendToShippingWarning!: boolean;
    private wasLastPageSelected = false;
    private orderIds: number[] = [];
    private wasSelectAllChecked = false;
    private isSelectingNextPage = false;

    public async created() {
        this.filters.eventId = this.initialEventId;
        this.filters.orderStatus = this.initialOrderStatus;
        if (this.state) {
            this.filters = this.state.filters as EcomOrderListFilters;
            this.pagination = this.state.pagination;
        }

        this.hasCreated = true;
        await this.refreshData();
        const tableChild = this.$refs.datatable.$el.firstElementChild;
        if (tableChild) {
            tableChild.addEventListener("scroll", this.checkNextPage);
        }
    }

    public printSalesOrders() {
        const url = this.getPrintUrl(this.selected);

        const incompleteStatuses = ["Voided", "Failed", "Abandoned"];
        let hasIncompleteOrder = false;
        for (const item of this.selected) {
            if (incompleteStatuses.indexOf(item.orderStatus) > -1) {
                hasIncompleteOrder = true;
                break;
            }
        }

        if (hasIncompleteOrder) {
            this.showPrintSalesOrderWarning = true;
        } else {
            this.printSalesOrdersConfirm();
        }
    }

    public data() {
        return {
            Utils,
            deletionStoreId: undefined,
            deletionStoreName: undefined,
            errorMessage: undefined,
            events: [],
            filters: this.getDefaultFilters(),
            hasCreated: false,
            isShippingIntegrationAvailable: false,
            items: [],
            loading: false,
            newStoreName: undefined,
            orderStatuses: [
                { text: "Paid", value: "paid" },
                { text: "Voided", value: "voided" },
                { text: "Failed", value: "failed" },
                { text: "Abandoned", value: "abandoned" },
                { text: "Refund Later", value: "refundlater" },
                { text: "Pay Later", value: "paylater" },
                { text: "Fraud", value: "fraud" },
            ],
            organizations: [],
            pagination: {
                itemsPerPage: 20,
                page: 1,
                sortBy: ["name"],
            } as IVuetifyTableOptions,
            rosterNameFilter: undefined,
            selected: [],
            selectedStoreStatus: "scheduled",
            shippingTypes: [
                { text: "Shipping", value: "Shipping" },
                {
                    text: "Organization Delivery",
                    value: "Organization Delivery",
                },
                { text: "In Store Pickup", value: "In Store Pickup" },
            ],
            showCopyDialog: false,
            showDeleteStoreDialog: false,
            showOrderFromDateMenu: false,
            showOrderToDateMenu: false,
            showPrintSalesOrderWarning: false,
            showSendToShippingWarning: false,
            showStoreOptionsDialog: false,
            storeCopyArgs: undefined,
            storeStatuses: [
                { text: "Open", value: "open" },
                { text: "Closed", value: "closed" },
                { text: "Scheduled", value: "scheduled" },
            ],
            tableHeight: "180px",
            totalStores: 0,
        };
    }

    public checkNextPage(args: any) {
        if (!args.target) {
            return;
        }
        if (this.isSelectingNextPage) {
            return;
        }
        if (
            Math.ceil(args.target.scrollTop) + args.target.clientHeight >= args.target.scrollHeight &&
            !this.wasLastPageSelected
        ) {
            this.selectNextPage();
        }
    }

    public async sendShippingInformation(): Promise<void> {
        const incompleteStatuses = ["Voided", "Failed", "Abandoned"];
        let hasIncompleteOrder = false;
        for (const item of this.selected) {
            if (incompleteStatuses.indexOf(item.orderStatus) > -1) {
                hasIncompleteOrder = true;
                break;
            }
        }
        if (hasIncompleteOrder) {
            this.showSendToShippingWarning = true;
        } else {
            await this.sendToShippingConfirm();
        }
    }

    protected async sendToShippingConfirm(): Promise<void> {
        this.showSendToShippingWarning = false;
        const selectedOrderIds = [];
        for (const item of this.selected) {
            selectedOrderIds.push(item.orderId);
        }
        const baseUrl = "/api/EcomOrder/SendShippingInformation";
        try {
            this.loading = true;
            const args = new CreateShippingIntegrationOrdersArgs();
            args.filters = this.filters;
            if (this.isSelectAllIntended()) {
                args.orderIds = [];
                args.shouldSelectAll = true;
            } else {
                args.orderIds = selectedOrderIds;
            }
            const resultsText = await WebHelper.postJsonData(baseUrl, args);
            const results = JSON.parse(resultsText);
            await this.refreshData();
        } catch {
            // TODO:
        } finally {
            this.loading = false;
        }
    }

    protected clearSelections() {
        this.selected.splice(0);
    }

    protected async printSalesOrdersConfirm() {
        const url = this.getPrintUrl(this.selected);
        if (url.length < 1900) {
            location.assign(this.getPrintUrl(this.selected));
        } else {
            const orderIds = this.getSelectedOrderIds(this.selected);
            this.longRunningMessage = "Retrieving Sales Orders...";
            const attachment = await WebHelper.postJsonDataBlob("/api/Reports/SaleOrder", { orderIds });
            WebHelper.downloadAsAttachment(attachment, "SalesOrder.pdf");
            this.longRunningMessage = null;
        }
    }

    protected getDefaultFilters(): EcomOrderListFilters {
        const filters = new EcomOrderListFilters();
        filters.eventId = this.initialEventId;
        filters.orderStatus = this.initialOrderStatus;
        return filters;
    }

    protected rowClicked(row: IEcomOrderResult, args: any) {
        if (this.$vuetify.breakpoint.mdAndUp) {
            return;
        }
        location.assign("/ng/edit-order.html?oid=" + row.orderId);
    }

    protected get computedHeaders() {
        if (this.$vuetify.breakpoint.smAndDown) {
            return [
                {
                    align: "left",
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order# / Sale#",
                    value: "orderNumber",
                    width: "60px",
                },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order Status",
                    value: "orderStatus",
                    width: "90px",
                },

                { sortable: false, text: "Name", value: "billingName" },
                {
                    sortable: false,
                    text: "Organization/Store",
                    value: "organization",
                    width: "120px",
                },
            ];
        } else {
            return [
                { sortable: false, text: "", width: "50px", value: "select" },
                {
                    align: "left",
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order# / Sale#",
                    value: "orderNumber",
                    width: "60px",
                },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order Status",
                    value: "orderStatus",
                    width: "90px",
                },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Store Status",
                    value: "storeStatus",
                    width: "90px",
                },
                { sortable: false, text: "Name", value: "billingName" },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Company Name",
                    value: "companyName",
                    width: "80px",
                },
                {
                    sortable: false,
                    text: "Email",
                    value: "billingEmail",
                    width: "80px",
                },
                {
                    sortable: false,
                    text: "Phone",
                    value: "billingPhone",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Organization/Store",
                    value: "organization",
                    width: "120px",
                },
                {
                    class: ["wrapHeader"],
                    sortable: false,
                    text: "Store Type",
                    value: "storeType",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Order Date",
                    value: "orderDate",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Est Ship Date",
                    value: "estimatedShipDate",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Items",
                    value: "items",
                    width: "50px",
                },
                {
                    sortable: false,
                    text: "Total",
                    value: "orderTotal",
                    width: "50px",
                },
                {
                    sortable: false,
                    text: "Exported To Shipping",
                    value: "shippingExportDate",
                    width: "100px",
                },
            ];
        }
    }
    protected getPageState() {
        return {
            description: "Order Manager",
            filters: this.filters,
            pagination: this.pagination,
            url: location.pathname + "?readstate=1",
        };
    }

    protected getPageStateUrl(pageUrl: string) {
        const serializedValue = JSON.stringify(this.getPageState());

        if (pageUrl.indexOf("?") === -1) {
            return pageUrl + "?state=" + encodeURIComponent(serializedValue);
        }
        return pageUrl + "&state=" + encodeURIComponent(serializedValue) + "&v=" + Math.floor(Math.random() * 100000);
    }

    @Watch("initialEventId")
    protected onInitialEventIdChanged(ov: any, newValue: number | undefined) {
        this.filters.eventId = newValue;
    }

    @Watch("initialOrderStatus")
    protected onInitialOrderStatusChanged(ov: any, newValue: string | undefined) {
        this.filters.orderStatus = newValue;
    }

    protected getSelectedOrderIds(selected: Array<{ orderId: number }>) {
        const orderIds = [];
        for (const item of selected) {
            orderIds.push(item.orderId);
        }
        return orderIds;
    }

    protected getPrintUrl(selected: Array<{ orderId: number }>) {
        if (this.isSelectAllIntended()) {
            const url = new URL("ng/sales-order.html", window.location.origin);
            url.searchParams.append("shouldselectall", "true");
            if (this.filters.billEmail) {
                url.searchParams.append("billemail", this.filters.billEmail);
            }
            if (this.filters.billFirstName) {
                url.searchParams.append("billfirstname", this.filters.billFirstName);
            }
            if (this.filters.billLastName) {
                url.searchParams.append("billlastname", this.filters.billLastName);
            }
            if (this.filters.billPhone) {
                url.searchParams.append("billphone", this.filters.billPhone);
            }
            if (this.filters.ordersFromDate) {
                url.searchParams.append("ordersfromdate", this.filters.ordersFromDate);
            }
            if (this.filters.ordersToDate) {
                url.searchParams.append("orderstodate", this.filters.ordersToDate);
            }
            if (this.filters.orderStatus) {
                url.searchParams.append("orderstatus", this.filters.orderStatus);
            }
            if (this.filters.organizationId) {
                url.searchParams.append("organizationid", this.filters.organizationId.toString());
            }
            if (this.filters.saleOrder) {
                url.searchParams.append("saleorder", this.filters.saleOrder);
            }
            if (this.filters.shippingType) {
                url.searchParams.append("shippingtype", this.filters.shippingType);
            }
            if (this.filters.eventId) {
                url.searchParams.append("eventid", this.filters.eventId.toString());
            }
            return url.toString();
        }
        const orderIds = this.getSelectedOrderIds(selected);
        const orderIdsQueryString = JSON.stringify(orderIds);
        return "/ng/sales-order.html?orderids=" + orderIdsQueryString;
    }

    @Watch("$vuetify.breakpoint.mdAndDown")
    protected resizeTable() {
        // This value may change based on the host.
        // I couldn't figure out a better way to make this work, open to improvement!
        // Without this the table will scroll past it's flex-basis and relative flex
        this.$nextTick(() => {
            let maxHeight = window.innerHeight;
            if (window.innerWidth > 960) {
                maxHeight -= 450;
            } else {
                maxHeight = maxHeight - 330 > 180 ? maxHeight - 330 : 180;
            }
            this.tableHeight = maxHeight + "px";
        });
    }

    protected async selectAll(args: { items: any[]; value: boolean }): Promise<void> {
        if (args.value === true) {
            this.wasSelectAllChecked = true;
        } else {
            this.wasSelectAllChecked = false;
        }
    }

    private displayDeleteEventDialog(eventId: number, eventName: string) {
        this.showDeleteStoreDialog = true;
        this.deletionStoreId = eventId;
        this.deletionStoreName = eventName;
    }

    private async deleteEvent(accepted: boolean) {
        this.showDeleteStoreDialog = false;
        const eventId = this.deletionStoreId;
        this.deletionStoreId = undefined;
        this.deletionStoreName = undefined;

        if (!accepted) {
            return;
        }

        this.loading = true;
        await WebHelper.delete("/api/Events/" + eventId);
        await this.refreshData();
        this.loading = false;
    }

    private buildArgs(): IEcomOrderListArgs {
        const args: IEcomOrderListArgs = {
            filters: this.filters,
            page: this.pagination.page,
            pageSize: this.pagination.itemsPerPage,
        };

        return args;
    }

    private clear() {
        this.filters = new EcomOrderListFilters();
    }

    private async refreshData() {
        this.clearSelections();
        this.pagination.page = 1;
        this.wasLastPageSelected = false;
        const unserializedData = await this.selectData();
        this.organizations = unserializedData.organizations;
        this.orderIds = [];
        this.items = unserializedData.orders;
        for (const order of this.items) {
            this.orderIds.push(order.orderId);
        }
        this.events = unserializedData.events;
        this.totalStores = unserializedData.totalCount;
    }

    private async selectData(): Promise<any> {
        if (!this.hasCreated) {
            return;
        }

        this.loading = true;
        try {
            const urlParams = new URLSearchParams(window.location.search);
            let pageUrl = "/ng/ecom-orders.html";
            if (urlParams.has("errorMessage")) {
                pageUrl += "?errorMessage=" + encodeURIComponent(urlParams.get("errorMessage") as string);
            }

            history.replaceState(this.getPageState(), "", this.getPageStateUrl(pageUrl));
            const data = await WebHelper.postJsonData("/api/EcomOrder/GetOrders", this.buildArgs());
            const unserializedData = JSON.parse(data);
            this.isShippingIntegrationAvailable = unserializedData.isShippingIntegrationAvailable;
            if (unserializedData.orders.length >= this.pagination.itemsPerPage) {
                this.pagination.page++;
            } else {
                this.wasLastPageSelected = true;
            }
            return unserializedData;
        } finally {
            this.loading = false;
        }
    }

    private async selectNextPage(): Promise<IEcomOrderResult[]> {
        this.isSelectingNextPage = true;
        try {
            const unserializedData = await this.selectData();
            const addedOrders: IEcomOrderResult[] = [];
            const shouldSelectNewItems = this.isSelectAllIntended();
            if (unserializedData.orders.length > 0) {
                for (const order of unserializedData.orders) {
                    if (this.orderIds.indexOf(order.orderId) < 0) {
                        this.items.push(order);
                        this.orderIds.push(order.orderId);
                        addedOrders.push(order);
                        if (shouldSelectNewItems) {
                            this.selected.push(order);
                        }
                    }
                }
                return addedOrders;
            }
            return [];
        } finally {
            this.isSelectingNextPage = false;
        }
    }

    private isSelectAllIntended() {
        return this.wasSelectAllChecked && this.areAllRecordsSelected();
    }

    private areAllRecordsSelected(): boolean {
        if (this.items.length !== this.selected.length) {
            return false;
        }
        return true;
    }
}
