import { EventBus } from "@/chipply/EventBus";
import { AddBoxViewModel } from "@/chipply/process/AddBoxViewModel";
import IArtwork from "@/chipply/process/IArtwork";
import { IProcessItemArtworkBoxPosition } from "@/chipply/process/IProcessItemArtworkBoxPosition";
import { IProcessArtworkBox } from "@/chipply/process/IProcessArtworkBox";
import { IProcessImageData } from "@/chipply/process/IProcessImageData";
import { SaveProcessLayoutArgs } from "@/chipply/process/ISaveProcessLayoutArgs";
import { ProcessLayoutCanvasViewModel } from "@/chipply/process/ProcessLayoutCanvasViewModel";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import { AsyncInteractionViewModel, WebHelper } from "chipply-common";
import { isEqual } from "lodash";

export abstract class ProcessLayoutPageViewModelBase extends PageViewModel {
    public addBoxViewModel: AddBoxViewModel | null = null;
    public unplacedBoxes: IProcessArtworkBox[] = [];
    public currentTab = 0;
    public gutters = { width: 100, height: 100 };
    public boxCount = 4;
    public imageLayoutViewModels: ProcessLayoutCanvasViewModel[] = [];

    public initialize() {
        EventBus.$on("process-boxes-updated", this.updateBoxLocations);
    }

    public dispose() {
        EventBus.$off("process-boxes-updated", this.updateBoxLocations);
    }

    public deleteItem(processItem: IProcessArtworkBox, imageNumber: number, i: number) {
        const removedItem = this.imageLayoutViewModels[imageNumber - 1].imageData!.boxes.splice(i, 1);
        this.unplacedBoxes.push(removedItem[0]);
        const args = { imageNumber, processItemId: processItem.processItemId };
        this.isModified = true;
        EventBus.$emit("process-box-delete", args);
    }

    public async add(imageNumber: number) {
        const addBoxViewModel = new AddBoxViewModel();
        addBoxViewModel.positions = this.getAvailablePositions();
        this.addBoxViewModel = addBoxViewModel;
        const result = await this.addBoxViewModel.interact();
        this.addBoxViewModel = null;
        if (result === "cancel") {
            return;
        }

        const selectedProcessItemId = addBoxViewModel.selectedPosition;
        const itemIndex = this.unplacedBoxes.findIndex((pi) => pi.processItemId === selectedProcessItemId);
        const item = this.unplacedBoxes.splice(itemIndex, 1)[0];
        const itemCopy = JSON.parse(JSON.stringify(item));
        this.imageLayoutViewModels[imageNumber - 1].imageData!.boxes.push(itemCopy);
        this.isModified = true;
        EventBus.$emit("process-box-added", {
            imageNumber,
            processItem: itemCopy,
        });
    }

    protected updateBoxLocations = (args: { imageNumber: number; newPositions: IProcessItemArtworkBoxPosition[] }) => {
        const boxList = this.imageLayoutViewModels[args.imageNumber - 1].imageData!.boxes;
        if (!boxList) {
            return;
        }

        const currentPositions = args.newPositions;
        for (const box of boxList) {
            const position = currentPositions.find((p) => p.id === box.processItemId);
            this.refreshIsModified(box, position!);
            box.boxRotation = position!.boxRotation;
            box.boxLayout = position!.boxLayout;
        }
    };

    protected refreshIsModified(box: IProcessArtworkBox, position: IProcessItemArtworkBoxPosition) {
        if (this.isModified) {
            return;
        }
        this.isModified = this.isModified || !isEqual(box.boxLayout, position.boxLayout);
        this.isModified = this.isModified || box.boxRotation !== position.boxRotation;
    }

    protected getAvailablePositions(): IArtwork[] {
        const positions: IArtwork[] = [];
        for (const item of this.unplacedBoxes) {
            positions.push({
                artName: item.artworkName + " - " + item.artworkLocationName,
                artworkId: item.processItemId,
                imageUrl: item.artworkPath,
            });
        }
        return positions;
    }
}
