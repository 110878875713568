import { ActionContext } from "vuex";
import { IRootState } from "@/store/RootStore";
import { getStoreAccessors } from "vuex-typescript";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import ISessionState from "@/store/ISessionState";

type SessionContext = ActionContext<ISessionState, IRootState>;

const storageKey = "session";

export const sessionStore = {
    namespaced: true,

    state: {
        storeListReferralSettings: undefined as IPageReferrerSettings | undefined,
    },

    getters: {
        getStoreListReferralSettings(state: ISessionState) {
            return state.storeListReferralSettings;
        },
    },

    mutations: {
        setStoreListReferralSettings(state: ISessionState, storeListReferralSettings: IPageReferrerSettings) {
            state.storeListReferralSettings = storeListReferralSettings;
            sessionStorage.setItem(storageKey, JSON.stringify(storeListReferralSettings));
        },
    },

    actions: {
        initializeSession(context: SessionContext) {
            const storageString = sessionStorage.getItem(storageKey);
            if (!storageString) {
                return;
            }

            const storeData = JSON.parse(storageString);
            commitStoreListReferralSettings(context, storeData);
        },
    },
};

const { commit, read, dispatch } = getStoreAccessors<ISessionState, IRootState>("sessionState");

const mutations = sessionStore.mutations;

export const commitStoreListReferralSettings = commit(mutations.setStoreListReferralSettings);

const getters = sessionStore.getters;

export const getStoreListReferralSettings = read(getters.getStoreListReferralSettings);

export const initialize = dispatch(sessionStore.actions.initializeSession);
