import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import { DateTime } from "luxon";
import EcertMergeTemplateViewModel from "@/chipply/merge/EcertMergeTemplateViewModel";
import IStoreEcertMergeTemplate from "@/chipply/merge/IStoreEcertMergeTemplate";
import IAutomatedMergeTemplate from "@/chipply/merge/IAutomatedMergeTemplate";
import { WebHelper } from "chipply-common";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";
import IMergeTemplateDto from "@/chipply/merge/IMergeTemplateDto";
import IStoreCustomerMergeTemplate from "@/chipply/merge/IStoreCustomerMergeTemplate";
import IMergeTemplateViewModel from "@/chipply/merge/IMergeTemplateViewModel";
import { IMergeMessageViewModel } from "@/chipply/merge/MergeMessageViewModel";

export default class StoreEcertMergeTemplateViewModel
    extends EcertMergeTemplateViewModel
    implements IMergeMessageViewModel
{
    public $typeHint = "StoreEcertMergeTemplate";
    public ecertIds: number[] = [];
    public lastSendDate!: string;
    public resend!: boolean;
    public templates: IAutomatedMergeTemplate[] = [];
    public ecertRules: any[] = [];
    public constructor() {
        super();
        this.ecertRules.push(this.requireOneRecipient);
    }

    public created(dto: IStoreEcertMergeTemplate) {
        this.setTemplate(dto);
    }

    public setTemplate(data?: IStoreEcertMergeTemplate) {
        super.setTemplate(data);

        this.resend = data ? data.resend : false;
        if (data) {
            if (data.trigger === EmailTemplateTriggerTypeEnum.StaticDate) {
                this.staticDate = DateTime.fromISO(data.staticDate!).toFormat("yyyy-MM-dd");
                this.staticTime = DateTime.fromISO(data.staticDate!).toLocaleString(DateTime.TIME_24_SIMPLE);
            }
            if (data.lastSendDate) {
                this.lastSendDate = DateTime.fromISO(data.lastSendDate).toLocaleString(DateTime.DATETIME_MED);
            }
        }

        this.ecertIds = data ? data.ecertIds! : [];
        this.trigger = data ? data.trigger : EmailTemplateTriggerTypeEnum.StaticDate;
    }

    public async loadEditData(): Promise<void> {
        this.templates = await WebHelper.getJsonData(`/api/Event/Email/Templates/${MergeTemplateType.ECertificate}`);
    }

    public resetTemplate(template: IMergeTemplateDto) {
        const castedTemplate = template as IStoreEcertMergeTemplate;
        castedTemplate.trigger = EmailTemplateTriggerTypeEnum.StaticDate;
        castedTemplate.staticDate = DateTime.local().toISO();
        castedTemplate.id = 0;
        castedTemplate.lastSendDate = "";
        castedTemplate.resend = false;
        castedTemplate.ecertIds = this.ecertIds;
        this.setTemplate(castedTemplate);
    }

    public toModel(): IStoreEcertMergeTemplate {
        const baseModel = super.toModel();
        const model = {
            ...baseModel,
            $typeHint: this.$typeHint,
            lastSendDate: this.lastSendDate,
            ecertIds: this.ecertIds,
            resend: this.resend,
        } as IStoreEcertMergeTemplate;
        this.setDate(model);
        return model;
    }

    public clone() {
        const clone = new StoreEcertMergeTemplateViewModel();
        clone.setTemplate(this.toModel());
        return clone;
    }

    protected requireOneRecipient = (value: any) => {
        const moreThanOneEmailRecipient = this.isMessageEmail && this.toRecipients.length > 0;
        const moreThanOneSmsRecipient = this.isMessageSms && this.sms!.recipients.length > 0;
        const moreThanOneEcert = this.ecertIds!.length > 0;
        return moreThanOneEmailRecipient || moreThanOneSmsRecipient || moreThanOneEcert
            ? true
            : "You must select at least one email recipient";
    };
}
