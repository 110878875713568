import { Address, IAddress } from "chipply-common";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import { IPurchaseOrderLineItem } from "./IPurchaseOrderLineItem";
import IPurchaseOrderTotals from "./IPurchaseOrderTotals";
import PurchaseOrderTotals from "./PurchaseOrderTotals";
import { PurchasingType } from "./PurchasingConstants";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import VendorPurchaseOrderHeader from "./VendorPurchaseOrderHeader";

export default class VendorPurchaseOrder
    extends VendorPurchaseOrderHeader
    implements IVendorPurchaseOrder<IPurchaseOrderLineItem>
{
    public lines: IPurchaseOrderLineItem[] = [];
}
