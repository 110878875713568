







import chipplyIcons from "@/chipply/ImportIcons";
import TextHeading from "@/components/utility/TextHeading.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    components: {
        TextHeading,
    },
    props: {
        title: String,
    },
})
export default class ViewHeader extends Vue {
    public name = "ViewHeader";
}
