






import { CanvasHelper } from "@/chipply/canvas/CanvasHelper";
import { EventBus } from "@/chipply/EventBus";
import { IProcessItemArtworkBoxPosition } from "@/chipply/process/IProcessItemArtworkBoxPosition";
import { IProcessArtworkBox } from "@/chipply/process/IProcessArtworkBox";
import { ProcessLayoutCanvasViewModel } from "@/chipply/process/ProcessLayoutCanvasViewModel";
import { fabric } from "fabric";
import { Canvas } from "fabric/fabric-impl";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class ProcessLayoutCanvas extends Vue {
    @Prop({
        default: null,
        type: Object,
    })
    public viewModel!: ProcessLayoutCanvasViewModel | null;

    public $refs!: { boxContainer: HTMLCanvasElement };

    public boxes: { [processItemId: number]: fabric.Group } = {};
    public canvas!: fabric.Canvas;

    public async mounted() {
        EventBus.$on("process-box-delete", (args: { imageNumber: number; processItemId: number }) => {
            debugger;
            if (this.viewModel!.imageNumber !== args.imageNumber) {
                return;
            }
            const box = this.boxes[args.processItemId];
            this.canvas.remove(box);
        });

        EventBus.$on("process-box-added", (args: { imageNumber: number; processItem: IProcessArtworkBox }) => {
            if (this.viewModel!.imageNumber !== args.imageNumber) {
                return;
            }
            this.addBox(this.canvas, args.processItem);
        });

        await this.initialize();
    }

    @Watch("viewModel")
    public async initialize() {
        if (!this.viewModel) {
            return;
        }
        this.canvas = await this.initializeCanvas(
            this.$refs.boxContainer,
            this.viewModel.imageData!.canvasImageUrl!,
            this.viewModel.imageData!.canvasBackgroundColor
        );

        for (const processItem of this.viewModel!.imageData!.boxes) {
            await this.addBox(this.canvas, processItem);
        }
    }

    protected initializeCanvas(
        el: HTMLCanvasElement,
        canvasImage?: string,
        canvasBackgroundColor?: string
    ): Promise<fabric.Canvas> {
        let resolver: ((value: any) => void) | null = null;
        const promise = new Promise<fabric.Canvas>((resolve) => {
            resolver = resolve;
        });

        let canvas: fabric.Canvas | null = this.canvas;
        if (!canvas) {
            canvas = new fabric.Canvas(el);
            canvas.selection = false;
            canvas.setDimensions({ width: 800, height: 800 });
            this.postCanvasChanges(canvas, this.viewModel!.imageNumber);
            CanvasHelper.preventOutsideCanvasMove(canvas);
            CanvasHelper.capCanvasObjectSize(canvas, 600);
        } else {
            debugger;
            canvas.clear();

            this.boxes = [];
        }

        // create a rectangle object
        const rect = new fabric.Rect({
            left: 99,
            top: 99,
            stroke: "black",
            fill: canvasBackgroundColor ?? "white",
            width: 601,
            height: 601,
            selectable: false,
            hoverCursor: "default",
        });

        if (canvasImage) {
            fabric.Image.fromURL(canvasImage, (oImg: fabric.Image) => {
                oImg.selectable = false;

                const shouldScaleDown = oImg.width! > 600 || oImg.height! > 600;
                const shouldScaleUp = oImg.width! < 600 && oImg.height! < 600;
                if (shouldScaleUp || shouldScaleDown) {
                    if (oImg.height! > oImg.width!) {
                        oImg.scaleToHeight(600);
                    } else {
                        oImg.scaleToWidth(600);
                    }
                }

                oImg.originY = "center";
                oImg.originX = "center";
                oImg.setPositionByOrigin(new fabric.Point(400, 400), "center", "center");

                canvas!.add(oImg);
                resolver!(canvas);
            });
        } else {
            resolver!(canvas);
        }
        // "add" rectangle onto canvas
        canvas.add(rect);
        return promise;
    }

    protected async addBox(canvas: fabric.Canvas, processItem: IProcessArtworkBox) {
        const box = await CanvasHelper.getNewBoundingBox(canvas, {
            boxLayout: processItem.boxLayout,
            artworkBox: processItem.artworkBox,
            artworkPath: processItem.artworkPath,
            boxRotation: processItem.boxRotation,
            gutters: this.viewModel!.gutters,
        });

        canvas.add(box);
        this.boxes[processItem.processItemId] = box;
    }

    protected getArtworkPositions() {
        const result: {
            imageNumber: number;
            newPositions: IProcessItemArtworkBoxPosition[];
        } = { imageNumber: this.viewModel!.imageNumber, newPositions: [] };

        for (const id of Object.keys(this.boxes)) {
            if (!this.boxes.hasOwnProperty(id)) {
                continue;
            }

            const box = this.boxes[Number(id)];
            const centerPoint = box.getCenterPoint();

            const boxPosition: IProcessItemArtworkBoxPosition = {
                id: Number(id),
                boxLayout: {
                    x: Math.round(centerPoint.x - this.viewModel!.gutters.width - box.getScaledWidth()! / 2),
                    y: Math.round(centerPoint.y - this.viewModel!.gutters.height - box.getScaledHeight()! / 2),
                    width: Math.round(box.getScaledWidth()),
                    height: Math.round(box.getScaledHeight()),
                    scale: 100,
                },
                boxRotation: Math.round(box.angle!),
            };
            result.newPositions.push(boxPosition);
        }
        return result;
    }

    private postCanvasChanges(canvas: Canvas, imageNumber: number) {
        canvas.on("mouse:up", (e) => {
            const obj = e.target as any;

            if (!obj) {
                return;
            }

            EventBus.$emit("process-boxes-updated", this.getArtworkPositions());
        });
    }
}
