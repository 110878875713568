export class Utils {
    public static ServerErrorMessage =
        "An unexpected error occurred. Please try again or contact Chipply for assistance.";

    static stripPhoneFormatting(formattedPhone: string) {
        if (!formattedPhone) {
            return "";
        }
        const areaCode = formattedPhone.substr(1, 3);
        const phonePart1 = formattedPhone.substr(6, 3);
        const phonePart2 = formattedPhone.substr(10, 4);
        return `${areaCode}${phonePart1}${phonePart2}`;
    }

    static formatPhone(value: string | number) {
        if (!value) {
            return "";
        }
        const stringValue = value.toString();
        const areaCode = stringValue.substr(0, 3);
        const phonePart1 = stringValue.substr(3, 3);
        const phonePart2 = stringValue.substr(6, 4);
        return `(${areaCode}) ${phonePart1}-${phonePart2}`;
    }

    static getNumericString(value: string) {
        if (!value) {
            return 0;
        }

        let numericString = "";
        for (let i = 0; i < value.length; i++) {
            const c = value[i];
            if (Number.isInteger(Number.parseInt(c))) {
                numericString += c;
            }
        }

        return numericString;
    }

    static getNumericLength(value: string) {
        if (!value) {
            return 0;
        }

        let numericLength = 0;
        for (let i = 0; i < value.length; i++) {
            const c = value[i];
            if (Number.isInteger(Number.parseInt(c))) {
                numericLength++;
            }
        }

        return numericLength;
    }

    public static hasValue(val: string) {
        if (!val) {
            return false;
        }
        return val.trim().length > 0;
    }
    public static isNullOrUndefined(val: any) {
        return val === null || typeof val === "undefined";
    }

    public static isNumericInput(key: string): boolean {
        if (!isNaN(Number(key))) {
            return true;
        }
        if (key === "." || key === "-" || this.isUtilityKey(key)) {
            return true;
        }
        return false;
    }

    public static isUtilityKey(key: string) {
        return key === "Backspace" || key === "Delete" || key === "Tab" || key === "ArrowLeft" || key === "ArrowRight";
    }

    /***
     * Returns a number from a string
     * @param value
     */
    public static getNumberFromString(value: string): number {
        if (!value) {
            return 0;
        }
        const cleanedValue = value.replace(",", "");
        const returnNumber = parseFloat(cleanedValue);
        if (isNaN(returnNumber)) {
            return 0;
        }
        return returnNumber;
    }

    /**
     * Returns true if input was prevented, I don't remember why though (legacy code?)
     * @param $event
     * @param currentValue
     * @param beforeDecimal
     * @param afterDecimal
     */
    public static preventNonNumericInput(
        $event: KeyboardEvent,
        currentValue?: number | string | undefined,
        beforeDecimal?: number | undefined,
        afterDecimal?: number | undefined
    ): boolean {
        if (!Utils.isNumericInput($event.key)) {
            $event.preventDefault();
            return true;
        }

        if (this.isUtilityKey($event.key)) {
            return false;
        }

        const input = $event.currentTarget as HTMLInputElement;
        const value = (currentValue || input.value || "").toString();
        const decimalIndex = value.indexOf(".");

        const checkBeforeDecimal = decimalIndex === -1 && beforeDecimal;
        const checkAfterDecimal = decimalIndex !== -1 && afterDecimal;
        if ($event.key === ".") {
            // Handles the case of replacing a highlighted value
            if (input && (input.selectionEnd || 0) - (input.selectionStart || 0) === value.length) {
                return false;
            }
            if (decimalIndex === -1) {
                return false;
            }

            $event.preventDefault();
            return true;
        } else if (checkBeforeDecimal) {
            if (value.length === beforeDecimal) {
                $event.preventDefault();
                return true;
            }
        } else if (checkAfterDecimal) {
            if (value.length - (decimalIndex + 1) === afterDecimal) {
                $event.preventDefault();
                return true;
            }
        }

        return false;
    }

    public static toCurrency(value: number): string {
        const formatter = new Intl.NumberFormat("en-US", {
            currency: "USD",
            style: "currency",
        });

        return formatter.format(value);
    }

    /**
     * Returns a currency value (i.e. "44.00") without the preceding or suffixing currency symbol
     * @param value Value to convert to currency formatted string
     * @param forcePositiveValue If number would be negative, returns 0
     * @param includeCurrencySymbol Include currency symbol ($) in returned value
     */
    public static getCurrencyValue(
        value: number | string,
        forcePositiveValue?: boolean,
        includeCurrencySymbol?: boolean
    ) {
        let valueAsNumber = this.parseNumber(value);
        if (isNaN(valueAsNumber) || !isFinite(valueAsNumber)) {
            valueAsNumber = 0;
        }
        if (valueAsNumber < 0 && forcePositiveValue) {
            valueAsNumber = 0;
        }

        const currencyAmount = valueAsNumber.toLocaleString("en-US", { style: "currency", currency: "USD" });
        if (includeCurrencySymbol) {
            return currencyAmount;
        } else {
            return currencyAmount.replace("$", "");
        }
    }
    public static parseNumber(value: number | string) {
        if (typeof value === "string") {
            value = value.replace(",", "");
        }
        return Number(value);
    }
    public static setCurrencyValue(
        value: number | string,
        propertyObject: any,
        propertyName: string,
        forcePositiveValue?: boolean
    ) {
        const currencyValue = this.getCurrencyValue(value, forcePositiveValue);
        propertyObject[propertyName] = currencyValue;
    }

    public static getPreviewPath(previewRoot: string, eventId: number | string) {
        return previewRoot + "/store.aspx?eid=" + eventId;
    }
}
