import {
    Address,
    ICountryDto,
    IStateDto,
    ITextValue,
    SimpleAsyncInteractionViewModel,
    TextUtils,
    WebHelper,
} from "chipply-common";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import SalesOrderInfo from "../event/SalesOrderInfo";
import IPurchaseOrderTotals from "./IPurchaseOrderTotals";
import PurchaseOrderTotals from "./PurchaseOrderTotals";
import PurchasingConstants, { PurchasingType } from "./PurchasingConstants";
import PurchasingFilters from "./PurchasingFilters";
import IPurchasingParentViewModel from "./IPurchasingParentViewModel";
import ListDealerBranchResults from "./ListDealerBranchResults";
import { ValidatePurchaseOrderArgs } from "./ValidatePurchaseOrderArgs";
import VendorPurchaseOrderHeader from "./VendorPurchaseOrderHeader";

export default abstract class AbstractPurchaseOrderViewModel {
    public attention = "";
    public branches: ITextValue<number>[] = [];
    public canCheckAvailability = false;
    public canExportPurchaseOrder = false;
    public canOrderDigitally = false;
    protected canGroupByFulfillmentStatus = true;
    public countries: ICountryDto[] = [];
    public currentStep = 1;
    public customerNumber = "";
    public dealerId = 0;
    public dealerBranchId: number | null = null;
    public dialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public dismissDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public eventId = 0;
    public filters: PurchasingFilters = new PurchasingFilters();
    public hasCheckedAvailability = false;
    public hasInitialized = false;
    public isFormValid = false;
    public isGroupingByProcess = false;
    public isStoreView = false;
    public isPrintEmailDialogVisible = false;
    public isPurchaseOrderDialogVisible = false;
    protected isPurchaseOrderInfoRequiredForPresubmit = false;
    public isSubmitDialogVisible = false;
    public lastPurchaseOrderNumber = "";
    public lastPurchaseOrderId = 0;
    public lastPurchaseOrderType: PurchasingType | null = null;
    public panels: number[] = [1];
    public poNumberValidation = {
        valid: true,
        loading: false,
    };
    public processes: Array<{ text: string; value: number }> = [];
    public purchaseOrderDialogTitle = "";
    public purchaseOrderId: number | null = null;
    public purchaseOrderNumber = "";
    public purchasingViewModel!: IPurchasingParentViewModel;
    public salesOrderInfo: SalesOrderInfo | null = null;
    public salesReps: Array<ITextValue<number>> = [];
    public selectedPurchaseOrderType: PurchasingType | null = null;

    public shouldShipToBranch = true;
    public shipEmail = "";
    public shipMethodHint = "";
    public shipTo: Address = new Address();
    public sortBy: string[] = [];
    public sortDesc: boolean[] = [];
    public states: IStateDto[] = [];
    public stores: Array<{ text: string; value: number }> = [];
    public storeName = "";
    public totals: IPurchaseOrderTotals = new PurchaseOrderTotals();
    public type = "";
    protected vendorErrorMessage =
        "An unexpected error has ocurred while communicating with the vendor, please try again later.";
    public vendorName = "";
    public vendors: Array<{ text: string; value: number }> = [];
    public vendorSettings: IVendorPurchaseOrderSettings | null = null;
    protected fulfillmentHeader = {
        text: "Is Fulfilled",
        value: "isFulfilled",
        sortable: false,
    };

    public back() {
        this.purchasingViewModel.back();
    }

    public abstract buildPurchaseOrder(): Promise<void>;

    public async checkPoNumber() {
        if (!this.purchaseOrderNumber) {
            return;
        }
        this.poNumberValidation.loading = true;
        const validateArgs = new ValidatePurchaseOrderArgs();
        validateArgs.purchaseOrderNumber = this.purchaseOrderNumber;
        const resultsText = await WebHelper.postJsonData("/api/Purchasing/validateponumber", validateArgs);
        const deserializedResults = JSON.parse(resultsText);
        this.poNumberValidation.valid = deserializedResults.isValid;
        this.poNumberValidation.loading = false;
    }

    public computedShippingStates() {
        if (this.shipTo.country) {
            return this.states.filter((x) => x.country === this.shipTo.country);
        }
        return this.states;
    }

    public async getBranches(): Promise<void> {
        const baseUrl = "/api/purchasing/listdealerbranches";
        try {
            this.selectedPurchaseOrderType = PurchasingConstants.autoPurchaseType;
            this.purchasingViewModel.isLoading = true;
            const resultsText = await WebHelper.postJsonData(baseUrl);
            const results = JSON.parse(resultsText) as ListDealerBranchResults;
            if (results) {
                this.branches = results.branches;
                if (results.primaryBranchId) {
                    this.dealerBranchId = results.primaryBranchId;
                }
            }
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public abstract getTotals(): void;

    public async initialize(): Promise<void> {
        if (!this.hasInitialized) {
            this.hasInitialized = true;
            await this.getBranches();
            await this.buildPurchaseOrder();
        }
    }

    public get poNumberErrors() {
        if (!this.purchaseOrderNumber) {
            return "PO Number is required";
        }
        if (
            this.vendorName &&
            this.vendorName.toLowerCase() === "sanmar" &&
            !TextUtils.isAlphaNumeric(this.purchaseOrderNumber)
        ) {
            return "Only letters or numbers are allowed";
        }
        return this.poNumberValidation.valid ? "" : `Number is already in use, please choose a different number`;
    }

    protected abstract getListUrl(): string;
    protected abstract handleLargePurchaseOrder(): Promise<void>;

    protected applyPurchaseOrderHeader(purchaseOrder: VendorPurchaseOrderHeader) {
        this.vendorName = purchaseOrder.vendorName;
        this.purchaseOrderNumber = purchaseOrder.purchaseOrderNumber;
        this.attention = purchaseOrder.attention;
        this.shipTo = purchaseOrder.shipTo;
        this.shipEmail = purchaseOrder.shipEmail;
        this.isPurchaseOrderInfoRequiredForPresubmit = purchaseOrder.isPurchaseOrderInfoRequiredForPresubmit;
        this.canCheckAvailability = purchaseOrder.canCheckAvailability;
        this.canOrderDigitally = purchaseOrder.canOrderDigitally;
        this.canExportPurchaseOrder = purchaseOrder.canExportPurchaseOrder;
    }
}
