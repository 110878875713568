import { Utils } from "chipply-common";
import { SimpleAsyncInteractionViewModel } from "chipply-common";
import ColorAssignmentsPageViewModel from "../store/color-assignment/ColorAssignmentPageViewModel";
import ISubstituteProductDetails from "./ISubstituteProductDetails";
import PurchasingViewModel from "./PurchasingViewModel";

export default class PurchasingColorAssignmentPageViewModel extends ColorAssignmentsPageViewModel {
    public eventProductColorId = 0;

    public newlyAddedProducts: ISubstituteProductDetails[] = [];

    public purchasingViewModel: PurchasingViewModel;

    public currentItem: ISubstituteProductDetails | null = null;

    public current = 0;
    public total = 1;

    public dialogViewModel: SimpleAsyncInteractionViewModel | null = null;

    public constructor(purchasingViewModel: PurchasingViewModel) {
        super();
        this.purchasingViewModel = purchasingViewModel;
    }

    public async next(): Promise<any> {
        try {
            if (this.currentItem) {
                await this.save();
                if (this.colorAssignments!.warningMessage) {
                    const vm = new SimpleAsyncInteractionViewModel();
                    vm.headerText = "Color Assignments";
                    vm.text = `${this.colorAssignments!.warningMessage}. Would you like to continue anyway?`;
                    this.dialogViewModel = vm;
                    const dialogResults = await this.dialogViewModel.interact();
                    this.dialogViewModel = null;
                    if (dialogResults !== "continue") {
                        return;
                    }
                }
            }
            if (this.newlyAddedProducts.length === 0) {
                this.close();
                return;
            }
            this.currentItem = this.newlyAddedProducts.pop() as ISubstituteProductDetails;
            ++this.current;
            this.purchasingViewModel.title = `Purchasing - assign colors for store '${this.currentItem?.storeName}'`;
            await this.getColorAssignments();
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        }
    }

    public close() {
        if (this.purchasingViewModel.purchaseOrderViewModel) {
            this.purchasingViewModel.isColorAssignmentVisible = false;
            this.purchasingViewModel.purchaseOrderViewModel.colorAssignmentViewModel = null;
            this.purchasingViewModel.title = "Purchasing";
        }
    }

    protected createGetArguments(): any {
        if (this.currentItem) {
            const results = {
                eventArtworkId: this.eventArtworkId,
                eventId: this.currentItem.eventId,
                filterByVariationIds: this.filterByVariationIds,
                eventProductColorId: this.currentItem.eventProductColorId,
            };
            return results;
        }
        return null;
    }

    protected createSaveArguments(): any {
        if (this.currentItem) {
            const results = {
                model: this.colorAssignments.toModel(),
                eventArtworkId: this.eventArtworkId,
                eventId: this.currentItem.eventId,
                filterByVariationIds: this.filterByVariationIds,
                eventProductColorId: this.currentItem.eventProductColorId,
            };
            return results;
        }
        return null;
    }

    protected setLoadingStatus(statusMessage: string): void {
        this.purchasingViewModel.statusMessage = statusMessage;
        this.purchasingViewModel.isLoading = true;
    }

    protected unsetLoadingStatus() {
        this.purchasingViewModel.isLoading = false;
        this.loading = false;
    }

    protected setErrorMessage(errorMessage: string): void {
        this.purchasingViewModel.errorMessage = errorMessage;
    }
}
