














import { DateTime } from "luxon";
import Vue from "vue";
import Component from "vue-class-component";
import { mask } from "vue-the-mask";
import { Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import Validation from "@/validation";
import { Utils } from "chipply-common";

@Component({
    directives: { mask },
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CPhone extends Vue {
    public Validation = Validation;
    public chipplyIcons = chipplyIcons;
    public Utils = Utils;
    @Prop()
    public value!: string;
    public inputValue: string | null = null;

    @Prop({
        default: false,
        type: Boolean,
    })
    public required!: boolean;

    @Prop({
        default: "Phone Number",
        type: String,
    })
    public label!: string;

    protected turnOffEmit = true;

    public requireLengthIfValued() {
        if (!this.inputValue) {
            return true;
        }

        return Validation.requireNumericLengthOf(10)(this.inputValue);
    }

    public mounted() {
        this.onValueChanged();
    }

    @Watch("value")
    public onValueChanged() {
        this.inputValue = this.value;
    }

    @Watch("inputValue")
    public onInputValueChanged() {
        if (this.value === this.inputValue) {
            return;
        }
        this.$emit("change", this.inputValue);
    }
}
