














import CCard from "@/components/ui/CCard.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { mask } from "vue-the-mask";
import { AsyncInteractionWithDataViewModel } from "chipply-common";

@Component({
    components: { CCard },
    directives: { mask },
    props: {
        show: Boolean,
        viewModel: Object,
        title: String,
        label: String,
    },
})
export default class CBooleanSetterCard extends Vue {
    public viewModel!: AsyncInteractionWithDataViewModel<boolean>;

    public cancel() {
        this.viewModel.done("cancel");
    }

    public accept() {
        this.viewModel.done("continue");
    }
}
