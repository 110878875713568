
















import { AddBoxViewModel } from "@/chipply/process/AddBoxViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import CArtworkSelect from "@/components/process/CArtworkSelect.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import Validation from "@/validation";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
        CArtworkSelect,
        CDecisionCard,
    },
})
export default class ProcessLayoutAddArtworkCard extends mixins(FormValidationMixin) {
    public Validation = Validation;

    @Prop({
        type: Object,
    })
    public vm!: AddBoxViewModel | null;
}
