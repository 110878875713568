import { AddFieldViewModel } from "@/chipply/fields/AddFieldViewModel";
import { FieldListItemEditorViewModel } from "@/chipply/fields/FieldListItemEditorViewModel";
import {
    AsyncInteractionViewModel,
    BooleanFieldModel,
    BooleanFieldViewModel,
    FieldItemModel,
    FieldModel,
    FieldType,
    FieldViewModel,
    ListFieldModel,
    ListFieldViewModel,
    TextFieldModel,
    TextFieldViewModel,
    typeDependencies,
    ViewModelFactory,
} from "chipply-common";

@typeDependencies({
    types: {
        BooleanFieldModel,
        ListFieldModel,
        TextFieldModel,
    },
})
export class FieldCollectionViewModel extends AsyncInteractionViewModel {
    public isValid = false;
    public addFieldViewModel: AddFieldViewModel | null = null;
    public itemEditorViewModel: FieldListItemEditorViewModel | null = null;

    public fieldHeaders = [
        { text: "", value: "handle" },
        { text: "Name", value: "name" },
        { text: "Required", value: "required" },
        { text: "Enabled", value: "enabled" },
        { text: "Type", value: "type" },
        { text: "Items", value: "options" },
        { text: "", value: "actions" },
    ];

    public fields: FieldViewModel[] = [];

    public async editItems(field: ListFieldViewModel) {
        const vm = new FieldListItemEditorViewModel();
        vm.items = JSON.parse(JSON.stringify((field as ListFieldViewModel).items));
        this.itemEditorViewModel = vm;
        const result = await this.itemEditorViewModel.interact();
        this.itemEditorViewModel = null;

        if (result === "cancel") {
            return;
        }

        field.items = vm.items as FieldItemModel[];
    }

    public remove(field: FieldViewModel) {
        const removalIndex = this.fields.indexOf(field);
        this.fields.splice(removalIndex, 1);
    }

    public async addField() {
        const vm = new AddFieldViewModel();
        this.addFieldViewModel = vm;
        const result = await this.addFieldViewModel.interact();
        this.addFieldViewModel = null;

        if (result === "cancel") {
            return;
        }

        this.addFieldType(vm.selectedFieldType!);
    }

    public toModel() {
        const fields = [];
        for (const field of this.fields) {
            fields.push(field.toModel());
        }

        return fields;
    }

    protected addFieldType(selectedFieldType: FieldType) {
        let fieldVm: FieldViewModel | null = null;
        switch (selectedFieldType) {
            case FieldType.Boolean:
                fieldVm = new BooleanFieldViewModel();
                break;
            case FieldType.List:
                fieldVm = new ListFieldViewModel();
                break;
            case FieldType.Text:
                fieldVm = new TextFieldViewModel();
                break;
            default:
                throw new Error("Not Implemented");
        }

        fieldVm.required = true;
        this.fields.push(fieldVm);
    }

    public constructor(model: { fields: FieldModel[] }) {
        super();

        for (const field of model.fields) {
            this.fields.push(ViewModelFactory.getViewModel({ model: field }));
        }
    }
}
