import IArtworkProcessItem from "@/chipply/process/IArtworkProcessItem";
import IArtwork from "@/chipply/process/IArtwork";
import { ProcessItemType } from "chipply-common";

export default class ArtworkProcessItem implements IArtworkProcessItem {
    public artworkId = 0;
    public artworkLocationId = 0;
    public imageUrl: string | null = null;
    public name: string | null = null;
    public placement = "Not Defined";
    public price: number | null = null;
    public processItemId = 0;
    public notes: string | null = null;
    public workOrderNotes: string | null = null;
    public type: ProcessItemType;

    constructor(artworkId?: number, artworks?: IArtwork[]) {
        this.type = ProcessItemType.ArtworkLogo;

        if (artworkId) {
            this.artworkId = artworkId;

            if (artworks) {
                for (const artwork of artworks) {
                    if (this.artworkId !== artwork.artworkId) {
                        continue;
                    }
                    this.imageUrl = artwork.imageUrl;
                }
            }
        } else {
            this.imageUrl = "/images/store/no-image-box.png";
        }
    }
}
