














import { WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import StoreCreationOptions from "@/components/events/StoreCreationOptions.vue";
import ICreateEventArgs from "@/chipply/event/ICreateEventArgs";

@Component({
    components: { LongRunningOperationDialog, StoreCreationOptions },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        eventType: String,
        value: Boolean,
    },
})
export default class EventAdder extends Vue {
    public eventType: string | undefined;
    public selectedEventType: string | undefined;
    public value!: boolean;
    public showCopyingDialog!: boolean;
    public showStoreOptionsDialog!: boolean;
    public name = "EventAdder";
    public statusMessage!: string | undefined;

    public data() {
        return {
            selectedEventType: this.eventType || "0",
            showCopyingDialog: false,
            showStoreOptionsDialog: false,
            statusMessage: "",
        };
    }

    @Watch("eventType")
    protected onEventTypeChanged() {
        this.selectedEventType = this.eventType;
    }

    @Watch("value")
    protected async onShowChanged(nv: boolean) {
        if (!nv) {
            return;
        }

        this.showStoreOptionsDialog = true;
    }

    private async addStore(args: { storeName: string; eventType: number; accepted: boolean }) {
        const newStoreName = args.storeName;
        const newEventType = Number(args.eventType);

        this.showStoreOptionsDialog = false;

        if (!args.accepted) {
            this.$emit("change", false);
            return;
        }

        const addArgs: ICreateEventArgs = {
            eventName: newStoreName,
            eventType: newEventType,
        };

        this.showCopyingDialog = true;
        this.statusMessage = `Creating Store ${newStoreName}`;
        const results = await WebHelper.postJsonData("/api/Events/CreateEvent", addArgs);
        this.statusMessage = "Store Creation Completed!";
        document.location.href = "/ng/settings.html?eventid=" + results;
    }
}
