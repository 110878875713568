
































import CAddressEditor from "@/components/ui/CAddressEditor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { IAddress } from "chipply-common";
@Component({
    components: {
        CAddressEditor,
        TextHeading,
    },
    props: {
        address: Object,
        disabled: Boolean,
        enableAutocomplete: Boolean,
        isRequired: Boolean,
        warning: String,
    },
})
export default class OrderAddressEditor extends Vue {
    public address!: IAddress | null;
    public isRequired!: boolean;
    public chipplyIcons = chipplyIcons;
    protected validateAddressField(value: string) {
        if (!this.isRequired) {
            return true;
        }
        if (value) {
            return true;
        }
        return "You must enter a value";
    }
}
