


















































































































































import chipplyIcons from "@/chipply/ImportIcons";
import EventProductColorSizeGridViewModel from "@/chipply/products/event-product-color-size-grid-view-model";
import EventProductColorSizeViewModel from "@/chipply/products/event-product-color-size-view-model";
import IEventProductColorSizeDto from "@/chipply/products/i-event-product-color-size-dto";
import { Utils, WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import ProductColorSizeGrid from "@/components/products/ProductColorSizeGrid.vue";
import IEventProductColorSizeGridDto from "@/chipply/products/i-event-product-color-size-grid-dto";
import { mask } from "vue-the-mask";
import _ from "lodash";

@Component({
    components: { ProductColorSizeGrid },
    directives: { mask },
    props: {
        eventProductId: Number,
        overrideFeedsBasedQty: Boolean,
        useFeedsBasedQty: Boolean,
    },
})
export default class EventProductColorSizeGrid extends Vue {
    public chipplyIcons = chipplyIcons;
    public name = "EventProductColorSizeGrid";
    public loading = false;
    public allChecked = false;
    public useFeedsBasedQty = false;
    public overrideFeedsBasedQty = false;
    public eventProductId!: number;
    public contentCellStyle = "";
    public upcMode = false;
    public settings: EventProductColorSizeGridViewModel | null = null;
    public saving = false;
    public productTitle = "";
    public horizontalScrollWidth = "880px";
    public horizontalScrollTop = "-100px";
    public horizontalScrollLeft = "0px";
    public errorMessage = "";
    public isValid = true;

    private originalData: {
        colorSizes?: IEventProductColorSizeDto[][];
        usesFeedsBasedQty?: boolean;
    } = {};

    @Watch("eventProductId")
    protected async onEventProductIdChanged(newValue: number, oldValue: number) {
        if (!newValue) {
            return;
        }
        await this.loadProduct();
    }

    protected async loadProduct() {
        this.loading = true;
        this.allChecked = false;
        const result = await this.getColorSizeInfo(this.eventProductId);
        this.buildGrid(result);

        const originalModel = this.settings!.toModel();
        this.originalData.colorSizes = originalModel.colorSizes;
        if (this.overrideFeedsBasedQty) {
            this.originalData.usesFeedsBasedQty = originalModel.usesFeedsBasedQty;
            this.settings!.usesFeedsBasedQty = this.useFeedsBasedQty;
        } else {
            this.originalData.usesFeedsBasedQty = originalModel.usesFeedsBasedQty;
        }

        this.loading = false;
    }

    private async created() {
        this.contentCellStyle = "min-width: 80px;max-width: 80px;";
        if (this.eventProductId) {
            await this.loadProduct();
        }
    }

    private upcModeToggled() {
        this.upcMode = !this.upcMode;
        this.contentCellStyle = this.upcMode
            ? "min-width: 140px;max-width: 140px;"
            : "min-width: 80px;max-width: 80px;";
    }

    public async close() {
        this.$emit("close");
    }

    public async save() {
        if (!this.isModified()) {
            this.$emit("close");
            return;
        }

        this.saving = true;
        let result = null;
        try {
            const jsonResult = await WebHelper.postJsonData(`/api/EventProductColorSizeGrid`, this.settings!.toModel());
            result = JSON.parse(jsonResult);

            if (result.hasError === true) {
                this.errorMessage = "Save Failed - " + result.errorMessage;
                this.saving = false;
                return;
            }
        } catch (e) {
            this.errorMessage = (e as any).message;
            this.saving = false;
            return;
        }

        this.saving = false;

        this.$emit("close", result);
    }

    private toErrorMessageItem(colorSizes: EventProductColorSizeViewModel[], upc: string) {
        if (colorSizes.length < 2) return "";
        var errorMessage = `UPC [${upc}] is duplicated in: `;
        for (var item of colorSizes) {
            errorMessage += `[${item.color} - ${item.size}] `;
            item.isValidUpc = false;
        }
        errorMessage = errorMessage.substring(0, errorMessage.length - 1) + ". ";
        return errorMessage;
    }

    private cleanOldUpc(
        colorSizesByUpcArgs: { [key: string]: EventProductColorSizeViewModel[] },
        colorSizeVM: EventProductColorSizeViewModel,
        upcs: string[]
    ) {
        if (colorSizeVM.lastUpc == null || colorSizeVM.lastUpc.length == 0) {
            return;
        }

        if (colorSizeVM.lastUpc in colorSizesByUpcArgs) {
            let lastIndex = colorSizesByUpcArgs[colorSizeVM.lastUpc].indexOf(colorSizeVM);
            if (lastIndex >= 0) {
                upcs.push(colorSizeVM.lastUpc);
                colorSizesByUpcArgs[colorSizeVM.lastUpc].splice(lastIndex, 1);
                return;
            }
        }

        //should not fall into this.
        for (let upcItem in colorSizesByUpcArgs) {
            let oldIndex = colorSizesByUpcArgs[upcItem].indexOf(colorSizeVM);
            if (oldIndex >= 0) {
                upcs.push(upcItem);
                colorSizesByUpcArgs[upcItem].splice(oldIndex, 1);
                break;
            }
        }
    }

    public validateUpcField(colorSizeItem: EventProductColorSizeViewModel) {
        if (colorSizeItem.upc == null) {
            return true;
        }

        if (colorSizeItem.upc != null) {
            colorSizeItem.upc = colorSizeItem.upc.trim();
        }
        const colorSizesByUpc = this.settings!.colorSizesByUpc;

        let upcsToUpdate: string[] = [];
        if (colorSizeItem.upc in colorSizesByUpc && colorSizesByUpc[colorSizeItem.upc].indexOf(colorSizeItem) >= 0) {
            //already existed, and not changed.
            return true;
        }
        this.cleanOldUpc(colorSizesByUpc, colorSizeItem, upcsToUpdate);

        if (colorSizeItem.upc in colorSizesByUpc) {
            colorSizesByUpc[colorSizeItem.upc!].push(colorSizeItem);
        } else {
            if (colorSizeItem.upc.length > 0) {
                colorSizesByUpc[colorSizeItem.upc] = [];
                colorSizesByUpc[colorSizeItem.upc].push(colorSizeItem);
            } else {
                colorSizeItem.isValidUpc = true;
            }
        }

        if (colorSizeItem.upc.length > 0 && upcsToUpdate.indexOf(colorSizeItem.upc) == -1) {
            upcsToUpdate.push(colorSizeItem.upc);
        }

        let errorMsg = this.errorMessage;
        for (var upc of upcsToUpdate) {
            var isUpcValid = colorSizesByUpc[upc].length < 2;
            colorSizesByUpc[upc].forEach((element) => {
                element.isValidUpc = isUpcValid;
            });
            if (errorMsg.indexOf(`[${upc}]`) >= 0) {
                let items = errorMsg.split(". ");
                for (var i = 0; i < items.length; i++) {
                    if (items[i].indexOf(`[${upc}]`) >= 0) {
                        items[i] = this.toErrorMessageItem(colorSizesByUpc[upc], upc);
                        break;
                    }
                }
                errorMsg = items.filter((x) => x.trim().length > 0).join(". ");
            } else if (!isUpcValid) {
                errorMsg += this.toErrorMessageItem(colorSizesByUpc[upc], upc);
            }
        }
        this.errorMessage = errorMsg;
        this.isValid = errorMsg.length == 0;

        //set to null when empty
        if (colorSizeItem.upc.length == 0) {
            colorSizeItem.upc = null;
        }
        //already returns true as color already applied for invalid items
        return true;
    }

    public checkAll(checked: boolean) {
        for (const row of this.settings!.sizes) {
            row.checked = checked;
        }

        for (const row of this.settings!.colors) {
            row.checked = checked;
        }

        for (const row of this.settings!.colorSizes) {
            for (const colorRow of row) {
                colorRow.enabled = checked;
            }
        }
    }

    public checkAllSizes(eventInfo: {
        sizeIndex: number;
        size: {
            eventProductSizeId: number;
            sizeName: string;
            checked: boolean;
        };
    }) {
        for (const row of this.settings!.colorSizes) {
            row[eventInfo.sizeIndex].enabled = eventInfo.size.checked;
        }
    }

    public checkAllColors(eventData: {
        colorIndex: number;
        color: {
            eventProductColorId: number;
            colorName: string;
            checked: boolean;
        };
    }) {
        const row = this.settings!.colorSizes[eventData.colorIndex];

        for (const colorRow of row) {
            colorRow.enabled = eventData.color.checked;
        }
    }

    private getColorSizeInfo(eventProductId: number): Promise<IEventProductColorSizeGridDto> {
        console.log("here");
        return WebHelper.getJsonData(
            `/api/EventProductColorSizeGrid/${eventProductId}`
        ) as Promise<IEventProductColorSizeGridDto>;
    }

    private buildGrid(dto: IEventProductColorSizeGridDto) {
        const viewModel = new EventProductColorSizeGridViewModel(dto);
        this.settings = viewModel;
    }

    private isModified() {
        const newModel = this.settings!.toModel();
        if (newModel.usesFeedsBasedQty !== this.originalData.usesFeedsBasedQty) {
            return true;
        }
        return !_.isEqual(newModel.colorSizes, this.originalData.colorSizes);
    }
}
