import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";

export abstract class PageViewModel {
    protected readonly savingMessage = "Saving...";
    protected readonly loadingMessage = "Loading...";
    protected readonly deletingMessage = "deleting...";
    protected readonly loadingErrorMessage = "An error occurred while loading the data.";

    public isValid = false;
    public loading = true;
    public statusMessage: string | null = this.loadingMessage;
    public errorMessage: string | null = null;
    public showErrorMessage = false;

    public get isModified() {
        return this._isModified;
    }

    public set isModified(value) {
        this._isModified = value;
    }

    private _isModified = false;

    protected hasChanges() {
        return false;
    }

    protected save(): Promise<boolean> {
        throw new Error("Save is not implemented");
    }

    // tslint:disable-next-line:no-empty
    protected startHandlePageNavigation() {}
    // tslint:disable-next-line:no-empty
    protected endHandlePageNavigation() {}

    protected handlePageNavigation = (location: string, eventArgs: IAsyncEventArgs) => {
        if (this.hasChanges()) {
            const promise = new Promise(async (resolve, reject) => {
                let canceled = false;
                try {
                    this.startHandlePageNavigation();
                    const saveResult = await this.save();
                    canceled = !saveResult;
                    eventArgs.cancel = canceled;
                    resolve(canceled);
                } catch (e) {
                    reject(e);
                } finally {
                    this.endHandlePageNavigation();
                }
            });
            eventArgs.promises.push(promise);
        }
    };

    protected dispose() {}
}
