
























































































































































































































































































































































































































import { GroupColorAssignmentViewModel } from "@/chipply/store/color-assignment/GroupColorAssignmentViewModel";
import { ProductColorAssignmentViewModel } from "@/chipply/store/color-assignment/ProductColorAssignmentViewModel";
import CEditButton from "@/components/ui/CEditButton.vue";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import ColorAssignmentsViewModel from "@/chipply/store/color-assignment/ColorAssignmentPageViewModel";
import EditLayout from "@/components/layout/EditLayout.vue";
import { EventBus } from "@/chipply/EventBus";
import { Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import IArtwork from "@/chipply/process/IArtwork";
import { IColorAssignment } from "@/chipply/interface/IColorAssignment";
import { IProductColorAssignment } from "@/chipply/interface/i-product-color-assignment";
import TreeSelector from "../input/TreeSelector.vue";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import CSaveDialog from "@/components/dialogs/CSaveDialog.vue";
import { AsyncInteractionViewModel } from "chipply-common";
import { ColorAssignmentViewModel } from "@/chipply/store/color-assignment/ColorAssignmentViewModel";

@Component({
    components: { CEditButton, EditLayout, TreeSelector, CSaveDialog },
})
export default class ColorAssignment extends Vue {
    public eventId = 0;
    public chipplyIcons = chipplyIcons;
    public vm = new ColorAssignmentsViewModel();

    // @ts-ignore
    public $refs!: {
        variationFilter: HTMLSelectElement;
        variationFilterByProduct: HTMLSelectElement;
    };
    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eventid")) {
            this.eventId = Number(urlParams.get("eventid"));
            this.vm.eventId = this.eventId;
            await this.vm.getColorAssignments();
        }
        EventBus.$on("top-navigation-started", (location: string, eventArgs: IAsyncEventArgs) => {
            if (this.vm.modifiedAssignmentsByColor.length === 0 && this.vm.modifiedAssignmentsByProduct.length === 0) {
                return;
            }
            const promise = new Promise(async (resolve, reject) => {
                this.vm.confirmViewModel = new AsyncInteractionViewModel();
                const result = await this.vm.confirmViewModel.interact();
                if (result === "cancel") {
                    eventArgs.cancel = true;
                    resolve(eventArgs.cancel);
                    this.vm.confirmViewModel = null;
                } else if (result === "accept") {
                    this.vm.confirmViewModel = null;
                    await this.vm.save();
                    resolve(false);
                } else if (result === "continue") {
                    this.vm.confirmViewModel = null;
                    resolve(false);
                }
            });
            eventArgs.promises.push(promise);
        });
    }

    public artworkChanged(artworkId: number) {
        this.vm.artworkChanged(artworkId);
    }

    public assignmentByColorVariationChanged(newValue: number, item: ColorAssignmentViewModel) {
        this.vm.assignmentByColorVariationChanging(newValue, item);
    }

    public assignmentByProductVariationChanged(newValue: number, item: ProductColorAssignmentViewModel) {
        const wasChanged = item.isChanged;
        this.vm.assignmentByProductVariationChanging(newValue, item);

        if (item.isChanged && !wasChanged) {
            item.colorName = item.colorName + " - " + item.productStyle;
            item.isManualAssignment = true;
            item.isEditing = true;
        }

        this.$nextTick(() => {
            (this.$refs as any)["colorName" + item.id].focus();
        });
    }
    @Watch("vm.filterByVariationIds")
    public watchFilterByVariationIds() {
        if (this.$refs.variationFilter) {
            this.$refs.variationFilter.blur();
        }
        if (this.$refs.variationFilterByProduct) {
            this.$refs.variationFilterByProduct.blur();
        }
        this.vm.getColorAssignments();
    }

    public async tabsChanged(index: number) {
        this.vm.tabsChanged(index);
    }

    public validateProductAssignmentColorName(item: ProductColorAssignmentViewModel) {
        return (newValue: string) => {
            if (!item.isManualAssignment) {
                return true;
            }
            for (const gc of this.vm.colorAssignments.assignmentsByColor) {
                for (const ca of gc.assignments) {
                    if (ca.colorName != newValue) {
                        continue;
                    }
                    return ca.eventArtworkVariationId == item.eventArtworkVariationId
                        ? true
                        : "You must enter a new color name";
                }
            }

            return true;
        };
    }

    public get saveDisabled() {
        const noModifiedAssignments = this.vm.modifiedAssignmentsByColor.length === 0;
        const noModifiedProductAssignments = this.vm.modifiedAssignmentsByProduct.length === 0;

        for (const assignment of this.vm.colorAssignments.assignmentsByProduct) {
            if (!assignment.isValid) {
                return true;
            }
            if (assignment.isEditing) {
                return true;
            }
        }

        return noModifiedAssignments && noModifiedProductAssignments;
    }
}
