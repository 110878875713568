import { IBoxPercentages } from "@/chipply/canvas/IBoxPercentages";
import { IGetNewBoxArgs } from "@/chipply/canvas/IGetNewBoxArgs";
import { IBoxDimensions } from "@/chipply/process/IBoxDimensions";
import { fabric } from "fabric";
import { Point } from "fabric/fabric-impl";

export class CanvasHelper {
    public static capCanvasObjectSize(canvas: fabric.Canvas, maxSize: number) {
        canvas.on("object:scaling", function (e) {
            const obj = e.target as any;

            if (obj.width * obj.scaleX > maxSize) {
                obj.scale(1);
            }

            if (obj.height * obj.scaleY > maxSize) {
                obj.scale(1);
            }
        });
    }

    public static preventOutsideCanvasMove(canvas: fabric.Canvas) {
        canvas.on("object:moving", (e) => {
            const obj = e.target!;
            // if object is too big ignore
            if ((obj as any).currentHeight > obj.canvas!.height! || (obj as any).currentWidth > obj.canvas!.width!) {
                return;
            }
            obj.setCoords();
            // top-left  corner
            if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
                obj.top = Math.max(obj.top!, obj.top! - obj.getBoundingRect().top);
                obj.left = Math.max(obj.left!, obj.left! - obj.getBoundingRect().left);
            }
            // bot-right corner
            if (
                obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas!.height! ||
                obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas!.width!
            ) {
                const remainingHeight = obj.canvas!.height! - obj.getBoundingRect().height;
                const remainingWidth = obj.canvas!.width! - obj.getBoundingRect().width;
                obj.top = Math.min(obj.top!, remainingHeight + obj.top! - obj.getBoundingRect().top);
                obj.left = Math.min(obj.left!, remainingWidth + obj.left! - obj.getBoundingRect().left);
            }
        });
    }

    public static getTransform(rotation: number) {
        return `rotate(${rotation}deg)`;
    }

    public static async getNewBoundingBox(canvas: fabric.Canvas, args: IGetNewBoxArgs): Promise<fabric.Group> {
        let resolver: ((value: any) => void) | null = null;
        const promise = new Promise<fabric.Group>((resolve) => {
            resolver = resolve;
        });

        fabric.Image.fromURL(args.artworkPath, (oImg: fabric.Image) => {
            oImg.selectable = false;
            const artworkSize = 400;
            const defaultScale = args.boxLayout.width / artworkSize;
            const scaleAdjustment = args.artworkBox.scale / 100;
            const scaledArtworkSize = artworkSize * scaleAdjustment;
            const scaledArtworkScale = args.boxLayout.width / scaledArtworkSize;
            const totalScale = defaultScale * scaleAdjustment;

            if (args.artworkBox.scale > 100) {
                if (args.artworkBox.x < 0) {
                    oImg.cropX = Math.abs(args.artworkBox.x) / scaleAdjustment;
                }
                if (args.artworkBox.y < 0) {
                    oImg.cropY = Math.abs(args.artworkBox.y) / scaleAdjustment;
                }
                if (scaledArtworkSize > artworkSize) {
                    const oversizeInPixels = scaledArtworkSize - artworkSize;
                    if (args.artworkBox.y > 0) {
                        oImg.height! -= oversizeInPixels + args.artworkBox.y / scaleAdjustment;
                    } else {
                        oImg.height! -= oversizeInPixels / scaleAdjustment;
                    }
                    if (args.artworkBox.x > 0) {
                        oImg.width! -= oversizeInPixels + args.artworkBox.x / scaleAdjustment;
                    } else {
                        oImg.width! -= oversizeInPixels / scaleAdjustment;
                    }
                }
            } else {
                if (args.artworkBox.y > 0) {
                    const imageHeight = artworkSize - args.artworkBox.y;
                    oImg.height! = (1 / scaleAdjustment) * imageHeight;
                }
                if (args.artworkBox.x > 0) {
                    const imageWidth = artworkSize - args.artworkBox.x;
                    oImg.width! = (1 / scaleAdjustment) * imageWidth;
                }

                oImg.cropX = args.artworkBox.x < 0 ? (1 / scaleAdjustment) * args.artworkBox.x * -1 : 0;
                oImg.cropY = args.artworkBox.y < 0 ? (1 / scaleAdjustment) * args.artworkBox.y * -1 : 0;
            }

            oImg.scale(totalScale);
            oImg.originX = "left";
            oImg.originY = "top";
            oImg.left = Math.max(args.artworkBox.x * defaultScale, 0);
            oImg.top = Math.max(args.artworkBox.y * defaultScale, 0);

            const rect = new fabric.Rect({
                left: 0,
                top: 0,
                stroke: "lightgray",
                fill: "rgba(0,0,0,0)",
                width: args.boxLayout.width - 1,
                height: args.boxLayout.height - 1,
            });
            const group = new fabric.Group([oImg, rect], {
                left: args.boxLayout.x + args.gutters.width,
                top: args.boxLayout.y + args.gutters.height,
                width: args.boxLayout.width,
                height: args.boxLayout.height,
                rotatingPointOffset: args.boxRotation,
                lockUniScaling: true,
                lockScalingFlip: true,
            });
            group.hasBorders = true;
            group.drawBorders(canvas.getContext());
            group.setControlsVisibility({
                bl: true,
                br: true,
                tr: true,
                tl: true,
                mr: false,
                ml: false,
                mb: false,
                mt: false,
            });

            group.centeredRotation = true;
            group.rotate(args.boxRotation);
            // group.dirty = true;
            oImg.hasBorders = true;
            oImg.borderColor = "black";
            resolver!(group);
        });

        return promise;
        // const box = document.createElement("div");
        // box.setAttribute("style", this.getBoundingStyle(args.boxLayout));
        // const image = document.createElement("img");
        // const artworkPath = args.artworkPath ?? "/images/store/no-image-box.png";
        // image.setAttribute("src", artworkPath);
        // image.setAttribute("style", this.getBoundingImageStyle(args.artworkLayout));
        // box.style.transform = this.getTransform(args.boxRotation);
        // box.appendChild(image)
        // return box;
    }

    private static boundingHtml = `<div class="bounding">
  <img src="/images/store/no-image-box.png" style="width:100%;height:100%" />
      </div>`;

    private static getBoundingImageStyle(args: IBoxPercentages) {
        return (
            "margin-left:" +
            args.left +
            "%; margin-top:" +
            args.top +
            "%; width:" +
            args.width +
            "%; height:" +
            args.height +
            "%;"
        );
    }

    private static getBoundingStyle(args: IBoxDimensions) {
        return (
            "border:1px dashed #808080; left:" +
            args.x +
            "px; top:" +
            args.y +
            "px; width:" +
            args.width +
            "px; height:" +
            args.height +
            "px;"
        );
    }
}
