import IPurchaseOrderArgs from "./IPurchaseOrderArgs";
import PurchasingFilters from "./PurchasingFilters";
import SubstituteSizeMapping from "./SubstituteSizeMapping";
import VendorPurchaseOrder from "./VendorPurchaseOrder";

export default class SubstituteProductArgs implements IPurchaseOrderArgs {
    public filters: PurchasingFilters = new PurchasingFilters();
    public replacementChipplyProductColorSizeId: number | null = null;
    public ecomOrderItemIds: number[] = [];
    public purchaseOrder: VendorPurchaseOrder = new VendorPurchaseOrder();
    public sizeMappings = new Array<SubstituteSizeMapping>();
}
