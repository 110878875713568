import DepositSummaryResult from "@/chipply/deposits/DepositSummaryResult";
import IDepositSummary from "@/chipply/deposits/IDepositSummary";
import IDepositSummaryResult from "@/chipply/deposits/IDepositSummaryResult";

export default class DepositSummary implements IDepositSummary {
    public storeName = "";
    public saleOrder = "";
    public totalCollected = 0;
    public totalChipplyFees = 0;
    public totalProcessingFees = 0;
    public totalFees = 0;
    public totalDeposited = 0;
    public upcomingDeposit = 0;
    public totalRevenue = 0;
    public deposits: DepositSummaryResult[];

    public constructor(summary: IDepositSummary) {
        Object.assign(this, summary);
        this.deposits = [];
        for (const dep of summary.deposits) {
            this.deposits.push(new DepositSummaryResult(dep));
        }
    }
}
