





































































































































































































































































































































import EmailTemplateMode from "@/chipply/emails/EmailTemplateMode";
import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import { EventType } from "@/chipply/event/EventType";
import chipplyIcons from "@/chipply/ImportIcons";
import AutomatedDealerStoreMergeTemplateViewModel from "@/chipply/merge/AutomatedDealerStoreMergeTemplateViewModel";
import AutomatedMergeTemplateViewModel from "@/chipply/merge/AutomatedMergeTemplateViewModel";
import CustomerMergeTemplateViewModel from "@/chipply/merge/CustomerMergeTemplateViewModel";
import PurchasingMergeTemplateViewModel from "@/chipply/merge/PurchasingMergeTemplateViewModel";
import DealerCustomerMergeTemplateViewModel from "@/chipply/merge/DealerCustomerMergeTemplateViewModel";
import { MergeTemplateRecipientType } from "@/chipply/merge/MergeTemplateRecipientType";
import { MessageType } from "@/chipply/merge/MessageType";
import StoreCustomerMergeTemplateViewModel from "@/chipply/merge/StoreCustomerMergeTemplateViewModel";
import EmailRecipientListEditor from "@/components/emails/EmailRecipientListEditor.vue";
import PhoneRecipientListEditor from "@/components/emails/PhoneRecipientListEditor.vue";
import FormValidationMixin from "@/components/FormValidationMixin";
import HtmlEditor from "@/components/HtmlEditor.vue";
import EditLayout from "@/components/layout/EditLayout.vue";
import OrderSelector from "@/components/orders/OrderSelector.vue";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import SmsMergeEditor from "@/components/SmsMergeEditor.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import CDate from "@/components/ui/CDate.vue";
import CTime from "@/components/ui/CTime.vue";
import CancelSaveButtonBarDesktop from "@/components/utility/CancelSaveButtonBarDesktop.vue";
import CancelSaveButtonBarMobile from "@/components/utility/CancelSaveButtonBarMobile.vue";
import ViewHeader from "@/components/utility/ViewHeader.vue";
import Validation from "@/validation";
import { DateTime } from "luxon";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import CToolbarBtn from "../ui/CToolbarBtn.vue";
import MergeFields from "@/chipply/merge/MergeFields";
import StoreEcertMergeTemplateViewModel from "@/chipply/merge/StoreEcertMergeTemplateViewModel";
import EcertMergeTemplateViewModel from "@/chipply/merge/EcertMergeTemplateViewModel";
import { IMergeMessageViewModel } from "@/chipply/merge/MergeMessageViewModel";
import IMergeTemplateDto from "@/chipply/merge/IMergeTemplateDto";
import { IDealerStoreMergeTemplate } from "@/chipply/merge/IDealerStoreMergeTemplate";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";
import DealerEcertMergeTemplateViewModel from "@/chipply/merge/DealerEcertMergeTemplateViewModel";
import { StoreMergeTemplateViewModelBase } from "@/chipply/merge/StoreMergeTemplateViewModelBase";

enum EmailReminderView {
    Template,
    Order,
}

@Component({
    components: {
        CDate,
        CTime,
        CToolbarBtn,
        EditLayout,
        CControlLabel,
        CancelSaveButtonBarDesktop,
        CancelSaveButtonBarMobile,
        EmailRecipientListEditor,
        HtmlEditor,
        OrderSelector,
        PhoneRecipientListEditor,
        SmsMergeEditor,
        ViewHeader,
    },
    directives: { mask },
    props: {
        errorMessage: String,
        hideCancel: Boolean,
        statusMessage: String,
        title: String,
        initialEmailTemplate: Object,
        mode: Number,
        eventId: Number,
        allowSms: Boolean,
        isLoading: Boolean,
    },
})
export default class EmailReminder extends mixins(ScrollableContentMixin, FormValidationMixin) {
    public StoreMergeTemplateViewModelBase = StoreMergeTemplateViewModelBase;
    public PurchasingMergeTemplateViewModel = PurchasingMergeTemplateViewModel;
    public StoreCustomerMergeTemplateViewModel = StoreCustomerMergeTemplateViewModel;
    public StoreEcertMergeTemplateViewModel = StoreEcertMergeTemplateViewModel;
    public Validation = Validation;
    public templateType: MergeTemplateType = MergeTemplateType.StoreAutomated;
    public EmailTemplateView = EmailReminderView;
    public chipplyIcons = chipplyIcons;
    public name = "EmailReminder";
    public eventId!: number | null;
    public initialEmailTemplate!: AutomatedMergeTemplateViewModel;
    public emailTemplate = this.initialEmailTemplate;
    public showStaticDateMenu!: boolean;
    public showStaticTimeMenu!: boolean;
    public title!: string;
    public computedTitle = this.title || "Edit Message";
    public view = EmailReminderView.Template;
    public panel = [0, 1];
    public allowSms!: boolean;
    public mode!: EmailTemplateMode;
    public selectedTemplate: number | null = null;
    public selectedHtmlSubjectMergeField: string | null = null;
    protected EmailTemplateMode = EmailTemplateMode;

    public messageNameRules(value: any) {
        return this.mode !== EmailTemplateMode.Dealer ? true : Validation.requireValue(value);
    }
    public get triggers() {
        return [
            { text: "After Open Date", value: 0 },
            { text: "Before Open Date", value: 1 },
            { text: "After Close Date", value: 2 },
            { text: "Before Close Date", value: 3 },
            { text: "Static Date", value: 4 },
            { text: "Abandoned Cart", value: 5 },
        ];
    }

    public get templateTypes() {
        return [
            {
                text: "Store Automated",
                value: MergeTemplateType.StoreAutomated,
            },
            {
                text: "Message Customers",
                value: MergeTemplateType.MessageCustomers,
            },
            { text: "E-Certificates", value: MergeTemplateType.ECertificate },
            { text: "Purchasing", value: MergeTemplateType.Purchasing },
        ];
    }
    public htmlSubjectMergeFieldChanged() {
        if (!this.selectedHtmlSubjectMergeField) {
            return;
        }
        this.emailTemplate.subject += " " + MergeFields.formatMergeField(this.selectedHtmlSubjectMergeField) + " ";

        this.$nextTick(() => {
            this.selectedHtmlSubjectMergeField = null;
        });
    }

    public get automatedEmailTemplate() {
        return this.emailTemplate as AutomatedDealerStoreMergeTemplateViewModel;
    }

    public get showSend() {
        const date = this.emailTemplate.staticDate;
        const time = this.emailTemplate.staticTime;
        if (this.emailTemplate.trigger !== EmailTemplateTriggerTypeEnum.StaticDate) {
            return false;
        }
        const scheduledDate = this.emailTemplate.getDate(date!, time!);
        return DateTime.fromISO(scheduledDate) < DateTime.local();
    }

    @Watch("initialEmailTemplate")
    public onEmailTemplateChanged() {
        this.emailTemplate = this.initialEmailTemplate;
        this.setupEmailTemplate();

        this.validate();
    }

    public created() {
        this.setupEmailTemplate();
    }

    public data() {
        return {
            EmailTemplateRecipientType: MergeTemplateRecipientType,
            EmailTemplateTriggerTypeEnum,
            contentSize: {},
            recipientTypes: [
                { text: "Custom Email", value: 0 },
                { text: "Organization Contact", value: 1 },
                { text: "Store Salesperson", value: 2 },
                { text: "Contact Information", value: 3 },
            ],
            showStaticDateMenu: false,
            showStaticTimeMenu: false,
        };
    }

    public selectedTemplateType() {
        if (this.templateType === MergeTemplateType.Purchasing) {
            const template = new PurchasingMergeTemplateViewModel();
            template.setTemplate();
            this.emailTemplate = template;
        }
        if (this.templateType === MergeTemplateType.ECertificate) {
            const template = new DealerEcertMergeTemplateViewModel();
            template.setTemplate();
            this.emailTemplate = template;
        }
        if (this.templateType === MergeTemplateType.MessageCustomers) {
            const template = new DealerCustomerMergeTemplateViewModel();
            template.setTemplate();
            this.emailTemplate = template;
        }
        if (this.templateType === MergeTemplateType.StoreAutomated) {
            const template = new AutomatedDealerStoreMergeTemplateViewModel();
            template.setTemplate();
            this.emailTemplate = template;
        }
    }

    protected templateChanged() {
        const mergeMessage = this.emailTemplate as unknown as IMergeMessageViewModel;
        if (!this.selectedTemplate) {
            return;
        }
        let currentTemplate: IMergeTemplateDto | null = null;
        for (const template of mergeMessage.templates) {
            if (template.id !== this.selectedTemplate) {
                continue;
            }
            currentTemplate = template;
            break;
        }

        if (!currentTemplate) {
            return;
        }
        currentTemplate = JSON.parse(JSON.stringify(currentTemplate));
        mergeMessage.resetTemplate(currentTemplate!);
    }

    protected requireOneOrder(value: any) {
        const moreThanOneEmailRecipient =
            this.emailTemplate!.isMessageEmail && this.emailTemplate!.toRecipients.length > 0;
        const moreThanOneSmsRecipient =
            this.emailTemplate.isMessageSms && this.emailTemplate!.sms!.recipients.length > 0;
        const moreThanOneOrder = (this.emailTemplate as StoreCustomerMergeTemplateViewModel)!.orderIds!.length > 0;
        const moreThanOneEcert = (this.emailTemplate as StoreEcertMergeTemplateViewModel)!.ecertIds!.length > 0;
        return moreThanOneEmailRecipient || moreThanOneSmsRecipient || moreThanOneOrder || moreThanOneEcert
            ? true
            : "You must select at least one email recipient";
    }

    protected ordersSelected(accepted: boolean, orderIds: number[]) {
        if (!accepted) {
            this.view = EmailReminderView.Template;
            this.validate();
            return;
        }

        (this.emailTemplate as StoreCustomerMergeTemplateViewModel)!.orderIds = orderIds;
        this.view = EmailReminderView.Template;
        this.validate();
    }

    protected setupEmailTemplate() {
        if (
            this.emailTemplate instanceof StoreCustomerMergeTemplateViewModel ||
            this.emailTemplate instanceof StoreEcertMergeTemplateViewModel
        ) {
            if (this.emailTemplate.id === 0) {
                this.emailTemplate.staticDate = DateTime.local().toISODate();
                this.emailTemplate.staticTime = DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE);
            }
        } else if (this.emailTemplate instanceof PurchasingMergeTemplateViewModel) {
            if (this.emailTemplate.id === 0) {
                this.emailTemplate.staticDate = DateTime.local().toISODate();
                this.emailTemplate.staticTime = DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE);
            }
        }
        this.templateType = this.emailTemplate.templateType;
    }

    protected requireEmailRecipient(value: any[]) {
        if (!this.automatedEmailTemplate.isMessageEmail) {
            return true;
        }

        return this.requireRecipient(value);
    }

    protected requireSmsRecipient(value: any[]) {
        if (!this.automatedEmailTemplate.isMessageSms) {
            return true;
        }

        return this.requireRecipient(value);
    }

    protected requireRecipient(value: any[]) {
        return value.length > 0 ? true : "You must add at least one recipient";
    }

    protected requireIfEmailChecked(value: string) {
        const isEmail = this.automatedEmailTemplate.isMessageEmail;
        if (!isEmail) {
            return true;
        }

        return Validation.requireValue(value);
    }

    protected rebuildStoreTypes() {
        const autoTemplate = this.automatedEmailTemplate;
        autoTemplate.storeTypes = [];
        if (autoTemplate.applyGroup) {
            autoTemplate.storeTypes.push(EventType.Store);
        }
        if (autoTemplate.applyCatalog) {
            autoTemplate.storeTypes.push(EventType.Catalog);
        }
    }

    protected rebuildMessageTypes() {
        const autoTemplate = this.automatedEmailTemplate;
        autoTemplate.messageTypes = [];
        if (autoTemplate.isMessageSms) {
            autoTemplate.messageTypes.push(MessageType.Sms);
        }
        if (autoTemplate.isMessageEmail) {
            autoTemplate.messageTypes.push(MessageType.Email);
        }
        this.$nextTick(() => {
            this.validate();
        });
    }

    private close(accepted: boolean) {
        this.$emit("close", accepted, this.emailTemplate);
    }

    private onTriggerChanged() {
        this.validate();

        if (this.emailTemplate.trigger === EmailTemplateTriggerTypeEnum.AbandonedCart) {
            this.emailTemplate.isMessageSms = false;
            this.rebuildMessageTypes();
        } else if (this.emailTemplate.trigger === EmailTemplateTriggerTypeEnum.StaticDate) {
            this.emailTemplate.staticDate = DateTime.local().toISODate();
            this.emailTemplate.staticTime = DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE);
        } else {
            this.emailTemplate.staticDate = null;
            this.emailTemplate.staticTime = null;
        }
    }
}
