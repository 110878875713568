import IPurchaseOrderArgs from "./IPurchaseOrderArgs";
import PurchasingFilters from "./PurchasingFilters";
import VendorPurchaseOrder from "./VendorPurchaseOrder";

export default class BulkUpdateNoteArgs implements IPurchaseOrderArgs {
    public note = "";

    public purchaseOrder = new VendorPurchaseOrder();

    public filters = new PurchasingFilters();
}
