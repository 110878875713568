import { __decorate } from "tslib";
import Component from "vue-class-component";
import Vue from "vue";
let CDismissDialog = class CDismissDialog extends Vue {
};
CDismissDialog = __decorate([
    Component({
        props: {
            show: Boolean,
            viewModel: Object,
        },
    })
], CDismissDialog);
export default CDismissDialog;
