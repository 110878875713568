import { ViewModel } from "chipply-common";
import { AsyncInteractionResult, EditResult, IEditable } from "chipply-common";

export default abstract class EditableViewModel extends ViewModel implements IEditable {
    private _editResolve: null | ((vm: EditResult) => void) = null;

    public done(result: AsyncInteractionResult) {
        const editResult = new EditResult(result);

        this._editResolve!(editResult);
        this._editResolve = null;
    }

    public edit(): Promise<EditResult> {
        return new Promise((resolve) => {
            this._editResolve = resolve;
        });
    }
}
