import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import CustomerMergeTemplateViewModel from "@/chipply/merge/CustomerMergeTemplateViewModel";
import { DateTime } from "luxon";
import IPurchasingMergeTemplate from "./IPurchasingMergeTemplate";
import { WebHelper } from "chipply-common";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";
import IMergeTemplateDto from "@/chipply/merge/IMergeTemplateDto";
import IStoreCustomerMergeTemplate from "@/chipply/merge/IStoreCustomerMergeTemplate";
import { IDealerStoreMergeTemplate } from "@/chipply/merge/IDealerStoreMergeTemplate";
import { IMergeMessageViewModel } from "@/chipply/merge/MergeMessageViewModel";
import { MessageType } from "@/chipply/merge/MessageType";
import IDealerEcertMergeTemplate from "@/chipply/merge/IDealerEcertMergeTemplate";

export default class PurchasingMergeTemplateViewModel
    extends CustomerMergeTemplateViewModel
    implements IMergeMessageViewModel
{
    public $typeHint = "PurchasingMergeTemplate";
    public orderIds: number[] | null = null;
    public lastSendDate!: string;
    public resend!: boolean;
    public allowSms = false;
    public supportsSms = false;
    public templateType = MergeTemplateType.Purchasing;
    public templateTypeDisplay = "Purchasing";
    public templates: IMergeTemplateDto[] = [];
    public shouldIncludePrice = true;
    public shouldIncludeUpc = true;

    public constructor() {
        super();
        this.messageTypes = [MessageType.Email];
        this.isMessageSms = false;
    }
    public toModel(): IPurchasingMergeTemplate {
        const baseModel = super.toModel();
        const model = {
            ...baseModel,
            $typeHint: this.$typeHint,
            lastSendDate: this.lastSendDate,
            orderIds: this.orderIds,
            resend: this.resend,
        } as IPurchasingMergeTemplate;
        this.setDate(model);
        model.shouldIncludeUpc = this.shouldIncludeUpc;
        model.shouldIncludePrice = this.shouldIncludePrice;
        return model;
    }
    public created(dto: IPurchasingMergeTemplate) {
        this.setTemplate(dto);
    }
    public clone() {
        const vm = new PurchasingMergeTemplateViewModel();
        vm.setTemplate(this.toModel());
        return vm;
    }
    public async loadEditData(): Promise<void> {
        this.templates = await WebHelper.getJsonData("/api/purchasing/listpurchasingemailtemplates");
    }
    public resetTemplate(template: IMergeTemplateDto) {
        const castedTemplate = template as IPurchasingMergeTemplate;
        castedTemplate.trigger = EmailTemplateTriggerTypeEnum.StaticDate;
        castedTemplate.staticDate = DateTime.local().toISO();
        castedTemplate.id = 0;
        castedTemplate.lastSendDate = "";
        castedTemplate.resend = false;
        this.setTemplate(castedTemplate);
    }

    public setTemplate(dto?: IPurchasingMergeTemplate) {
        super.setTemplate(dto);
        this.messageTypes = [MessageType.Email];
        this.isMessageSms = false;
    }
}
