var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vm)?_c('div',{staticStyle:{"display":"grid"}},[_c('div',{staticStyle:{"display":"grid"}},[_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr auto"}},[_c('v-btn',{staticClass:"chipplyButton",staticStyle:{"grid-column":"2","width":"100px","margin":"20px"},attrs:{"color":"primary","disabled":_vm.vm.modifiedAssignmentsByColor.length === 0 && _vm.vm.modifiedAssignmentsByProduct.length === 0},on:{"click":function($event){return _vm.vm.save()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(_vm._s(_vm.chipplyIcons.mdiContentSave))]),_vm._v("Save ")],1)],1),(_vm.vm.colorAssignments.warningMessage)?_c('div',[_c('v-icon',{staticStyle:{"margin":"10px"},attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.chipplyIcons.mdiAlertOutline))]),_c('span',{staticClass:"chipplyFont",staticStyle:{"align-self":"center"}},[_vm._v(_vm._s(_vm.vm.colorAssignments.warningMessage))])],1):_vm._e(),_c('v-tabs',{on:{"change":_vm.tabsChanged},model:{value:(_vm.vm.tab),callback:function ($$v) {_vm.$set(_vm.vm, "tab", $$v)},expression:"vm.tab"}},[_c('v-tab',{key:"byColor"},[_vm._v("Artwork Assignments By Color")]),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.vm.tab),callback:function ($$v) {_vm.$set(_vm.vm, "tab", $$v)},expression:"vm.tab"}},[_c('v-tab-item',{key:"byColor"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"5","cols":"9"}},[_c('v-select',{staticStyle:{"padding":"5px","margin":"10px"},attrs:{"items":_vm.vm.colorAssignments.artworks,"item-text":"artName","item-value":"artworkId","label":"Select an Artwork to work with:"},on:{"change":_vm.artworkChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('img',{staticStyle:{"max-height":"50px","margin":"5px"},attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"align-self-center text--primary body-2",staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(item.artName)+" ")])])]}}],null,false,2899476091),model:{value:(_vm.vm.eventArtworkId),callback:function ($$v) {_vm.$set(_vm.vm, "eventArtworkId", $$v)},expression:"vm.eventArtworkId"}})],1),_c('v-col',{attrs:{"md":"5","cols":"9"}},[(_vm.vm.currentItem && !_vm.vm.currentItem.eventProductColorId)?_c('v-select',{ref:"variationFilter",attrs:{"items":_vm.vm.availableVariationsItems,"label":"Filter by Decoration Color","multiple":"","chips":"","small-chips":"","deletable-chips":"","item-text":"name","item-value":"id","hint":"Filter by Decoration Color"},model:{value:(_vm.vm.filterByVariationIds),callback:function ($$v) {_vm.$set(_vm.vm, "filterByVariationIds", $$v)},expression:"vm.filterByVariationIds"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9","md":"12"}},[_c('v-data-table',{staticStyle:{"margin-right":"40px"},attrs:{"checkbox-color":"primary","items":_vm.vm.colorAssignments.assignmentsByColor,"headers":_vm.vm.artworkHeaders,"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","show-select":"","item-key":"id","single-select":_vm.vm.singleSelect},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
return [_c('v-checkbox',{attrs:{"dense":"","hide-details":"","indeterminate":props.indeterminate},on:{"change":function($event){return _vm.vm.selectedChanged(props.value)}},model:{value:(props.value),callback:function ($$v) {_vm.$set(props, "value", $$v)},expression:"props.value"}})]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('div',{staticClass:"d-flex align-start",staticStyle:{"height":"100%"}},[_c('v-checkbox',{attrs:{"dense":"","hide-details":""},on:{"change":function($event){return _vm.vm.groupSelectedChanged(item, isSelected)}},model:{value:(isSelected),callback:function ($$v) {isSelected=$$v},expression:"isSelected"}})],1)]}},{key:"item.standardColor",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-start mt-5",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.productColorCheckbox",fn:function(ref){
var item = ref.item;
return _vm._l((item.assignments),function(assignment){return _c('div',{key:assignment.id,staticClass:"d-flex align-start",staticStyle:{"height":"60px"}},[_c('v-checkbox',{attrs:{"dense":"","hide-details":""},model:{value:(assignment.colorSelected),callback:function ($$v) {_vm.$set(assignment, "colorSelected", $$v)},expression:"assignment.colorSelected"}})],1)})}},{key:"item.colorName",fn:function(ref){
var item = ref.item;
return _vm._l((item.assignments),function(assignment){return _c('div',{key:assignment.id,staticClass:"d-flex align-start pt-2",staticStyle:{"width":"100%","height":"60px"}},[_vm._v(" "+_vm._s(assignment.colorName)+" ")])})}},{key:"item.eventArtworkVariationId",fn:function(ref){
var item = ref.item;
return _vm._l((item.assignments),function(assignment){return _c('div',{key:assignment.id,staticClass:"d-flex align-center",staticStyle:{"height":"60px"}},[_c('v-select',{staticStyle:{"padding":"5px"},attrs:{"items":_vm.vm.colorAssignments.variations,"item-text":"decorationGarmentName","item-value":"eventArtworkVariationId","value":assignment.eventArtworkVariationId},on:{"change":function($event){return _vm.assignmentByColorVariationChanged($event, assignment)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('img',{staticStyle:{"max-height":"50px","margin":"5px"},attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"align-self-center text--primary body-2",staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(item.decorationGarmentName)+" ")])])]}}],null,true)})],1)})}},{key:"item.variationImage",fn:function(ref){
var item = ref.item;
return [(item.variationImage)?_c('img',{staticStyle:{"border":"1px solid #eee","background-color":"#eee"},attrs:{"src":item.variationImage,"width":"50"}}):_vm._e()]}}],null,false,3975831897),model:{value:(_vm.vm.selectedAssignmentsByColor),callback:function ($$v) {_vm.$set(_vm.vm, "selectedAssignmentsByColor", $$v)},expression:"vm.selectedAssignmentsByColor"}})],1)],1)],1)],1)],1)],1),_c('v-tab-item',{key:"byProduct"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"5","cols":"9"}},[_c('v-select',{staticStyle:{"padding":"5px","margin":"10px"},attrs:{"items":_vm.vm.colorAssignments.artworks,"item-text":"artName","item-value":"artworkId","label":"Select an Artwork to work with:"},on:{"change":_vm.artworkChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('img',{staticStyle:{"max-height":"50px","margin":"5px"},attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"align-self-center text--primary body-2",staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(item.artName)+" ")])])]}}],null,false,2899476091),model:{value:(_vm.vm.eventArtworkId),callback:function ($$v) {_vm.$set(_vm.vm, "eventArtworkId", $$v)},expression:"vm.eventArtworkId"}})],1),_c('v-col',{attrs:{"md":"5","cols":"9"}},[(_vm.vm.currentItem && !_vm.vm.currentItem.eventProductColorId)?_c('v-select',{ref:"variationFilterByProduct",attrs:{"items":_vm.vm.availableVariationsItems,"label":"Filter by Decoration Color","multiple":"","chips":"","small-chips":"","deletable-chips":"","item-text":"name","item-value":"id","hint":"Filter by Decoration Color"},model:{value:(_vm.vm.filterByVariationIds),callback:function ($$v) {_vm.$set(_vm.vm, "filterByVariationIds", $$v)},expression:"vm.filterByVariationIds"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9","md":"12"}},[_c('v-data-table',{staticStyle:{"margin-right":"30px"},attrs:{"checkbox-color":"primary","items":_vm.vm.colorAssignments.assignmentsByProduct,"headers":_vm.vm.byProductHeaders,"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","show-select":!_vm.vm.colorAssignments.isArtwork,"item-key":"id"},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.productName)+" ")]}},{key:"item.colorName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.colorName)+" ")]}},{key:"item.eventArtworkVariationId",fn:function(ref){
var item = ref.item;
return [_vm._v(" > "),_c('v-select',{staticStyle:{"padding":"5px"},attrs:{"items":_vm.vm.colorAssignments.variations,"item-text":"decorationGarmentName","item-value":"eventArtworkVariationId","value":item.eventArtworkVariationId,"disabled":item.artType === 'Art'},on:{"change":function($event){return _vm.assignmentByProductVariationChanged($event, item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('img',{staticStyle:{"max-height":"50px","margin":"5px"},attrs:{"src":item.imageUrl}}),_c('div',{staticClass:"align-self-center text--primary body-2",staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(item.decorationGarmentName)+" ")])])]}}],null,true)})]}},{key:"item.productImage",fn:function(ref){
var item = ref.item;
return [(item.productImage)?_c('img',{staticClass:"productImage",attrs:{"src":item.productImage}}):_vm._e(),(!item.productImage)?_c('div',{style:({
                                                        width: 80,
                                                        height: 80,
                                                        backgroundColor: item.hexCode1,
                                                    })}):_vm._e()]}},{key:"item.variationImage",fn:function(ref){
                                                    var item = ref.item;
return [(item.variationImage)?_c('img',{staticStyle:{"border":"1px solid #eee","background-color":"#eee"},attrs:{"src":item.variationImage,"width":"50"}}):_vm._e()]}}],null,false,3421025260),model:{value:(_vm.vm.selectedAssignmentsByProduct),callback:function ($$v) {_vm.$set(_vm.vm, "selectedAssignmentsByProduct", $$v)},expression:"vm.selectedAssignmentsByProduct"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('div',{staticStyle:{"display":"grid","grid-template-columns":"auto 1fr"}},[_c('span',{staticStyle:{"grid-column":"1","margin":"20px"}},[_vm._v(_vm._s(("Performing color assignment on store " + (_vm.vm.current) + " of " + (_vm.vm.total))))]),_c('c-toolbar-btn',{staticStyle:{"justify-self":"end","width":"140px","margin":"20px","grid-column":"2"},attrs:{"icon":_vm.chipplyIcons.mdiArrowRight,"label":"Continue"},on:{"click":function($event){return _vm.vm.next()}}})],1)],1),_c('c-yes-no-dialog',{attrs:{"show":_vm.vm.dialogViewModel !== null,"viewModel":_vm.vm.dialogViewModel}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }