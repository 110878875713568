export default class SortProperty {
    public displayName = "";
    public propertyName = "";
    public isDescending = false;

    public constructor(propertyName?: string, isDescending?: boolean) {
        this.propertyName = propertyName ?? "";
        this.isDescending = isDescending ?? false;
    }
}
