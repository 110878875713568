import { __decorate } from "tslib";
//import chipplyIcons from "@/chipply/ImportIcons";
// import ICountryDto from "@/chipply/interface/ICountryDto";
// import IStateDto from "@/chipply/interface/IStateDto";
import { WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
let CAddressEditor = class CAddressEditor extends Vue {
    constructor() {
        super(...arguments);
        this.states = [];
        this.countries = [];
    }
    //public chipplyIcons = chipplyIcons;
    async created() {
        this.countries = await WebHelper.getJsonData("/api/List/Countries", false);
        this.states = await WebHelper.getJsonData("/api/List/States", false);
    }
    get computedStates() {
        if (this.address && this.address.country) {
            return this.states.filter((x) => x.country === this.address.country);
        }
        return this.states;
    }
    validateAddressField(value) {
        if (!this.isRequired) {
            return true;
        }
        if (value) {
            return true;
        }
        return "You must enter a value";
    }
    countryAutocompleteChanged(value) {
        if (!this.address) {
            return;
        }
        for (const item of this.countries) {
            if (item.alpha2 === value) {
                this.address.country = item.alpha3;
                return;
            }
        }
    }
    stateAutocompleteChanged(value) {
        if (!this.address) {
            return;
        }
        for (const item of this.computedStates) {
            if (item.name === value) {
                this.address.state = item.abbreviation;
                return;
            }
            if (item.abbreviation === value) {
                this.address.state = value;
                return;
            }
        }
    }
    validateZipField(value) {
        if (!this.isRequired && !this.requireZip) {
            return true;
        }
        if (value) {
            return true;
        }
        return "You must enter a value";
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean,
    })
], CAddressEditor.prototype, "requireZip", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean,
    })
], CAddressEditor.prototype, "hideName", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean,
    })
], CAddressEditor.prototype, "hideCompanyName", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean,
    })
], CAddressEditor.prototype, "checkout", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean,
    })
], CAddressEditor.prototype, "validationStatus", void 0);
CAddressEditor = __decorate([
    Component({
        props: {
            address: Object,
            disabled: Boolean,
            enableAutocomplete: Boolean,
            isRequired: Boolean,
            warning: String,
            checkout: Boolean,
        },
    })
], CAddressEditor);
export default CAddressEditor;
