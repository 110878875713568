enum UserLevel {
    Admin = 1,
    AdminSalesperson = 2,
    DealerAdmin = 3,
    DealerSalesperson = 4,
    OrganizationDirector = 5,
    OrganizationUser = 6,
}

export default UserLevel;
