







import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: {
        color: String,
        tooltip: String,
        disabled: Boolean,
    },
})
export default class CSaveButton extends Vue {
    public chipplyIcons = chipplyIcons;
    public color: string | undefined;

    protected computedColor() {
        if (this.color) {
            return this.color;
        }
        return "primary";
    }
}
