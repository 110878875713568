import { render, staticRenderFns } from "./CMoney.vue?vue&type=template&id=aa1a069c&scoped=true&"
import script from "./CMoney.vue?vue&type=script&lang=ts&"
export * from "./CMoney.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa1a069c",
  null
  
)

export default component.exports