import EventProductColorSizeViewModel from "@/chipply/products/event-product-color-size-view-model";
import EventProductColorViewModel from "@/chipply/products/event-product-color-view-model";
import EventProductSizeViewModel from "@/chipply/products/event-product-size-view-model";
import IEventProductColor from "@/chipply/products/i-event-product-color";
import IEventProductColorSizeDto from "@/chipply/products/i-event-product-color-size-dto";
import IEventProductColorSizeGridDto from "@/chipply/products/i-event-product-color-size-grid-dto";
import { Utils } from "chipply-common";

export default class EventProductColorSizeGridViewModel {
    public colors: EventProductColorViewModel[];
    public sizes: EventProductSizeViewModel[];
    public colorSizes: EventProductColorSizeViewModel[][];
    public productTitle: string;
    public usesFeedsBasedQty: boolean;
    public eventProductId: number;
    public hasFeedsInventory: boolean;
    public isFeedVendor: boolean;
    public allowUpcEntry: boolean;
    public feedsThreshold: number | null;
    public colorSizesByUpc: { [key: string]: EventProductColorSizeViewModel[] } = {};

    constructor(model: IEventProductColorSizeGridDto) {
        this.productTitle = model.productTitle;
        this.usesFeedsBasedQty = model.usesFeedsBasedQty;
        this.eventProductId = model.eventProductId;
        this.hasFeedsInventory = model.hasFeedsInventory;
        this.isFeedVendor = model.isFeedVendor;
        this.allowUpcEntry = model.allowUpcEntry;
        this.feedsThreshold = model.feedsThreshold;

        this.colors = [];
        const colorsById: { [key: number]: string } = {};
        for (const color of model.colors) {
            this.colors.push(new EventProductColorViewModel(color));
            colorsById[color.eventProductColorId] = color.colorName;
        }

        this.sizes = [];
        const sizesById: { [key: number]: string } = {};
        for (const size of model.sizes) {
            this.sizes.push(new EventProductSizeViewModel(size));
            sizesById[size.eventProductSizeId] = size.sizeName;
        }

        this.colorSizes = [];
        const existingUpcs: { [key: string]: EventProductColorSizeViewModel[] } = {};
        for (const colorSizeArray of model.colorSizes) {
            const colorSizes = [];
            for (const colorSize of colorSizeArray) {
                const colorSizeVM = new EventProductColorSizeViewModel(
                    colorSize,
                    sizesById,
                    colorsById,
                    this.feedsThreshold
                );
                colorSizes.push(colorSizeVM);
                if (colorSize.upc == null || !Utils.hasValue(colorSize.upc)) {
                    continue;
                }
                if (!(colorSize.upc in existingUpcs)) {
                    existingUpcs[colorSize.upc] = [];
                }
                existingUpcs[colorSize.upc].push(colorSizeVM);
            }
            this.colorSizes.push(colorSizes);
        }
        this.colorSizesByUpc = existingUpcs;
    }

    public toModel(): IEventProductColorSizeGridDto {
        const model = {
            allowUpcEntry: this.allowUpcEntry,
            colorSizes: [],
            colors: [],
            eventProductId: this.eventProductId,
            feedsThreshold: this.feedsThreshold,
            hasFeedsInventory: this.hasFeedsInventory,
            isFeedVendor: this.isFeedVendor,
            productTitle: this.productTitle,
            sizes: [],
            usesFeedsBasedQty: this.usesFeedsBasedQty,
        } as IEventProductColorSizeGridDto;

        for (const colorSizeRow of this.colorSizes) {
            const colorSizes = [];
            for (const colorSize of colorSizeRow) {
                colorSizes.push(colorSize.toModel());
            }
            model.colorSizes.push(colorSizes);
        }

        for (const size of this.sizes) {
            model.sizes.push(size.toModel());
        }

        for (const color of this.colors) {
            model.colors.push(color.toModel());
        }

        return model;
    }
}
