import IProcessOptionItem from "@/chipply/process/IProcessOptionItem";
import { AsyncInteractionViewModel } from "chipply-common";

export interface IItem {
    name: string;
}

export abstract class ListItemEditorViewModel extends AsyncInteractionViewModel {
    public isValid = false;
    public allowDelete = false;
    public items: IItem[] = [];
    public itemName = "Item";
    public itemNames = "Items";
    public quickItems: string | null = null;

    public addItem(value?: string) {
        const item = this.constructItem();
        item.name = value ?? "";
        this.items.push(item);
    }

    public abstract constructItem(): { name: string };

    public removeItem(option: IItem) {
        const optionIndex = this.items.indexOf(option);
        this.items.splice(optionIndex, 1);
    }

    public quickAddItems() {
        if (!this.quickItems) {
            return;
        }
        const entries = this.quickItems.split("\n");

        for (const entry of entries) {
            if (!entry) {
                continue;
            }
            this.addItem(entry);
        }

        this.quickItems = null;
    }
}
