import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import AutomatedMergeTemplateViewModel from "@/chipply/merge/AutomatedMergeTemplateViewModel";
import CustomerMergeTemplateViewModel from "@/chipply/merge/CustomerMergeTemplateViewModel";
import IDealerCustomerMergeTemplate from "@/chipply/merge/IDealerCustomerMergeTemplate";
import IStoreCustomerMergeTemplate from "@/chipply/merge/IStoreCustomerMergeTemplate";
import MergeFields from "@/chipply/merge/MergeFields";
import { ITextValue } from "chipply-common";
import IDealerEcertMergeTemplate from "@/chipply/merge/IDealerEcertMergeTemplate";

export default class DealerCustomerMergeTemplateViewModel extends CustomerMergeTemplateViewModel {
    public $typeHint = "DealerCustomerMergeTemplate";

    public created(dto: IDealerCustomerMergeTemplate) {
        this.setTemplate(dto);
    }

    public setTemplate(dto?: IDealerCustomerMergeTemplate) {
        super.setTemplate(dto);
        this.trigger = dto ? dto.trigger : EmailTemplateTriggerTypeEnum.StaticDate;
    }

    public toModel(): IDealerCustomerMergeTemplate {
        const baseModel = super.toModel();
        return {
            ...baseModel,
            $typeHint: this.$typeHint,
        };
    }

    public clone() {
        const clone = new DealerCustomerMergeTemplateViewModel();
        clone.setTemplate(this.toModel());
        return clone;
    }
}
