










































































































































































































import ProductColorSizeSelectorViewModel from "@/chipply/event/ProductColorSizeSelectorViewModel";
import TextHeading from "@/components/utility/TextHeading.vue";
import Validation from "@/validation";
import { AsyncInteractionResult, Utils } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        TextHeading,
    },
})
export default class ProductColorSizeSelector extends Vue {
    public Utils = Utils;
    public quantities!: number[];
    public isValid = true;

    @Prop({ type: Object })
    public vm!: ProductColorSizeSelectorViewModel;

    public $refs!: { form: any; colors: any };

    public Validation = Validation;

    public done(args: AsyncInteractionResult) {
        this.vm.done(args);
    }
}
