




































import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import CControlLabel from "@/components/ui/CControlLabel.vue";

@Component({
    components: {
        CControlLabel,
    },
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        maxLength: Number,
        mergeFields: Array,
        mergeLabel: String,
        value: String,
    },
})
export default class SmsMergeEditor extends Vue {
    public mergeLabel!: string;
    public name = "SmsMergeEditor";

    public value!: string;
    public editingValue = this.value;
    public maxLength!: number | null;
    public defaultMaxLength = this.maxLength || 280;

    public $refs!: {
        textarea: Vue;
    };

    @Watch("value")
    protected valueChanged() {
        this.editingValue = this.value;
    }

    protected updateValue() {
        this.$emit("change", this.editingValue);
    }

    protected mergeFieldSelected(mergeField: string) {
        const formattedMergeField = `[${mergeField}] `;
        const areaElement = this.$refs.textarea.$el.querySelector("textarea");
        if (!areaElement) {
            return;
        }

        const value = areaElement.value;
        const startSelectionIndex = areaElement.selectionStart;
        let newValue = value.substr(0, startSelectionIndex);
        newValue += " " + formattedMergeField;
        newValue += value.substr(startSelectionIndex);
        this.editingValue = newValue;
        this.updateValue();
        areaElement.setSelectionRange(this.value.length - 1, this.value.length - 1);
        areaElement.focus();
    }

    protected lessThanMaxLength() {
        return this.value.length <= this.defaultMaxLength;
    }
}
