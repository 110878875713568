



































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import { Utils } from "chipply-common";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CDate extends Vue {
    public chipplyIcons = chipplyIcons;
    public Utils = Utils;
    @Prop({
        type: String,
    })
    public value!: string | number;
    public inputValue: string | number | null = null;
    @Prop()
    public label!: string;
    public showMenu = false;
    @Prop()
    public rules!: any[];
    @Prop({ type: Boolean })
    public hideDetails!: boolean;
    @Prop()
    public disabled!: boolean;
    @Prop()
    public clearable!: boolean;

    public mounted() {
        this.onValueChanged();
    }

    @Watch("value")
    public onValueChanged() {
        this.inputValue = this.value;
    }

    @Watch("inputValue")
    public onInputValueChanged() {
        this.$emit("change", this.inputValue);
    }
}
