













import Component from "vue-class-component";
import Vue from "vue";
import { mask } from "vue-the-mask";

@Component({})
export default class CCard extends Vue {}
