import { ActionContext } from "vuex";
import { IRootState } from "@/store/RootStore";
import { getStoreAccessors } from "vuex-typescript";

type UserInfoContext = ActionContext<IUserInfoState, IRootState>;

export const userInfoStore = {
    namespaced: true,

    state: {
        dealerId: undefined,
        userId: undefined,
        userLevel: undefined,
        isPremium: undefined,
    },

    getters: {
        getUserId(state: IUserInfoState) {
            return state.userId;
        },

        getUserLevel(state: IUserInfoState) {
            return state.userLevel;
        },

        getDealerId(state: IUserInfoState) {
            return state.dealerId;
        },
        getIsPremium(state: IUserInfoState) {
            return state.isPremium;
        },
    },

    mutations: {
        setUserInfo(
            state: IUserInfoState,
            item: {
                dealerId: number;
                userId: number;
                isPremium: boolean;
                userLevel: number;
            }
        ) {
            state.dealerId = item.dealerId;
            state.userId = item.userId;
            state.isPremium = item.isPremium;
            state.userLevel = item.userLevel;
        },
    },

    actions: {
        initializeUserInfo(context: UserInfoContext) {
            const userInfoString = localStorage.getItem("userInfo");
            if (!userInfoString) {
                return;
            }

            const userInfo = JSON.parse(userInfoString);
            commitUserInfo(context, userInfo);
        },
    },
};

const { commit, read, dispatch } = getStoreAccessors<IUserInfoState, IRootState>("userInfo");

const mutations = userInfoStore.mutations;

export const commitUserInfo = commit(mutations.setUserInfo);

const getters = userInfoStore.getters;

export const getUserId = read(getters.getUserId);
export const getUserLevel = read(getters.getUserLevel);
export const getDealerId = read(getters.getDealerId);
export const getIsPremium = read(getters.getIsPremium);
export const initialize = dispatch(userInfoStore.actions.initializeUserInfo);
