import AutomatedMergeTemplateViewModel from "@/chipply/merge/AutomatedMergeTemplateViewModel";
import IMergeTemplateViewModel from "@/chipply/merge/IMergeTemplateViewModel";
import MergeFields from "@/chipply/merge/MergeFields";
import { MessageType } from "@/chipply/merge/MessageType";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";
import { ITextValue } from "chipply-common";

export default abstract class EcertMergeTemplateViewModel extends AutomatedMergeTemplateViewModel {
    public templateType = MergeTemplateType.ECertificate;
    public templateTypeDisplay = "E-Certificate";
    public constructor() {
        super();
        this.messageTypes = [MessageType.Email];
        this.isMessageSms = false;
    }
    public get htmlBodyMergeFields(): Array<ITextValue<string>> {
        return MergeFields.getEcertHTMLBodyMergeFields();
    }

    public get smsMergeFields(): Array<ITextValue<string>> {
        return MergeFields.getEcertMergeFields();
    }

    public get htmlSubjectMergeFields(): Array<ITextValue<string>> {
        return MergeFields.getEcertMergeFields();
    }

    public abstract clone(): IMergeTemplateViewModel;
}
