









import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        errorMessage: String,
        value: Boolean,
    },
})
export default class ErrorReporter extends Vue {
    public name = "ErrorReporter";
    public errorMessage!: string;
    public value!: boolean;
    public opened!: boolean;

    public data() {
        return {
            opened: false,
        };
    }

    @Watch("value")
    protected onValueChanged(nv: boolean) {
        this.opened = nv;
    }

    @Watch("opened")
    protected snackbarOpenedChanged(nv: boolean) {
        this.$emit("change", nv);
    }
}
