import IDepositSummary from "@/chipply/deposits/IDepositSummary";
import IDepositSummaryResult from "@/chipply/deposits/IDepositSummaryResult";
import { DateTime } from "luxon";

export default class DepositSummaryResult implements IDepositSummaryResult {
    public amountCollected!: number;
    public amountPaid!: number;
    public chipplyFees!: number;
    public depositDate: string | undefined;
    public depositId!: number;
    public endingPeriod!: string;
    public processingFees!: number;
    public startingPeriod!: string;
    public statementDate!: string;
    public statementNumber!: number;
    public statusName!: string;

    public constructor(result: IDepositSummaryResult) {
        Object.assign(this, result);
        this.statementDate = DateTime.fromISO(result.depositDate!).toLocaleString(DateTime.DATE_SHORT);
        this.startingPeriod = DateTime.fromISO(result.startingPeriod!).toLocaleString(DateTime.DATE_SHORT);
        this.endingPeriod = DateTime.fromISO(result.endingPeriod!).toLocaleString(DateTime.DATE_SHORT);

        if (!this.depositDate) {
            this.depositDate = "N/A";
        } else {
            this.depositDate = DateTime.fromISO(result.depositDate!).toLocaleString(DateTime.DATE_SHORT);
        }
    }
}
