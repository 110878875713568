


































































































































































































































































































































































































































import IEcomOrderResult from "@/chipply/ecom-orders/IEcomOrderResult";
import ITextValue from "@/chipply/interface/ITextValueDisabled";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import SelectorMixin from "@/components/SelectorMixin";
import CAddButton from "@/components/ui/CAddButton.vue";
import Component, { mixins } from "vue-class-component";
import Vue from "vue";
import ISpreadsheetProductDto from "@/chipply/interface/i-spreadsheet-product-dto";
import { Prop, Watch } from "vue-property-decorator";
import IGetProductSpreadsheetResults from "@/chipply/interface/i-get-product-spreadsheet-results";
import SpreadsheetProductViewModel from "@/chipply/view-model/SpreadsheetProductViewModel";
import EventProductColorSizeGrid from "@/components/products/EventProductColorSizeGrid.vue";
import ITreeItem from "@/chipply/interface/i-tree-item";

import TreeSelector from "../input/TreeSelector.vue";
import * as environmentStore from "@/store/EnvironmentStore";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import TextHeading from "@/components/utility/TextHeading.vue";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import FundraisingSelection from "@/components/products/FundraisingSelection.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import CMoney from "@/components/ui/CMoney.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import { TaxType, Utils, WebHelper } from "chipply-common";
import ProductMoreInfo from "@/components/products/ProductMoreInfo.vue";

interface ISpreadsheetFilters {
    vendorId: number | null;
    filter: string | null;
    categoryIds: number[];
    processId: number | null;
    taxType: TaxType | null;
    onlyOrderedProducts: boolean;
    exactMatch: boolean;
}

@Component({
    components: {
        CAddButton,
        CEditButton,
        CMoney,
        EventProductColorSizeGrid,
        FundraisingSelection,
        TaxTypeSelection,
        TextHeading,
        TreeSelector,
        ProductMoreInfo,
    },
    props: {
        eventId: Number,
        initialSelectedIds: Array,
        disabledProductIds: Array,
        maxHeight: Number,
    },
})
export default class ProductsSelector extends mixins(ScrollableContentMixin, SelectorMixin) {
    get pages() {
        if (this.pagination.itemsPerPage == null || this.totalItems == null) {
            return 1;
        }

        const pages = Math.ceil(this.totalItems / this.pagination.itemsPerPage);
        return pages > 0 ? pages : 1;
    }

    @Prop({
        default: false,
    })
    public onlyOrderedProducts!: boolean;

    public TaxType = TaxType;
    public Utils = Utils;

    public icons = chipplyIcons;
    public chipplyIcons = chipplyIcons;

    public selectedSortBy = 0;
    public selectedVendor: number | null = null;
    public selectedProcessId: number | null = null;
    public selectedCategoryIds: number[] = [];
    public selectedTaxType: TaxType | null = null;

    public showAdditionalFiltersDialog = false;

    public items: SpreadsheetProductViewModel[] = [];
    public eventId!: number;
    public hasCategories = false;
    public headers: any[] = [];

    public selectionTaxValid = true;

    public vendors: Array<{
        vendorName: string;
        vendorId: number;
        isFromFeed: boolean;
    }> = [];
    public categories: ITreeItem[] = [];
    public itemCategories: ITreeItem[] = [];
    public pagination: IVuetifyTableOptions = {
        itemsPerPage: 50,
        page: 1,
        sortDesc: [],
        sortBy: [],
    };
    public previewPath = "";
    public totalItems: number | null = null;
    public loading = true;
    public loadingMessage: string | null = null;

    public filter: string | null = null;
    public vendor: any[] = [];
    public suppressPageChangeCallback!: boolean;
    public processes: Array<ITextValue<number>> = [];
    public errorMessage = "";

    public maxHeight!: number | null;
    public height: number | null = this.maxHeight;
    public selected: SpreadsheetProductViewModel[] = [];
    public tableHeight: string | null = null;
    public msrpPricingTier!: number;
    public pricingTierResetExistingProducts!: boolean;
    public exactMatch = false;

    @Prop({
        default: true,
    })
    public tableMode!: boolean;

    @Prop({
        default: false,
    })
    public canChangeView!: boolean;

    public dialog = false;
    public prodId: number | null = null;

    public lastAcceptedMsrpPricingTier!: number | undefined;

    public taxTypes = [
        { text: "All", value: null },
        { text: "Auto", value: 0 },
        { text: "Flat Percentage", value: 1 },
        { text: "Tax Exempt", value: 2 },
    ];

    public sortBy = [
        { text: "Sort Order", value: 0 },
        { text: "Vendor Name", value: 1 },
        { text: "Process Name", value: 2 },
        { text: "Product Name", value: 3 },
        { text: "Price Low to High", value: 4 },
        { text: "Price High to Low", value: 5 },
    ];

    public $refs!: {
        searchPanel: HTMLDivElement;
        deleteProductsCancel: Vue;
        deleteProductsAccept: Vue;
        zeroProductPriceAcknowledge: Vue;
        zeroProductPriceCancel: Vue;
    };

    protected hasMobileHeader = false;

    public getItemIdPath(): string {
        return "eventProductId";
    }

    public async created() {
        await environmentStore.initialize(this.$store);
        this.previewPath = environmentStore.getPreviewWebsitePath(this.$store);
        this.onEventIdChanged();
    }

    public async applyFilters() {
        this.loading = true;
        try {
            this.loadingMessage = this.getPageLoadingText();
            await this.refreshSpreadsheetData(
                this.pagination.page,
                this.pagination.itemsPerPage,
                this.buildFilters(),
                this.selectedSortBy
            );
            this.pagination.page = 1;
        } catch (e) {
            this.errorMessage = e.message;
        }
        this.doneLoading();
    }

    public showInfo(productId: number) {
        this.prodId = productId;
        this.dialog = true;
    }

    protected getVariableHeadroom(): number {
        const tableFooterSize = 50;
        return tableFooterSize + (this.$refs.searchPanel || { clientHeight: 0 }).clientHeight + 100;
    }

    protected doneLoading() {
        this.$nextTick(() => {
            // Need to do this in nextTick for selected <-> selectedProductIds synchronicity
            this.loading = false;
        });
    }

    protected getProductDtos() {
        const products = [];
        for (const item of this.items) {
            products.push(item.toDto());
        }

        return products;
    }

    protected buildFilters(): ISpreadsheetFilters {
        return {
            categoryIds: this.selectedCategoryIds,
            filter: this.filter,
            processId: this.selectedProcessId,
            taxType: this.selectedTaxType,
            vendorId: this.selectedVendor,
            onlyOrderedProducts: this.onlyOrderedProducts,
            exactMatch: this.exactMatch,
        };
    }

    protected async clear() {
        this.selectedSortBy = 0;
        this.selectedVendor = null;
        this.selectedProcessId = null;
        this.selectedCategoryIds = [];
        this.filter = null;
        this.selectedTaxType = null;
        await this.applyFilters();
    }

    protected getPageLoadingText() {
        return (
            `Loading Products ${(this.pagination.page - 1) * this.pagination.itemsPerPage + 1} to ` +
            this.pagination.page * this.pagination.itemsPerPage
        );
    }

    @Watch("pagination.page")
    protected async onPageChanged() {
        if (this.suppressPageChangeCallback) {
            return;
        }

        this.loading = true;
        try {
            this.loadingMessage = this.getPageLoadingText();
            await this.refreshSpreadsheetData(
                this.pagination.page,
                this.pagination.itemsPerPage,
                this.buildFilters(),
                this.selectedSortBy
            );
        } catch (e) {
            this.errorMessage = e.message;
        }
        this.doneLoading();
    }

    @Watch("eventId")
    protected async onEventIdChanged() {
        this.loading = true;
        this.loadingMessage = this.getPageLoadingText();

        await this.refreshSpreadsheetData(1, this.pagination.itemsPerPage, this.buildFilters(), 0);
        this.doneLoading();
    }

    protected async refreshSpreadsheetData(
        pageNumber: number,
        pageSize: number,
        filters: ISpreadsheetFilters,
        sortBy: number
    ) {
        const baseUrl = `/api/ProductSpreadsheet/${this.eventId}/${pageNumber}/${pageSize}/false`;

        let queryString = `?sortby=${sortBy}`;

        if (filters.filter) {
            queryString += "&filter=" + encodeURI(filters.filter);
        }
        if (filters.vendorId) {
            queryString += "&vendorid=" + filters.vendorId;
        }
        if (filters.processId) {
            queryString += "&processids=" + filters.processId;
        }
        for (const catId of filters.categoryIds) {
            queryString += "&categoryids=" + catId;
        }
        if (!Number.isNaN(Number(filters.taxType))) {
            queryString += "&taxtype=" + filters.taxType;
        }

        if (filters.onlyOrderedProducts) {
            queryString += "&op=true";
        }

        if (filters.exactMatch) {
            queryString += "&exactMatch=" + filters.exactMatch;
        }

        const results = (await WebHelper.getJsonData(baseUrl + queryString, true)) as IGetProductSpreadsheetResults;
        this.totalItems = results.totalProducts;
        this.vendors = results.vendors;
        this.hasCategories = results.categories.length > 0;
        if (this.hasCategories) {
            this.categories = results.categories;
            const pickerCategories: ITreeItem[] = [];
            pickerCategories.push(...results.categories);
            pickerCategories.splice(0, 1);
            this.itemCategories = pickerCategories;
        }
        this.processes = results.processes;

        this.msrpPricingTier = results.pricingTier;

        this.buildHeaders();

        const tempArray = [];
        for (const product of results.products) {
            if (product.productName !== "") {
                //Get rid of blank products
                const vm = new SpreadsheetProductViewModel(
                    product,
                    this.categories,
                    results.processCosts,
                    results.processes
                );
                tempArray.push(vm);
            }
        }

        this.items = tempArray;
        this.resetSelectedItems();
    }

    protected hasHeaderSlot() {
        return !!this.$slots.heading;
    }

    protected buildHeaders() {
        this.headers = [
            { sortable: false, text: " ", value: "image" },
            {
                class: ["checkboxTableHeader", "noPadding"],
                sortable: false,
                text: "Active",
                value: "active",
            },
            {
                class: ["sortOrderTableHeader"],
                sortable: false,
                text: "Sort",
                width: "50px",
                value: "sort",
            },
            {
                class: ["noPadding"],
                sortable: false,
                text: "Product Name",
                value: "productname",
            },
            {
                class: "vendorStyleTableHeader",
                sortable: false,
                text: "Vendor / Style",
                value: "vendorstyle",
            },
            { sortable: false, text: "Process", value: "process" },
        ];

        if (this.hasCategories) {
            this.headers.push({
                sortable: false,
                text: "Categories",
                class: "categoryHeader",
                width: "200px",
                value: "categories",
            });
        }

        this.headers.push(
            ...[
                {
                    align: "center",
                    class: ["moneyTableHeader", "noPadding"],
                    sortable: false,
                    text: "Sell Price",
                    width: "50px",
                    value: "sellprice",
                },
            ]
        );
    }
}
