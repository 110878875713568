import { PageViewModel } from "@/chipply/view-model/PageViewModel";

export class ReportPageViewModel extends PageViewModel {
    public pdfUrl = "";
    public isSmallScreen = false;

    protected save(): Promise<boolean> {
        return Promise.resolve(false);
    }
}
