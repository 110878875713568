enum NavigationArea {
    Stores = 0,
    MyAccount = 1,
    Organizations = 2,
    Requestor = 3,
    OrderManager = 4,
    Reports = 5,
    SuperadminMyAccount = 6,
    SuperadminDealers = 7,
    SuperadminVendors = 8,
    SuperadminChipplyProducts = 9,
    SuperadminAccounting = 10,
    StoreDetails = 11,
    DealerDetails = 12,
    SuperadminDealerDetails = 13,
    OrganizationDetails = 14,
    OrderDetails = 15,
    SuperadminFeeds = 16,
    Purchasing = 17,
}

export default NavigationArea;
