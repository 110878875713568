











































































import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import chipplyIcons from "@/chipply/ImportIcons";
import { Prop, Watch } from "vue-property-decorator";
@Component({})
export default class ResponsiveDetailView extends Vue {
    @Prop({
        type: Boolean,
    })
    public isViewingDetails!: boolean;
}
