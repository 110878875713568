import { ICustomerEmailItemViewModel } from "./ICustomerEmailItemViewModel";
import DepositsEditCardViewModel from "@/chipply/deposits/DepositsEditCardViewModel";
import { ListPageViewModel } from "@/chipply/view-model/ListPageViewModel";
import { SimpleAsyncInteractionViewModel, SimpleAsyncInteractionWithDataViewModel, WebHelper } from "chipply-common";
import { ITextValue } from "chipply-common";
import { IListViewModel } from "@/chipply/view-model/IListViewModel";
import { DepositStatementStatus } from "@/chipply/deposits/DepositStatementStatus";
import PageState from "@/chipply/PageState";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import DepositsExportCardViewModel from "@/chipply/deposits/DepositsExportCardViewModel";
import { DateTime } from "luxon";
import { CustomerEmailItemViewModel } from "./CustomerEmailItemViewModel";
import { ICustomerEmailDto } from "./ICustomerEmailDto";
import _ from "lodash";
import SortProperty from "../data-access/SortProperty";
import { EventBus } from "../EventBus";

export default class CustomerEmailsViewModel extends ListPageViewModel {
    public dataTableHeaders = [
        { text: "Send Date", value: "sendDate", width: "200px" },
        { text: "Order Number", value: "ecomOrderId" },
        { text: "Subject", value: "emailSubject", width: "30%" },
        { text: "To", value: "emailTo" },
        { text: "Bcc", value: "emailBcc" },
        { text: "", value: "viewDetails", sortable: false },
    ];

    private _isEmailsFromSingleOrder = false;
    public get isEmailsFromSingleOrder() {
        return this._isEmailsFromSingleOrder;
    }
    public set isEmailsFromSingleOrder(value: boolean) {
        if (this._isEmailsFromSingleOrder != value) {
            this._isEmailsFromSingleOrder = value;
            if (this._isEmailsFromSingleOrder == true) {
                const index = this.dataTableHeaders.findIndex((x) => x.value == "ecomOrderId");
                if (index >= 0) {
                    this.dataTableHeaders.splice(index, 1);
                }
            }
        }
    }

    public dataTableItemKey = "id";
    public items: ICustomerEmailItemViewModel[] = [];
    public get pagination() {
        return this._pagination;
    }
    public set pagination(value: IVuetifyTableOptions) {
        if (!this.isPaginationEquals(this._pagination, value)) {
            this._pagination = value;
            this.refreshData();
        }
    }
    private _pagination: IVuetifyTableOptions = {
        itemsPerPage: 100,
        page: 1,
        sortDesc: [true],
        sortBy: ["sendDate"],
    };
    public pageState!: PageState;

    public emailToView: string | null = null;
    public storeId?: number;
    public ecomOrderId?: number;

    public emailDetailsViewModel: SimpleAsyncInteractionWithDataViewModel<ICustomerEmailItemViewModel> | null = null;

    public constructor() {
        super();
        this.pageState = new PageState(this._pagination);
    }

    public async refreshData(): Promise<void> {
        await this.list(true);
    }

    public getPageState() {
        return {
            description: "CustomerEmails",
            pagination: this.pagination,
            url: location.pathname + "?readstate=1",
        };
    }

    public getPageStateUrl(pageUrl: string) {
        const serializedValue = JSON.stringify(this.getPageState());

        const initialParameterJoin = pageUrl.indexOf("?") === -1 ? "?" : "&";
        const pageStateUrl = pageUrl + initialParameterJoin + "state=" + encodeURIComponent(serializedValue);

        return pageStateUrl;
    }

    public async list(isNewSearch: boolean): Promise<any> {
        if (!((this.ecomOrderId && this.ecomOrderId > 0) || (this.storeId && this.storeId > 0))) {
            console.log("Both ecomOrderId and storeId are invalid. Will not query server.");
            return;
        }

        if (this.dataTableLoading) {
            return;
        }

        if (isNewSearch) {
            this.items = [];
            this.selectedItems = [];
            this.pageState.reset();
        }

        const sortProperties = [];
        for (let i = 0; i < this._pagination.sortBy.length; i++) {
            const sortBy = this._pagination.sortBy[i];
            const sortDesc = this._pagination.sortDesc[i];
            const sortProperty = new SortProperty(sortBy, sortDesc);
            sortProperties.push(sortProperty);
        }

        const args = {
            storeID: this.storeId,
            ecomOrderId: this.ecomOrderId,
            pageState: this.pageState,
            sortProperties,
        };

        try {
            if (this.pageState.wasLastPageSelected) {
                return;
            }

            this.dataTableLoading = true;

            this.statusMessage = "Loading...";

            const serverResults = await WebHelper.postJsonData(`/api/Event/Email/CustomerEmails`, args);

            const results = await JSON.parse(serverResults);

            this.pageState.next(results.customerEmails.length);

            const items = [];
            for (const dto of results.customerEmails) {
                const item = new CustomerEmailItemViewModel(dto as ICustomerEmailDto);
                items.push(item);
            }
            this.items.push(...items);

            this.serverItemsLength = results.totalRows;
        } finally {
            this.dataTableLoading = false;
            this.statusMessage = null;
        }
    }

    public async viewEmailDetails(item: ICustomerEmailItemViewModel) {
        const results = await WebHelper.getJsonData(`/api/Event/Email/CustomerEmailBody/${item.storeID}/${item.id}`);

        this.emailDetailsViewModel = new SimpleAsyncInteractionWithDataViewModel();

        this.emailDetailsViewModel.headerText =
            "Email Details - Order: " + (parseInt(item.ecomOrderId) > 0 ? item.ecomOrderId : "N/A");
        this.emailDetailsViewModel.html = results.body;
        this.emailDetailsViewModel.data = item;
        EventBus.$emit("customer-emails-view-details");

        await this.emailDetailsViewModel.interact();
        this.emailDetailsViewModel = null;
    }
}
