import Vue from "vue";
import Component from "vue-class-component";
import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import { MergeTemplateRecipientType } from "@/chipply/merge/MergeTemplateRecipientType";
import IMergeEmailRecipient from "@/chipply/merge/IMergeEmailRecipient";
import IMergeTemplateDto from "@/chipply/merge/IMergeTemplateDto";
import IEmailTemplatesDto from "@/chipply/emails/IEmailTemplatesDto";
import UserLevel from "@/chipply/UserLevel";
import * as userInfoStore from "@/store/UserInfoStore";
import userLevel from "@/chipply/UserLevel";
import { WebHelper } from "chipply-common";

@Component
export default class UserInfoMixin extends Vue {
    public userInfo: {
        userLevel: UserLevel | null;
        userId: number;
        dealerId: number;
        isPremium: boolean;
    } = {
        userLevel: null,
        userId: 0,
        dealerId: 0,
        isPremium: false,
    };
    public needsBootstrap = false;
    public isUserInfoCreated = false;
    public userInfoCreated: Promise<void> | null = null;
    public async created() {
        this.userInfoCreated = new Promise(async (resolve) => {
            await this.bootstrap();
            await userInfoStore.initialize(this.$store);
            this.userInfo.userLevel = userInfoStore.getUserLevel(this.$store);
            this.userInfo.userId = userInfoStore.getUserId(this.$store);
            this.userInfo.dealerId = userInfoStore.getDealerId(this.$store);
            this.userInfo.isPremium = userInfoStore.getIsPremium(this.$store);
            this.isUserInfoCreated = true;
            resolve();
        });
    }
    protected async bootstrap() {
        if (!this.needsBootstrap) {
            return;
        }
        const bootstrap = await WebHelper.getJsonData("/api/Login/BootstrapUser");
        localStorage.setItem("userInfo", JSON.stringify(bootstrap.userData));
        localStorage.setItem("envInfo", JSON.stringify(bootstrap.environmentInfo));
    }
}
