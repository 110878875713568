






































































import chipplyIcons from "@/chipply/ImportIcons";
import { ListItemEditorViewModel } from "@/chipply/view-model/ListItemEditorViewModel";
import { DragDropHelper } from "@/components/DragDropHelper";
import FormValidationMixin from "@/components/FormValidationMixin";
import Component, { mixins } from "vue-class-component";
import Vue from "vue";
import IProcessOptionItem from "@/chipply/process/IProcessOptionItem";
import ProcessOptionItem from "@/chipply/process/ProcessOptionItem";
import CMoney from "@/components/ui/CMoney.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import Validation from "@/validation";
import { Prop } from "vue-property-decorator";
@Component({
    components: { CDeleteButton, CMoney, CAddButton, CControlLabel, TextHeading },
})
export default class CListItemEditor extends mixins(FormValidationMixin) {
    public Validation = Validation;
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: Object,
    })
    public vm!: ListItemEditorViewModel | null;

    public $refs!: {
        optionsList: Vue;
        form: any;
    };

    public mounted() {
        this.$refs.form.validate();
        DragDropHelper.initializeDragAndDrop(this.$refs.optionsList, this.vm, "items", this);
    }

    protected addItem(value?: string) {
        this.vm!.addItem(value);

        this.$nextTick(() => {
            this.$refs.form.validate();
            // Hacky but it works...
            const table = this.$refs.optionsList.$el as HTMLDivElement;
            (table.firstChild! as HTMLDivElement).scrollTo(0, table.scrollHeight + table.offsetHeight);
        });
    }
}
