import AutomatedMergeTemplateViewModel from "@/chipply/merge/AutomatedMergeTemplateViewModel";
import IMergeTemplateViewModel from "@/chipply/merge/IMergeTemplateViewModel";
import MergeFields from "@/chipply/merge/MergeFields";
import { ITextValue } from "chipply-common";
import IAutomatedMergeTemplate from "@/chipply/merge/IAutomatedMergeTemplate";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";

export default abstract class CustomerMergeTemplateViewModel extends AutomatedMergeTemplateViewModel {
    public supportsSms = true;
    public templateType = MergeTemplateType.MessageCustomers;
    public templateTypeDisplay = "Message Customers";

    public get htmlBodyMergeFields(): Array<ITextValue<string>> {
        return MergeFields.getCustomerMergeFields();
    }

    public get smsMergeFields(): Array<ITextValue<string>> {
        return MergeFields.getSmsCustomerMergeFields();
    }

    public get htmlSubjectMergeFields(): Array<ITextValue<string>> {
        return MergeFields.getCustomerSubjectMergeFields();
    }

    public abstract clone(): IMergeTemplateViewModel;
}
