import { IProductColorAssignment } from "@/chipply/interface/i-product-color-assignment";
import { ViewModel } from "chipply-common";

export class ProductColorAssignmentViewModel extends ViewModel {
    public artName = "";
    public artType = "";
    public colorName = "";
    public eventArtworkId = 0;
    public eventArtworkVariationId = 0;
    public isChanged = false;
    public isValid = true;
    public id = 0;
    public productName = "";
    public variationImage = "";
    public productImage = "";
    public hexCode1 = "";
    public vendorName = "";
    public productStyle = "";

    public isEditing = false;
    public isManualAssignment = false;

    public originalValues: IProductColorAssignment;

    public constructor(model: IProductColorAssignment) {
        super();
        this.artName = model.artName;
        this.artType = model.artType;
        this.colorName = model.colorName;
        this.eventArtworkId = model.eventArtworkId;
        this.eventArtworkVariationId = model.eventArtworkVariationId;
        this.productName = model.productName;
        this.variationImage = model.variationImage;
        this.productImage = model.productImage;
        this.hexCode1 = model.hexCode1;
        this.vendorName = model.vendorName;
        this.productStyle = model.productStyle;
        this.isManualAssignment = model.isManualAssignment;
        this.originalValues = this.toModel();
    }

    toModel(): IProductColorAssignment {
        return {
            artName: this.artName,
            artType: this.artType,
            colorName: this.colorName,
            eventArtworkId: this.eventArtworkId,
            productName: this.productName,
            variationImage: this.variationImage,
            productImage: this.productImage,
            hexCode1: this.hexCode1,
            vendorName: this.vendorName,
            productStyle: this.productStyle,
            isChanged: this.isChanged,
            isManualAssignment: this.isManualAssignment,
            eventArtworkVariationId: this.eventArtworkVariationId,
            originalColorName: this.originalValues?.colorName ?? this.colorName,
        };
    }
}
