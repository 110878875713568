export default class PurchasingConstants {
    public static readonly artworkPurchaseType = "Artwork";
    public static readonly autoPurchaseType = "Auto";
    public static readonly manualPurchaseType = "Manual";
    public static readonly manualArtworkPurchaseType = "ManualArtwork";
    public static readonly pulledPurchaseType = "Pulled";
    public static readonly checkPurchaseType = "Check";
}

export type PurchasingType = "Auto" | "Artwork" | "Manual" | "Pulled" | "Check" | "ManualArtwork";
