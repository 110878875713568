import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import VendorPurchaseOrderHeader from "./VendorPurchaseOrderHeader";

export default class ArtworkPurchaseOrder
    extends VendorPurchaseOrderHeader
    implements IVendorPurchaseOrder<IArtworkPurchasingLineItem>
{
    public lines: IArtworkPurchasingLineItem[] = [];
}
