import { IOrderItemOptionListItem, Utils } from "chipply-common";

export default class OrderItemOptionListItemViewModel {
    public optionName!: string;
    public price!: number;
    public optionId!: number;
    public optionDisplay: string;

    public constructor(val: IOrderItemOptionListItem) {
        Object.assign(this, val);
        this.optionDisplay =
            this.price === 0
                ? this.optionName
                : `${this.optionName} (+${Utils.getCurrencyValue(this.price, false, true)})`;
    }

    public toModel(): IOrderItemOptionListItem {
        return {
            optionId: this.optionId,
            optionName: this.optionName,
            price: this.price,
        };
    }
}
