var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('list-layout',{ref:"listlayout",attrs:{"view-model":_vm.vm,"is-loading":_vm.vm.dataTableLoading,"status-message":_vm.vm.statusMessage,"error-message":_vm.vm.errorMessage},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._t("customer-emails-heading")]},proxy:true},{key:"item.sendDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sendDate)+" ")]}},{key:"item.ecomOrderId",fn:function(ref){
var item = ref.item;
return [(!_vm.vm.isEmailsFromSingleOrder)?_c('td',[(!(item.ecomOrderId > 0))?_c('span',[_vm._v("N/A")]):_vm._e(),(item.ecomOrderId > 0)?_c('a',{attrs:{"target":"_blank","href":'/ng/edit-order.html?oid=' + item.ecomOrderId + '&v=' + Math.floor(Math.random() * 100000)}},[_vm._v(" "+_vm._s(item.ecomOrderId)+" ")]):_vm._e()]):_vm._e()]}},{key:"item.emailSubject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailSubject)+" ")]}},{key:"item.emailTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailTo)+" ")]}},{key:"item.emailBcc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailBcc)+" ")]}},{key:"item.viewDetails",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","outlined":"","title":"View Details"},on:{"click":function($event){$event.stopPropagation();return _vm.vm.viewEmailDetails(item)}}},[_c('v-icon',{attrs:{"dark":"","left":"","medium":""}},[_vm._v(_vm._s(_vm.chipplyIcons.mdiEmailSend))]),_vm._v(" Details ")],1)]}}],null,true)}),_c('v-dialog',{staticStyle:{"overflow":"hidden"},attrs:{"max-width":"600px","height":"400px"},model:{value:(_vm.hasDetailsViewModel),callback:function ($$v) {_vm.hasDetailsViewModel=$$v},expression:"hasDetailsViewModel"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.vm.emailDetailsViewModel),expression:"vm.emailDetailsViewModel"}]},[_c('v-card-title',[_vm._v(_vm._s(_vm.vm.emailDetailsViewModel && _vm.vm.emailDetailsViewModel.headerText))]),_c('v-card-text',[_c('v-divider'),_c('br'),_vm._v("From: "+_vm._s(_vm.vm.emailDetailsViewModel && _vm.vm.emailDetailsViewModel.data.emailFrom)+" "),_c('br'),_vm._v(" Sent: "+_vm._s(_vm.vm.emailDetailsViewModel && _vm.vm.emailDetailsViewModel.data.sendDate)+" "),_c('br'),_vm._v("To: "+_vm._s(_vm.vm.emailDetailsViewModel && _vm.vm.emailDetailsViewModel.data.emailTo)+" "),_c('br'),_vm._v("Subject: "+_vm._s(_vm.vm.emailDetailsViewModel && _vm.vm.emailDetailsViewModel.data.emailSubject)+" "),_c('v-divider'),_c('br'),_c('div',{staticStyle:{"overflow":"hidden"}},[_c('iframe',{ref:"iframeRef",staticStyle:{"border-style":"none","width":"100%","height":"350px"},attrs:{"frameborder":"0","border":"0","cellspacing":"0"}})])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.vm.emailDetailsViewModel && _vm.vm.emailDetailsViewModel.done('accept')}}},[_vm._v("OK")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }