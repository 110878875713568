





























import Vue from "vue";
import Component from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import { Watch } from "vue-property-decorator";

@Component({
    components: {
        TextHeading,
    },
    props: {
        dialogTitle: String,
        eventName: String,
        eventType: String,
    },
})
export default class StoreCreationOptions extends Vue {
    public name = "StoreCreationOptions";
    public selectedStoreName!: string | undefined;
    public selectedEventType!: string | undefined;
    public eventName!: string | undefined;
    public eventType!: string | undefined;

    public optionsAccepted(accepted: boolean) {
        this.$emit("options-accepted", {
            accepted,
            eventType: this.selectedEventType,
            storeName: this.selectedStoreName,
        });
    }

    public data() {
        return {
            selectedEventType: this.eventType,
            selectedStoreName: this.eventName,
        };
    }

    @Watch("eventType")
    protected onEventTypeChanged() {
        this.selectedEventType = this.eventType;
    }

    @Watch("eventName")
    protected onEventNameChanged() {
        this.selectedStoreName = this.eventName;
    }
}
