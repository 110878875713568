import _, { set } from "lodash";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import VendorPurchaseOrderArgs from "./VendorPurchaseOrderArgs";
import PurchasingConstants from "./PurchasingConstants";
import { SimpleAsyncInteractionViewModel, Utils, TextUtils } from "chipply-common";
import { WebHelper } from "chipply-common";
import { PurchasingType } from "./PurchasingConstants";
import IPurchasingParentViewModel from "./IPurchasingParentViewModel";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import { Serializer, typeDependencies } from "chipply-common";
import IPurchasingViewModel from "./IPurchasingViewModel";
import BuildArtworkPurchaseOrderResults from "./BuildArtworkPurchaseOrderResults";
import ArtworkPurchaseOrder from "./ArtworkPurchaseOrder";
import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import CheckArtworkAvailabilityResults from "./CheckArtworkAvailabilityResults";
import SubmitArtworkPurchaseOrderResults from "./SubmitArtworkPurchaseOrderResults";
import CheckArtworkAvailabilityArgs from "./CheckArtworkAvailabilityArgs";
import ArtworkPurchaseOrderArgs from "./ArtworkPurchaseOrderArgs";
import SubmitArtworkPurchaseOrderArgs from "./SubmitArtworkPurchaseOrderArgs";
import ListArtworkPurchaseOrderArgs from "./ListArtworkPurchaseOrderArgs";
import AbstractPurchaseOrderViewModel from "./AbstractPurchaseOrderViewModel";
import StahlsPurchaseOrderSettings from "./Stahls/StahlsPurchaseOrderSettings";
import StahlsSettings from "./Stahls/StahlsSettings";
@typeDependencies({
    types: {
        StahlsPurchaseOrderSettings,
    },
})
export default class ArtworkPurchaseOrderViewModel
    extends AbstractPurchaseOrderViewModel
    implements IPurchasingViewModel
{
    public groupBy = "saleOrder";
    public lines: IArtworkPurchasingLineItem[] = [];
    public selectedLines: IArtworkPurchasingLineItem[] = [];
    public isEmailWindowVisible = false;
    public isViewingDetails = false;
    public currentItem: IArtworkPurchasingLineItem | null = null;
    public printOrEmailDialogViewModel: SimpleAsyncInteractionViewModel | null = null;

    public headers = [
        { text: "", value: "imageUrl" },
        { text: "Vendor", value: "vendorName" },
        { text: "Artwork Name", value: "artworkName" },
        { text: "Variation Name", value: "variationName" },
        { text: "Variation ID", value: "externalId" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
    ];

    public stahlsShipCarriers = [
        { carrier: "UPS", description: "UPS" },
        { carrier: "FedEx", description: "Fedex" },
    ];

    public stahlsShipServices = [
        { service: "UPSGR", description: "UPS Ground" },
        { service: "UPS2D", description: "UPS 2nd Day Air" },
        { service: "UPSND", description: "UPS Next Day" },
        { service: "UPSNDPM", description: "UPS Next Day Air Saver" },
        { service: "FEDEXNDAM", description: "FedEx Priority Overnight" },
        { service: "FEDEX2D", description: "FedEx 2 Day" },
        { service: "FEDEXND", description: "FedEx Standard Overnight" },
        { service: "FEDEXGR", description: "FedEx Ground" },
    ];

    public computedStahlsShipServices(): { service: string; description: string }[] {
        if (!(this.vendorSettings?.vendorName === "stahls")) {
            return [];
        }
        const settings = <StahlsSettings>this.vendorSettings;
        if (!settings.carrier) {
            return [];
        }
        return this.stahlsShipServices.filter((x) =>
            x.service.toLocaleLowerCase().includes(settings.carrier.toLowerCase())
        );
    }

    protected processHeader = { text: "Process", value: "processId", sortable: true };

    public constructor(purchasingViewModel: IPurchasingParentViewModel) {
        super();
        this.purchasingViewModel = purchasingViewModel;
        this.isStoreView = purchasingViewModel.isStoreView;
        this.salesOrderInfo = purchasingViewModel.salesOrderInfo;
    }

    public async buildPurchaseOrder(): Promise<void> {
        const baseUrl = "/api/purchasing/buildartworkpurchaseorder";
        let rowLimitExceeded = false;
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new VendorPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as BuildArtworkPurchaseOrderResults;
            if (results) {
                if (results.purchaseOrder) {
                    if (results.purchaseOrder.exceedsRowLimit) {
                        rowLimitExceeded = true;
                    }
                    this.toViewModel(results.purchaseOrder);
                }
                if (results.countries) {
                    this.countries = results.countries;
                }
                if (results.states) {
                    this.states = results.states;
                }
                this.stores.splice(0);
                this.stores.push(...results.stores);
                this.processes.splice(0);
                this.processes.push(...results.processes);
                this.vendors.splice(0);
                this.vendors.push(...results.vendors);
                this.salesReps.splice(0);
                this.salesReps.push(...results.salesReps);
                this.selectedPurchaseOrderType = PurchasingConstants.autoPurchaseType;
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        if (rowLimitExceeded) {
            this.purchasingViewModel.isLoading = false;
            setTimeout(async () => {
                await this.handleLargePurchaseOrder();
            });
            return;
        }
    }

    public async showPurchasingDialogOrCheckAvailabilityAsNeeded(type: PurchasingType): Promise<void> {
        if (this.isPurchaseOrderInfoRequiredForPresubmit) {
            await this.showPurchaseOrderDialog(type);
        } else {
            this.selectedPurchaseOrderType = type;
            await this.checkAvailability();
        }
    }

    public async showPurchaseOrderDialog(type: PurchasingType): Promise<void> {
        this.selectedPurchaseOrderType = type;
        if (!(await this.getDefaultVendorSettings())) {
            // Don't allow check availability if this false for some reason.
            return;
        }
        this.isPurchaseOrderDialogVisible = true;
        switch (this.selectedPurchaseOrderType) {
            case PurchasingConstants.autoPurchaseType:
                this.purchaseOrderDialogTitle = "Order Digitally";
                break;
            case PurchasingConstants.checkPurchaseType:
                this.purchaseOrderDialogTitle = "Check Availability";
                break;
            case PurchasingConstants.manualArtworkPurchaseType:
                this.purchaseOrderDialogTitle = "Manually Order";
                break;
            case PurchasingConstants.pulledPurchaseType:
                this.purchaseOrderDialogTitle = "Pull from Stock";
                break;
        }
    }

    public async closePurchaseOrderDialog(): Promise<void> {
        this.isPurchaseOrderDialogVisible = false;
        this.selectedPurchaseOrderType = null;
    }

    public async acceptPurchaseOrderDialog(): Promise<void> {
        if (!this.selectedPurchaseOrderType) {
            return;
        }
        this.isPurchaseOrderDialogVisible = false;
        switch (this.selectedPurchaseOrderType) {
            case PurchasingConstants.checkPurchaseType:
                await this.checkAvailability();
                break;
            default:
                await this.submitPurchaseOrder(this.selectedPurchaseOrderType);
                break;
        }
    }

    public async refresh(shouldMaintainAvailabilityStatus?: boolean): Promise<void> {
        this.lastPurchaseOrderId = 0;
        const previousLines: IArtworkPurchasingLineItem[] = [];
        if (shouldMaintainAvailabilityStatus) {
            for (const lines of this.lines) {
                previousLines.push(lines);
            }
        }
        await this.list();
    }

    public async list(): Promise<void> {
        const baseUrl = this.getListUrl();
        try {
            this.selectedLines.splice(0);
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new ListArtworkPurchaseOrderArgs();
            serviceArgs.shouldGroupByProcess = this.isGroupingByProcess;
            // We can only show one group at a time so we prefer the process group.
            if (this.isGroupingByProcess) {
                const fulfilledIndex = this.headers.indexOf(this.fulfillmentHeader);
                if (fulfilledIndex >= 0) {
                    this.headers.splice(fulfilledIndex, 1);
                }
                this.groupBy = "processId";
                const index = this.headers.indexOf(this.processHeader);
                if (index < 0) {
                    this.headers.push(this.processHeader);
                }
            } else {
                this.groupBy = "storeId";
                const index = this.headers.indexOf(this.processHeader);
                if (index >= 0) {
                    this.headers.splice(index, 1);
                }
                if (!this.filters.shouldExcludeFulfilledItems && this.canGroupByFulfillmentStatus) {
                    this.groupBy = "isFulfilled";
                    this.headers.push(this.fulfillmentHeader);
                } else {
                    const fulfilledIndex = this.headers.indexOf(this.fulfillmentHeader);
                    if (fulfilledIndex >= 0) {
                        this.headers.splice(fulfilledIndex, 1);
                    }
                }
            }
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            //serviceArgs.shouldGroupLikeItems = true;
            if (this.purchaseOrderId) {
                this.filters.purchaseOrderId = this.purchaseOrderId;
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as ArtworkPurchaseOrder;
            if (results) {
                this.toViewModel(results);
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async checkAvailability(): Promise<void> {
        const baseUrl = "/api/purchasing/checkartworkavailability";
        let errorMessage = "";
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new CheckArtworkAvailabilityArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as CheckArtworkAvailabilityResults;
            if (results) {
                if (results.wasSuccessful === false && results.lines.length <= 0) {
                    errorMessage = results.message;
                    this.purchasingViewModel.errorMessage = errorMessage;
                    this.hasCheckedAvailability = false;
                    return;
                }
                this.hasCheckedAvailability = true;
                this.applyLines(results.lines);
                this.applySort();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
            this.selectedPurchaseOrderType = null;
        }
    }

    public async getDefaultVendorSettings(): Promise<boolean> {
        const baseUrl = "/api/purchasing/getartworkvendorsettings";
        try {
            const previousVendorSettings = this.vendorSettings;
            this.vendorSettings = null;
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new ArtworkPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = Serializer.deserialize(resultsText) as IVendorPurchaseOrderSettings;
            if (results && previousVendorSettings && previousVendorSettings.vendorName === results.vendorName) {
                this.vendorSettings = previousVendorSettings;
            } else {
                this.shipMethodHint = "";
                this.vendorSettings = results;
            }
            if (this.vendorSettings) {
                this.vendorName = this.vendorSettings.vendorName;
            }
            return true;
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return false;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
        }
    }

    public async submitPurchaseOrder(purchaseOrderType: PurchasingType) {
        this.purchasingViewModel.errorMessage = "";
        this.isSubmitDialogVisible = false;
        this.selectedPurchaseOrderType = purchaseOrderType;
        const baseUrl = "/api/purchasing/submitartworkpurchaseorder";
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new SubmitArtworkPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.shouldGroupByProcess = this.isGroupingByProcess;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as SubmitArtworkPurchaseOrderResults;
            if (results) {
                if (results.wasSuccessful === false && results.lines.length <= 0) {
                    this.purchasingViewModel.errorMessage = results.message;
                    return;
                }
                this.applyLines(results.lines);
                this.isSubmitDialogVisible = false;
                this.lastPurchaseOrderNumber = this.purchaseOrderNumber;
                this.lastPurchaseOrderId = results.purchaseOrderId;
                this.purchaseOrderNumber = "";
                this.lastPurchaseOrderType = this.selectedPurchaseOrderType;
                this.showOrderCompleteDialog();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isSubmitDialogVisible = false;
            this.selectedPurchaseOrderType = null;
        }
    }

    public productCostChanged(line: IArtworkPurchasingLineItem) {
        line.totalCost = line.quantityToOrder * line.productCost;
    }

    protected applySort() {
        if (this.sortBy.length === 0) {
            this.sortBy = ["availability", "vendorName", "style", "color", "size"];
            this.sortDesc = [true, false, false, false, false];
        }
    }

    public async showOrderCompleteDialog(): Promise<void> {
        try {
            this.printOrEmailDialogViewModel = new SimpleAsyncInteractionViewModel();
            this.isPrintEmailDialogVisible = true;
            const dialogResult = await this.printOrEmailDialogViewModel.interact();
            if (dialogResult !== "accept") {
                return;
            }
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;
        } finally {
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;
        }
    }

    protected applyLines(lines: IArtworkPurchasingLineItem[]) {
        const fulfilledItems: IArtworkPurchasingLineItem[] = [];
        for (const line of lines) {
            for (const matchingLine of this.lines) {
                if (matchingLine.id !== line.id) {
                    continue;
                }
                matchingLine.quantityToOrder = line.quantityToOrder;
                matchingLine.message = line.message;
                matchingLine.hasError = line.hasError;
                matchingLine.availability = line.availability;
                matchingLine.quantityOrdered = line.quantityOrdered;
                matchingLine.isFulfilled = line.isFulfilled;
                if (
                    (matchingLine.hasError || matchingLine.availability === "Unavailable") &&
                    this.selectedLines.indexOf(matchingLine) >= 0
                ) {
                    const index = this.selectedLines.indexOf(matchingLine);
                    if (index >= 0) {
                        this.selectedLines.splice(index, 1);
                    }
                }
                if (line.isFulfilled) {
                    fulfilledItems.push(matchingLine);
                }

                if (matchingLine.isFulfilled && this.selectedLines.indexOf(matchingLine) >= 0) {
                    const index = this.selectedLines.indexOf(matchingLine);
                    if (index >= 0) {
                        this.selectedLines.splice(index, 1);
                    }
                }
            }
        }
        for (const fulfilledItem of fulfilledItems) {
            const index = this.lines.indexOf(fulfilledItem);
            if (index >= 0) {
                this.lines.splice(index, 1);
            }
        }
    }

    protected toPurchaseOrder(): ArtworkPurchaseOrder {
        const purchaseOrder = new ArtworkPurchaseOrder();
        purchaseOrder.shipEmail = this.shipEmail;
        purchaseOrder.lines = this.selectedLines;
        purchaseOrder.customerNumber = this.customerNumber;
        purchaseOrder.purchaseOrderNumber = this.purchaseOrderNumber;
        purchaseOrder.vendorName = this.vendorName;
        purchaseOrder.attention = this.attention;
        purchaseOrder.shipTo = this.shipTo;
        purchaseOrder.type = this.selectedPurchaseOrderType;
        if (this.purchaseOrderId) {
            purchaseOrder.purchaseOrderId = this.purchaseOrderId;
        }
        if (this.shouldShipToBranch) {
            purchaseOrder.dealerBranchId = this.dealerBranchId;
        } else {
            purchaseOrder.dealerBranchId = null;
        }
        purchaseOrder.vendorSettings = this.vendorSettings;
        return purchaseOrder;
    }

    protected toViewModel(purchaseOrder: IVendorPurchaseOrder<IArtworkPurchasingLineItem>) {
        this.lines.splice(0);
        for (const line of purchaseOrder.lines) {
            this.lines.push(line);
        }
        this.applyPurchaseOrderHeader(purchaseOrder);
        this.selectedLines.splice(0);
        this.selectLinesAsNeeded();
        this.totals = purchaseOrder.totals;
        if (purchaseOrder.type) {
            this.type = purchaseOrder.type;
        }
    }

    protected selectLinesAsNeeded() {
        this.selectedLines.push(...this.lines);
    }

    public getTotals() {
        {
            let lines = this.lines;
            if (this.selectedLines.length > 0) {
                lines = this.selectedLines;
            }
            const totals = lines.reduce(
                (previousValue: any, currentValue: any) => {
                    previousValue.quantity += currentValue.quantity;
                    previousValue.quantityOrdered += currentValue.quantityOrdered;
                    previousValue.quantityToOrder += parseInt(currentValue.quantityToOrder, 10);
                    return previousValue;
                },
                {
                    quantity: 0,
                    quantityOrdered: 0,
                    quantityToOrder: 0,
                }
            );
            return totals;
        }
    }

    protected getListUrl(): string {
        const baseUrl = "/api/purchasing/listvariations";
        return baseUrl;
    }

    protected async handleLargePurchaseOrder(): Promise<void> {
        const vm = new SimpleAsyncInteractionViewModel();
        vm.headerText = "Select Stores";
        vm.text = `The configured purchase order exceeds the configured size limit.  Please select a smaller number of stores or processes.`;
        this.dismissDialogViewModel = vm;
        const dialogResults = await this.dismissDialogViewModel.interact();
        this.dialogViewModel = null;
        this.back();
    }
}
