import { render, staticRenderFns } from "./ProcessLayoutCanvas.vue?vue&type=template&id=194c5d65&scoped=true&"
import script from "./ProcessLayoutCanvas.vue?vue&type=script&lang=ts&"
export * from "./ProcessLayoutCanvas.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "194c5d65",
  null
  
)

export default component.exports