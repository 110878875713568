import Vue from "vue";
import Component from "vue-class-component";
import StoreListSortBy from "@/chipply/interface/StoreListSortBy";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";

@Component
export default class StoreGridMixin extends Vue {
    public pagination!: IVuetifyTableOptions;

    protected getSortBy(): StoreListSortBy {
        if (this.pagination.sortDesc && this.pagination.sortDesc[0]) {
            switch (this.pagination.sortBy[0]) {
                case "openDate":
                    return StoreListSortBy.OpenDateDesc;
                case "closeDate":
                    return StoreListSortBy.CloseDateDesc;
                case "name":
                    return StoreListSortBy.NameDesc;
                case "inHouseCompletionDate":
                    return StoreListSortBy.InHouseCompletionDateDesc;
                case "shipDate":
                    return StoreListSortBy.ShipDateDesc;
                case "globalPurchasing":
                    return StoreListSortBy.IsDealerpurchasingEnrolledDesc;
            }
        }

        switch (this.pagination.sortBy[0]) {
            case "openDate":
                return StoreListSortBy.OpenDateAsc;
            case "closeDate":
                return StoreListSortBy.CloseDateAsc;
            case "name":
                return StoreListSortBy.NameAsc;
            case "inHouseCompletionDate":
                return StoreListSortBy.InHouseCompletionDateAsc;
            case "shipDate":
                return StoreListSortBy.ShipDateAsc;
            case "globalPurchasing":
                return StoreListSortBy.IsDealerpurchasingEnrolledAsc;
        }

        return StoreListSortBy.NameAsc;
    }
}
