import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class ScrollableContentMixin extends Vue {
    public contentSize: object = {};

    public height: number | null = null;
    public noTopNavigation = false;
    @Prop({
        default: true,
    })
    public allowScroll!: boolean;

    protected maxContentHeight = 0;

    protected hasMobileHeader = true;

    protected get mobileHeadroom(): number {
        return this.hasMobileHeader ? 70 + this.topNavigationHeadroom : this.topNavigationHeadroom;
    }

    protected get topNavigationHeadroom(): number {
        return this.noTopNavigation ? 0 : 50;
    }

    protected get excludedContentSize(): number {
        return this.$vuetify.breakpoint.smAndDown ? this.mobileHeadroom : this.topNavigationHeadroom;
    }

    protected getAdjustedParentHeight(): number {
        return this.parentHeight;
    }

    protected getParentHeight(): number {
        return this.parentHeight;
    }

    protected get parentHeight(): number {
        return this.height || window.innerHeight;
    }

    protected getMaxContentHeight() {
        return this.getParentHeight() - this.excludedContentSize - this.getVariableHeadroom();
    }

    /*
     This property is meant to be used by components that need to account
     for other elements on the screen that shouldn't scroll
    */

    protected getVariableHeadroom(): number {
        return 0;
    }

    @Watch("$vuetify.breakpoint.name")
    protected delayResize() {
        this.$nextTick(() => this.resizeContent());
    }

    protected resizeContent() {
        //this.height = window.innerHeight;
        // This value may change based on the host.
        // I couldn't figure out a better way to make this work, open to improvement!
        // Without this the table will scroll past it's flex-basis and relative flex
        this.maxContentHeight = this.getMaxContentHeight();
        if (this.allowScroll) {
            this.contentSize = {
                maxHeight: this.maxContentHeight + "px",
                overflow: "auto",
            };
        } else {
            this.contentSize = {
                maxHeight: this.maxContentHeight + "px",
                overflow: "hidden",
            };
        }
    }
}
