import Sortable, { SortableEvent } from "sortablejs";
import Vue from "vue";

export class DragDropHelper {
    public static initializeDragAndDrop(
        table: Element | Vue,
        vm: any,
        arrayPath: string,
        vueInstance: Vue,
        filter?: any,
        handle?: any
    ) {
        table = (table as Vue).$el || table;
        const tableBody = table!.querySelector("tbody");
        Sortable.create(tableBody!, {
            handle: handle ?? ".dragHandle",
            filter: filter,
            preventOnFilter: false,
            onEnd: (event: SortableEvent) => {
                const oldIndex = event.oldIndex!;
                const newIndex = event.newIndex!;
                const tempArray: any[] = [];
                tempArray.push(...vm[arrayPath]);

                const rowSelected = tempArray.splice(oldIndex, 1)[0];
                tempArray.splice(newIndex, 0, rowSelected);
                vm![arrayPath] = [];
                // Goofy but necessary to correctly redraw after drag and drop
                vueInstance.$nextTick(() => {
                    vm![arrayPath] = tempArray;
                });
            },
        });
    }
}
