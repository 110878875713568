import {
    mdiAlert,
    mdiAlertCircle,
    mdiAlertOutline,
    mdiArrowLeft,
    mdiArrowRight,
    mdiBriefcase,
    mdiCalendar,
    mdiCamera,
    mdiCancel,
    mdiCardsSpade,
    mdiCheck,
    mdiCheckboxOutline,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiCheckUnderlineCircleOutline,
    mdiChevronDown,
    mdiChevronRight,
    mdiChevronUp,
    mdiClockOutline,
    mdiClose,
    mdiCloseCircleMultipleOutline,
    mdiCloudUpload,
    mdiCog,
    mdiCogs,
    mdiConnection,
    mdiContentCopy,
    mdiContentPaste,
    mdiContentSave,
    mdiCurrencyUsd,
    mdiDelete,
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiDownload,
    mdiDragVertical,
    mdiEmailSend,
    mdiEye,
    mdiEyeOff,
    mdiFileMultiple,
    mdiFilter,
    mdiFormatListBulleted,
    mdiFormatListChecks,
    mdiHelpCircle,
    mdiHelpCircleOutline,
    mdiImage,
    mdiInformationOutline,
    mdiKey,
    mdiLogout,
    mdiMagnify,
    mdiMenuRight,
    mdiMinus,
    mdiOpenInNew,
    mdiPencil,
    mdiPlus,
    mdiPrinter,
    mdiRefresh,
    mdiRss,
    mdiRestore,
    mdiRun,
    mdiShape,
    mdiSortAlphabeticalVariant,
    mdiSquareOpacity,
    mdiStar,
    mdiStore,
    mdiStorefrontOutline,
    mdiSync,
    mdiTextSearch,
    mdiTimelineClockOutline,
    mdiTruckDelivery,
    mdiViewDashboard,
    mdiWeatherLightningRainy,
    mdiSourcePull,
    mdiSwapHorizontalVariant,
    mdiViewGrid,
    mdiInformationVariant,
    mdiPaletteOutline,
} from "@mdi/js";
import { mdiDomain } from "@mdi/js";

// These can be found at https://materialdesignicons.com/

const chipplyIcons = {
    mdiAlert,
    mdiAlertCircle,
    mdiAlertOutline,
    mdiArrowRight,
    mdiArrowLeft,
    mdiBriefcase,
    mdiCalendar,
    mdiCamera,
    mdiCancel,
    mdiCardsSpade,
    mdiCheck,
    mdiCheckboxOutline,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiCheckUnderlineCircleOutline,
    mdiChevronDown,
    mdiChevronRight,
    mdiChevronUp,
    mdiClockOutline,
    mdiClose,
    mdiCloseCircleMultipleOutline,
    mdiCloudUpload,
    mdiCog,
    mdiCogs,
    mdiConnection,
    mdiContentCopy,
    mdiContentPaste,
    mdiContentSave,
    mdiCurrencyUsd,
    mdiDelete,
    mdiDomain,
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiDownload,
    mdiDragVertical,
    mdiEmailSend,
    mdiEye,
    mdiEyeOff,
    mdiFileMultiple,
    mdiFilter,
    mdiFormatListBulleted,
    mdiFormatListChecks,
    mdiHelpCircle,
    mdiHelpCircleOutline,
    mdiImage,
    mdiInformationOutline,
    mdiKey,
    mdiLogout,
    mdiMagnify,
    mdiMenuRight,
    mdiMinus,
    mdiOpenInNew,
    mdiPaletteOutline,
    mdiPencil,
    mdiPlus,
    mdiPrinter,
    mdiRefresh,
    mdiRss,
    mdiRestore,
    mdiRun,
    mdiShape,
    mdiSortAlphabeticalVariant,
    mdiSquareOpacity,
    mdiStar,
    mdiStore,
    mdiStorefrontOutline,
    mdiSync,
    mdiTextSearch,
    mdiTimelineClockOutline,
    mdiTruckDelivery,
    mdiViewDashboard,
    mdiSourcePull,
    mdiSwapHorizontalVariant,
    mdiViewGrid,
    mdiInformationVariant,
};

export default chipplyIcons;
