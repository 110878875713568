import IEventProductSize from "@/chipply/products/i-event-product-size";

export default class EventProductSizeViewModel {
    public eventProductSizeId: number;
    public sizeName: string;
    public checked: boolean;

    public constructor(model: IEventProductSize) {
        this.eventProductSizeId = model.eventProductSizeId;
        this.sizeName = model.sizeName;
        this.checked = model.checked || false;
    }

    public toModel(): IEventProductSize {
        return {
            checked: this.checked,
            eventProductSizeId: this.eventProductSizeId,
            sizeName: this.sizeName,
        };
    }
}
