






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    props: {
        bold: Boolean,
    },
})
export default class CControlLabel extends Vue {
    public bold: boolean | undefined;
    private classes = "body-1";

    @Prop({
        type: Boolean,
        default: false,
    })
    public small!: boolean;

    public created() {
        if (this.small) {
            this.classes = "caption";
        }

        if (this.bold) {
            this.classes += " font-weight-bold";
        }
    }
}
