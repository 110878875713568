



















import { AssignCategorySelection, AssignCategoryViewModel } from "@/chipply/categories/AssignCategoryViewModel";
import { AddFieldViewModel } from "@/chipply/fields/AddFieldViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import TreeSelector from "@/components/input/TreeSelector.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import Component, { mixins } from "vue-class-component";
import Validation from "@/validation";
import { Prop } from "vue-property-decorator";

@Component({
    components: { CDecisionCard },
})
export default class AddFieldCard extends mixins(FormValidationMixin) {
    public Validation = Validation;

    @Prop({
        type: Object,
        default: null,
    })
    public vm!: AddFieldViewModel | null;
}
