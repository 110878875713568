












import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CHelpButton extends Vue {
    public chipplyIcons = chipplyIcons;
    @Prop({
        type: String,
    })
    public tooltip!: string | null;

    protected computedTooltip() {
        return this.tooltip || "Help";
    }
}
