
































































































































































import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import ErrorReporter from "@/components/utility/ErrorReporter.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { Prop, Watch } from "vue-property-decorator";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import { IListViewModel } from "@/chipply/view-model/IListViewModel";
import CChildPane from "@/components/ui/CChildPane.vue";
import DataTablePagingMixin from "@/components/DataTablePagingMixin";
import { ListPageViewModel } from "@/chipply/view-model/ListPageViewModel";
import { result } from "lodash";
@Component({
    components: { TextHeading, LongRunningOperationDialog, ErrorReporter, CChildPane },
})
export default class ListLayout extends mixins(ScrollableContentMixin, DataTablePagingMixin) {
    @Prop({
        type: Object,
    })
    public viewModel!: ListPageViewModel;

    @Prop({
        default: false,
        type: Boolean,
    })
    public isLoading!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public showBack!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isHeaderVisible!: boolean;

    @Prop({
        type: Number,
    })
    public containerHeight: number | undefined;

    @Prop({
        default: false,
        type: Boolean,
    })
    public hideDefaultHeader!: boolean;

    @Prop({
        default: "",
        type: String,
    })
    public dataTableNoDataText!: string;

    public name = "ListLayout";
    public declare $refs: {
        textHeading: Vue;
        mobileTextHeading: Vue;
        listfooter: HTMLElement;
        searchPanel: HTMLElement;
        layoutWrapper: HTMLElement;
        datatable: any;
    };
    public chipplyIcons = chipplyIcons;

    public raiseBack() {
        this.$emit("back");
    }
    public data() {
        return {};
    }

    protected getParentHeight(): number {
        if (this.containerHeight) {
            return this.containerHeight;
        }
        return super.parentHeight;
    }

    protected getVariableHeadroom(): number {
        const mobileTableHeader =
            (this.$vuetify.breakpoint.mdAndUp
                ? this.$refs.textHeading?.$el.clientHeight
                : this.$refs.mobileTextHeading?.$el.clientHeight) || 0;
        const tableFooterHeight = this.viewModel?.hideDefaultFooter ? 80 : 130;
        return (
            mobileTableHeader +
            tableFooterHeight +
            (this.$refs.listfooter || { clientHeight: 0 }).clientHeight +
            (this.$refs.searchPanel || { clientHeight: 0 }).clientHeight
        );
    }

    protected async selectNextPageCore(): Promise<any> {
        if (this.viewModel?.dataTableDisablePagination) {
            await this.viewModel.list();
        }
    }

    public expandRow(item: any, isExpand: boolean) {
        (this.$refs.datatable as any).expand(item, isExpand);
    }

    @Watch("viewModel.pagination.sortBy")
    protected async onSortChanged() {
        if (this.viewModel?.dataTableDisableSort) {
            return;
        }

        await this.viewModel.list(true);
    }
}
