import ArtworkPurchaseOrderArgs from "./ArtworkPurchaseOrderArgs";
import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import ICheckAvailabilityArgs from "./ICheckAvailabilityArgs";
import IPurchaseOrderArgs from "./IPurchaseOrderArgs";
import PurchasingFilters from "./PurchasingFilters";

export default class CheckArtworkAvailabilityArgs
    extends ArtworkPurchaseOrderArgs
    implements ICheckAvailabilityArgs<IArtworkPurchasingLineItem>
{
    public filters: PurchasingFilters = new PurchasingFilters();
}
