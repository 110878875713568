import { IOrderItemSize, Utils } from "chipply-common";

export default class OrderItemSizeViewModel {
    public sizeName!: string;
    public sizeId!: number;
    public cost!: number;
    public sizeDisplay!: string;

    public constructor(size: IOrderItemSize) {
        Object.assign(this, size);

        this.sizeDisplay =
            this.cost === 0 ? this.sizeName : `${this.sizeName} (+${Utils.getCurrencyValue(this.cost, false, true)})`;
    }

    public toModel(): IOrderItemSize {
        return {
            cost: this.cost,
            sizeId: this.sizeId,
            sizeName: this.sizeName,
        };
    }
}
