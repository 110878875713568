import { mixins } from "vue-class-component";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import NavigationArea from "@/chipply/NavigationArea";
import Component from "vue-class-component";

@Component
export default class StorePageMixin extends mixins(UserInfoMixin) {
    public NavigationArea = NavigationArea;

    public eventId: number | null = null;
    public menuArgs: object | null = null;
    public init = false;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eventid")) {
            this.eventId = Number(urlParams.get("eventid"));
            this.menuArgs = {
                $type: "Chipply.Service.Menu.StoreDetailsMenuArgs, Chipply.Service",
                eventId: this.eventId,
            };
            this.init = true;
        }
    }
}
