import "es6-promise/dist/es6-promise.auto.min";
import "whatwg-fetch";
import vuetify from "../plugins/vuetify";
import "../registerServiceWorker";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import Vue from "vue";
import Vuex from "vuex";
import "./ImportIcons";
Vue.config.productionTip = false;

Vue.use(Vuex);

export { vuetify };
