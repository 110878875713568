import { ColorAssignmentsModel } from "@/chipply/store/color-assignment/ColorAssignmentsModel";
import { ColorAssignmentsViewModel } from "@/chipply/store/color-assignment/ColorAssignmentsViewModel";
import { ColorAssignmentViewModel } from "@/chipply/store/color-assignment/ColorAssignmentViewModel";
import { GroupColorAssignmentViewModel } from "@/chipply/store/color-assignment/GroupColorAssignmentViewModel";
import { ProductColorAssignmentViewModel } from "@/chipply/store/color-assignment/ProductColorAssignmentViewModel";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import {
    AsyncInteractionViewModel,
    Serializer,
    typeDependencies,
    Utils,
    ViewModelFactory,
    WebHelper,
} from "chipply-common";
import IColorAssignmentsResults from "../../interface/i-color-assignment-results";
import IColorAssignmentsDto from "../../interface/i-color-assignments-dto";
import { IProductColorAssignment } from "../../interface/i-product-color-assignment";
import ITreeItem from "../../interface/i-tree-item";
import { IColorAssignment } from "../../interface/IColorAssignment";

@typeDependencies({
    types: {
        ColorAssignmentsModel,
    },
})
export default class ColorAssignmentsPageViewModel extends PageViewModel {
    public originalModel: IColorAssignmentsDto | null = null;
    public eventArtworkId = 0;
    public colorAssignments: ColorAssignmentsViewModel = new ColorAssignmentsViewModel();
    public eventId = 0;
    public isAssignVariationsDialogVisible = false;
    public massAssignEventArtworkVariationId = 0;
    public selectedAssignmentsByColor: GroupColorAssignmentViewModel[] = [];
    public selectedAssignmentsByProduct: ProductColorAssignmentViewModel[] = [];
    public modifiedAssignmentsByColor: ColorAssignmentViewModel[] = [];
    public modifiedAssignmentsByProduct: ProductColorAssignmentViewModel[] = [];
    public singleSelect = false;
    public tab: any = null;
    public filterByVariationIds: number[] = [];
    public availableVariationsItems: ITreeItem[] = [];
    public confirmViewModel: AsyncInteractionViewModel | null = null;

    public artworkHeaders = [
        { text: "Standard Color", value: "standardColor" },
        { value: "productColorCheckbox", width: "60px" },
        { text: "Product Color", value: "colorName" },
        {
            text: "Decoration Color - Garment Color",
            value: "eventArtworkVariationId",
        },
        { text: "", value: "variationImage", width: "60px" },
    ];

    public byProductHeaders = [
        { text: "Product Name", value: "productName" },
        { text: "Product Color", value: "colorName" },
        { text: "Vendor Name", value: "vendorName" },
        { text: "Product Style", value: "productStyle" },
        { text: "Decoration Color", value: "eventArtworkVariationId" },
        { text: "", value: "productImage" },
        { text: "", value: "variationImage" },
    ];

    public get showAssignVariations() {
        for (const group of this.colorAssignments.assignmentsByColor) {
            for (const assignment of group.assignments) {
                if (assignment.colorSelected) {
                    return true;
                }
            }
        }

        if (this.selectedAssignmentsByProduct.length > 0) {
            return true;
        }
        return false;
    }

    public selectedChanged = (selected: boolean) => {
        this.selectedAssignmentsByColor = [];
        for (const group of this.colorAssignments.assignmentsByColor) {
            group.selectedChanged(selected);
            if (selected) {
                this.selectedAssignmentsByColor.push(group as any);
            }
        }
    };

    public groupSelectedChanged = (group: GroupColorAssignmentViewModel, selected: boolean) => {
        group.selectedChanged(selected);
        if (selected) {
            this.selectedAssignmentsByColor.push(group as any);
        } else {
            const removalIndex = this.selectedAssignmentsByColor.indexOf(group);
            this.selectedAssignmentsByColor.splice(removalIndex, 1);
        }
    };
    public async getColorAssignments(): Promise<void> {
        const baseUrl = "/api/Events/ColorAssignment";
        try {
            this.setLoadingStatus(this.loadingMessage);
            const resultsText = await WebHelper.postJsonData(baseUrl, this.createGetArguments());
            const results = Serializer.deserialize(resultsText) as IColorAssignmentsResults;
            this.colorAssignments = ViewModelFactory.getViewModel({ model: results.model });
            this.originalModel = this.colorAssignments!.toModel();

            if (results.model.artworks.length > 0 && this.eventArtworkId === 0) {
                this.eventArtworkId = results.model.artworks[0].artworkId;
            }

            this.availableVariationsItems.splice(0);
            for (const item of this.colorAssignments.variations) {
                this.availableVariationsItems.push({
                    id: item.eventArtworkVariationId,
                    name: item.variationName,
                    children: [],
                });
            }
        } catch (e) {
            this.setErrorMessage(this.loadingErrorMessage);
        } finally {
            this.clearSelectionsAndChanges();
            this.unsetLoadingStatus();
        }
    }

    public async artworkChanged(eventArtworkId: number): Promise<void> {
        if (this.modifiedAssignmentsByColor.length > 0 || this.modifiedAssignmentsByProduct.length > 0) {
            await this.save();
        }
        this.eventArtworkId = eventArtworkId;
        if (this.filterByVariationIds.length > 0) {
            this.clearFilters();
            // Clearing the filters will trigger the watcher which will in turn call
            // getColorAssignments, no need to call getColorAssignments here
        } else {
            this.getColorAssignments();
        }
    }

    public async save(): Promise<boolean> {
        const baseUrl = "/api/Events/SaveColorAssignments";
        try {
            this.setLoadingStatus(this.savingMessage);
            const resultsText = await WebHelper.postJsonData(baseUrl, this.createSaveArguments());
            this.clearSelections();
            const results = Serializer.deserialize(resultsText) as IColorAssignmentsResults;
            this.colorAssignments = ViewModelFactory.getViewModel({ model: results.model });
            this.originalModel = this.colorAssignments!.toModel();
            return true;
        } catch {
            this.setErrorMessage(Utils.ServerErrorMessage);
        } finally {
            this.clearSelectionsAndChanges();
            this.unsetLoadingStatus();
        }
        return false;
    }

    public massApplyVariations() {
        this.isAssignVariationsDialogVisible = true;
    }

    public async assignVariations(wasAccepted: boolean): Promise<void> {
        this.isAssignVariationsDialogVisible = false;
        if (wasAccepted) {
            for (const currentItem of this.colorAssignments.assignmentsByColor) {
                for (const assignment of currentItem.assignments) {
                    if (assignment.colorSelected) {
                        this.assignmentByColorVariationChanging(this.massAssignEventArtworkVariationId, assignment);
                        assignment.eventArtworkVariationId = this.massAssignEventArtworkVariationId;
                    }
                }
            }
            for (const currentItem of this.selectedAssignmentsByProduct) {
                this.assignmentByProductVariationChanging(this.massAssignEventArtworkVariationId, currentItem);
                currentItem.eventArtworkVariationId = this.massAssignEventArtworkVariationId;
            }
            await this.save();
        }
    }

    public assignmentByColorVariationChanging(newValue: number, item: ColorAssignmentViewModel) {
        if (!item.originalValues) {
            item.originalValues = {};
        }
        const propertyName = "eventArtworkVariationId";
        if (newValue === item.originalValues[propertyName]) {
            delete item.originalValues[propertyName];
            item.isChanged = false;
            const index = this.modifiedAssignmentsByColor.indexOf(item);
            if (index > -1) {
                this.modifiedAssignmentsByColor.splice(index, 1);
            }
        } else {
            item.isChanged = true;
            item.originalValues[propertyName] = item.eventArtworkVariationId;
            this.modifiedAssignmentsByColor.push(item);
        }
        item.eventArtworkVariationId = newValue;
        item.variationImage = this.getArtworkVariationImageUrl(newValue);
    }

    private getArtworkVariationImageUrl(eventArtworkVariationId: number): string {
        return this.colorAssignments.variations.find((x) => x.eventArtworkVariationId === eventArtworkVariationId)!
            .imageUrl;
    }

    public cancelManualAssignment = (item: ProductColorAssignmentViewModel) => {
        item.eventArtworkVariationId = item.originalValues.eventArtworkVariationId;
        item.colorName = item.originalValues.colorName;
        item.isChanged = false;
        item.isManualAssignment = item.originalValues.isManualAssignment;

        const index = this.modifiedAssignmentsByProduct.indexOf(item);
        if (index > -1) {
            this.modifiedAssignmentsByProduct.splice(index, 1);
        }
    };

    public assignmentByProductVariationChanging(newValue: number, item: ProductColorAssignmentViewModel) {
        if (newValue === item.originalValues.eventArtworkVariationId) {
            this.cancelManualAssignment(item);
        } else {
            item.isChanged = true;
            if (this.modifiedAssignmentsByProduct.indexOf(item) === -1) {
                this.modifiedAssignmentsByProduct.push(item);
            }
        }
        item.eventArtworkVariationId = newValue;
        item.variationImage = this.getArtworkVariationImageUrl(newValue);
    }

    public async tabsChanged(index: number) {
        if (index === 0) {
            this.selectedAssignmentsByProduct.splice(0);
        } else if (index === 1) {
            this.selectedAssignmentsByColor.splice(0);
        }
        if (this.modifiedAssignmentsByColor.length === 0 && this.modifiedAssignmentsByProduct.length === 0) {
            return;
        }
        await this.save();
    }

    protected clearSelectionsAndChanges() {
        this.clearSelections();
        this.clearChanges();
    }

    protected clearChanges() {
        this.modifiedAssignmentsByProduct.splice(0);
        this.modifiedAssignmentsByColor.splice(0);
    }

    protected clearSelections() {
        this.selectedAssignmentsByColor.splice(0);
        this.selectedAssignmentsByProduct.splice(0);
    }

    protected clearFilters() {
        this.filterByVariationIds.splice(0);
    }

    protected setLoadingStatus(statusMessage: string): void {
        this.statusMessage = statusMessage;
        this.loading = true;
    }

    protected setErrorMessage(errorMessage: string): void {
        this.errorMessage = errorMessage;
    }

    protected unsetLoadingStatus() {
        this.loading = false;
    }

    protected createSaveArguments(): any {
        const results = {
            model: this.colorAssignments.toModel(),
            eventArtworkId: this.eventArtworkId,
            eventId: this.eventId,
            filterByVariationIds: this.filterByVariationIds,
        };
        return results;
    }

    protected createGetArguments(): any {
        const results = {
            eventArtworkId: this.eventArtworkId,
            eventId: this.eventId,
            filterByVariationIds: this.filterByVariationIds,
        };
        return results;
    }
}
