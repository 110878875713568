
















import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    props: {
        block: Boolean,
        color: String,
        icon: Boolean,
        small: Boolean,
        tooltip: String,
    },
})
export default class CEditButton extends Vue {
    public chipplyIcons = chipplyIcons;
    public color: string | undefined;
    public xSmall!: boolean | null;
    public small!: boolean | null;
    public tooltip!: string | null;

    @Prop({
        default: false,
        type: Boolean,
    })
    public disabled!: boolean;

    protected computedColor() {
        if (this.color) {
            return this.color;
        }
        return "primary";
    }

    protected computedTooltip() {
        return this.tooltip || "Edit";
    }
}
