export default class EcomOrderListFilters {
    public billEmail?: string;
    public billFirstName?: string;
    public billLastName?: string;
    public billPhone?: string;
    public orderId?: number;
    public ordersFromDate?: string;
    public ordersToDate?: string;
    public orderStatus?: string;
    public organizationId?: number;
    public saleOrder?: string;
    public shippingType?: string;
    public eventId?: number;
}
