import { IAddress } from "chipply-common";

export default class DealerBranchModel {
    public companyBranchId = "";
    public dealerId = "";
    public branchName = "";
    public primaryUser = "";
    public primaryUserId: number | null = null;
    public addressType: number | null = null;
    public poBox = "";
    public phone = "";
    public fax = "";
    public email = "";
    public billingAddressType: number | null = null;
    public billingPoBox = "";
    public billingPhone = "";
    public billingFax = "";
    public billingEmail = "";
    public enabled: boolean | null = null;
    public address: IAddress | null = null;
    public billingAddress: IAddress | null = null;
}
