




















































































































































































































import chipplyIcons from "@/chipply/ImportIcons";
import Component from "vue-class-component";
import EditLayout from "@/components/layout/EditLayout.vue";
import DealerBranchesViewModel from "@/chipply/dealer/DealerBranchesViewModel";
import EditOrganizationContact from "@/components/organization/EditOrganizationContact.vue";
import CToolbarBtn from "../ui/CToolbarBtn.vue";
import CEditButton from "../ui/CEditButton.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CAddressEditor from "../ui/CAddressEditor.vue";
import Validation from "@/validation";
import EditContact from "@/components/EditContact.vue";
import { Prop, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import FormValidationMixin from "../FormValidationMixin";
import { mixins } from "vue-class-component";

@Component({
    components: {
        EditLayout,
        CToolbarBtn,
        CEditButton,
        CAddButton,
        CAddressEditor,
        EditOrganizationContact,
        EditContact,
    },
    directives: {
        mask,
    },
})
export default class DealerBranches extends mixins(FormValidationMixin) {
    public icons = chipplyIcons;
    public isBranchValid = false;
    public isBillingValid = false;
    public get isDisabled() {
        if (!this.vm.sameAsBranchInfo) {
            return !(this.isBranchValid && this.isBillingValid);
        } else {
            return !this.isBranchValid;
        }
    }
    @Prop({
        type: Number,
    })
    public companyId!: number | null;
    @Prop({
        type: Boolean,
    })
    public isAdmin!: boolean;

    @Watch("vm", { deep: true })
    protected vmChanged(newVal: any) {
        this.validate();
        if (newVal.isEdit) {
            this.$nextTick(() => {
                this.validateBillingForm();
            });
        }
    }

    public Validation = Validation;
    public vm = new DealerBranchesViewModel();
    public showDialog: boolean = this.vm.showDialog;

    public async created() {
        this.vm.isAdmin = this.isAdmin;
        await this.vm.list(this.companyId);
    }

    public async validateBillingForm() {
        if (this.$refs.billingForm) {
            await (this.$refs.billingForm as any).validate();
        }
    }
}
