import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import CustomerMergeTemplateViewModel from "@/chipply/merge/CustomerMergeTemplateViewModel";
import IAutomatedStoreMergeTemplate from "@/chipply/merge/IAutomatedStoreMergeTemplate";
import IStoreCustomerMergeTemplate from "@/chipply/merge/IStoreCustomerMergeTemplate";
import { DateTime } from "luxon";
import { WebHelper } from "chipply-common";
import IDealerCustomerMergeTemplate from "@/chipply/merge/IDealerCustomerMergeTemplate";
import { IMergeMessageViewModel } from "@/chipply/merge/MergeMessageViewModel";
import IMergeTemplateDto from "@/chipply/merge/IMergeTemplateDto";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";

export default class StoreCustomerMergeTemplateViewModel
    extends CustomerMergeTemplateViewModel
    implements IMergeMessageViewModel
{
    public $typeHint = "StoreCustomerMergeTemplate";
    public orderIds: number[] = [];
    public lastSendDate!: string;
    public resend!: boolean;
    public templates: IDealerCustomerMergeTemplate[] = [];
    public orderRules: any[] = [];

    public constructor() {
        super();
        this.orderRules.push(this.requireOneOrder);
    }

    public created(dto: IStoreCustomerMergeTemplate) {
        this.setTemplate(dto);
    }

    public setTemplate(data?: IStoreCustomerMergeTemplate) {
        super.setTemplate(data);
        this.resend = data ? data.resend : false;

        if (data) {
            if (data.trigger === EmailTemplateTriggerTypeEnum.StaticDate) {
                this.staticDate = DateTime.fromISO(data.staticDate!).toFormat("yyyy-MM-dd");
                this.staticTime = DateTime.fromISO(data.staticDate!).toLocaleString(DateTime.TIME_24_SIMPLE);
            }
            if (data.lastSendDate) {
                this.lastSendDate = DateTime.fromISO(data.lastSendDate).toLocaleString(DateTime.DATETIME_MED);
            }
        }
        if (!this.orderIds || this.orderIds.length === 0) {
            this.orderIds = data ? data.orderIds! : [];
        }
        this.trigger = data ? data.trigger : EmailTemplateTriggerTypeEnum.StaticDate;
    }

    public resetTemplate(template: IMergeTemplateDto) {
        const castedTemplate = template as IStoreCustomerMergeTemplate;
        castedTemplate.trigger = EmailTemplateTriggerTypeEnum.StaticDate;
        castedTemplate.staticDate = DateTime.local().toISO();
        castedTemplate.id = 0;
        castedTemplate.orderIds = castedTemplate.orderIds ?? [];
        castedTemplate.lastSendDate = "";
        castedTemplate.resend = false;
        this.setTemplate(castedTemplate);
    }

    public async loadEditData(): Promise<void> {
        this.templates = await WebHelper.getJsonData(
            `/api/Event/Email/Templates/${MergeTemplateType.MessageCustomers}`
        );
    }

    public toModel(): IStoreCustomerMergeTemplate {
        const baseModel = super.toModel();
        const model = {
            ...baseModel,
            $typeHint: this.$typeHint,
            lastSendDate: this.lastSendDate,
            orderIds: this.orderIds,
            resend: this.resend,
        } as IStoreCustomerMergeTemplate;
        this.setDate(model);
        return model;
    }

    public clone() {
        const clone = new StoreCustomerMergeTemplateViewModel();
        clone.setTemplate(this.toModel());
        return clone;
    }
    protected requireOneOrder = (value: any) => {
        const moreThanOneEmailRecipient = this.isMessageEmail && this.toRecipients.length > 0;
        const moreThanOneSmsRecipient = this.isMessageSms && this.sms!.recipients.length > 0;
        const moreThanOneOrder = this.orderIds!.length > 0;
        return moreThanOneEmailRecipient || moreThanOneSmsRecipient || moreThanOneOrder
            ? true
            : "You must select at least one email recipient";
    };
}
