



































import Component from "vue-class-component";
import tinymce from "tinymce";
import "tinymce/themes/silver";
import Editor from "@tinymce/tinymce-vue";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import MergeFields from "@/chipply/merge/MergeFields";

const test = tinymce.init;
const tinymceDefault = require("@tinymce/tinymce-vue").default;

@Component({
    components: {
        CControlLabel,
        Editor,
    },
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        height: Number,
        label: String,
        mergeFields: Array,
        mergeLabel: String,
        simple: Boolean,
        value: String,
        rules: Array,
    },
})
export default class HtmlEditor extends Vue {
    public mergeLabel!: string;
    public name = "HtmlEditor";
    public editorValue!: string | undefined;
    public editorId!: string;
    public value!: string | undefined;
    public tinyMceInitOptions!: object | undefined;
    public simple!: boolean;
    public height: number | undefined;

    public data() {
        return {
            editorId: Math.floor(Math.random() * 1000000).toString(),
            editorValue: this.value,
            rawEditor: tinymceDefault,
            tinyMceInitOptions: undefined,
        };
    }

    public created() {
        const options = {
            height: this.height || 600,
            menu: undefined as undefined | object,
            menubar: undefined as undefined | string,
            toolbar: undefined as undefined | string,
        };

        if (this.simple) {
            options.toolbar =
                "fontsizeselect forecolor fontselect | bold italic | alignleft aligncenter alignright alignjustify | link";
        }

        this.tinyMceInitOptions = options;
    }

    @Watch("value")
    protected onValueChanged(nv: string) {
        this.editorValue = nv;
    }

    protected editorValueChanged(nv: string) {
        this.$emit("change", nv);
    }

    protected mergeFieldSelected(mergeField: string) {
        const formattedMergeField = MergeFields.formatMergeField(mergeField);
        tinymce.get(this.editorId).execCommand("mceInsertContent", false, formattedMergeField);
    }
}
