


















import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";

@Component({
    props: {
        show: Boolean,
        statusMessage: String,
    },
})
export default class LongRunningOperationDialog extends Vue {
    public name = "LongRunningOperationDialog";

    public data() {
        return {};
    }

    @Watch("show")
    private onShowChanged(oldValue: boolean, newValue: boolean) {
        const sideMenuBlocker = document.getElementById("sideMenuBlocker");
        if (sideMenuBlocker) {
            sideMenuBlocker.style.display = "";
        }
        const mainMenuBlocker = document.getElementById("mainMenuBlocker");
        if (mainMenuBlocker) {
            mainMenuBlocker.style.display = "";
        }
    }
}
