import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";

export default class PageState {
    public page = 1;
    public itemsPerPage = 200;
    public wasLastPageSelected = false;

    public constructor(vuetifyTableOptions?: IVuetifyTableOptions) {
        if (vuetifyTableOptions) {
            this.itemsPerPage = vuetifyTableOptions.itemsPerPage;
            this.page = vuetifyTableOptions.page;
        }
    }
    public next(selectedCount: number) {
        if (selectedCount >= this.itemsPerPage) {
            this.page++;
        } else {
            this.wasLastPageSelected = true;
        }
    }

    public reset() {
        this.page = 1;
        this.wasLastPageSelected = false;
    }
}
