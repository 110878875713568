import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class FormValidationMixin extends Vue {
    public isValid = false;

    public $refs!: { form: any };

    public mounted() {
        this.validate();
    }

    public validate() {
        this.$nextTick(() => {
            if (!this.$refs.form) {
                return;
            }
            this.$refs.form.validate();
        });
    }

    public updated() {
        this.validate();
    }
}
