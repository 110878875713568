import SortProperty from "../data-access/SortProperty";
import PageState from "../PageState";
import IPurchasingFilters from "./IPurchasingFilters";
import PurchasingFilters from "./PurchasingFilters";

export default class GetPurchaseOrderItemDetailsArgs {
    public filters: IPurchasingFilters = new PurchasingFilters();
    public sortProperties: SortProperty[] = [];
    public pageState = new PageState();
}
