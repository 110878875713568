























import { ReportPageViewModel } from "@/chipply/view-model/ReportPageViewModel";
import EditLayout from "@/components/layout/EditLayout.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
@Component({
    components: { CToolbarBtn, EditLayout },
})
export default class ReportLayout extends Vue {
    @Prop({
        type: Object,
        default: null,
    })
    public vm!: ReportPageViewModel | null;

    public created() {
        this.vm!.isSmallScreen = this.$vuetify.breakpoint.mdAndDown;
    }

    @Watch("$vuetify.breakpoint.smAndDown")
    public onSizeChange() {
        this.vm!.isSmallScreen = this.$vuetify.breakpoint.mdAndDown;
    }
}
