import IEventProductColor from "@/chipply/products/i-event-product-color";
import IEventProductColorSizeDto from "@/chipply/products/i-event-product-color-size-dto";

export default class EventProductColorViewModel {
    public eventProductColorId: number;
    public colorName: string;
    public checked: boolean;
    public image1Url?: string;
    public image2Url?: string;
    public image3Url?: string;
    public image4Url?: string;

    constructor(model: IEventProductColor) {
        this.eventProductColorId = model.eventProductColorId;
        this.colorName = model.colorName;
        this.checked = model.checked || false;
        this.image1Url = model.image1Url;
        this.image2Url = model.image2Url;
        this.image3Url = model.image3Url;
        this.image4Url = model.image4Url;
    }

    public toModel(): IEventProductColor {
        return {
            checked: this.checked,
            colorName: this.colorName,
            eventProductColorId: this.eventProductColorId,
            image1Url: this.image1Url,
            image2Url: this.image2Url,
            image3Url: this.image3Url,
            image4Url: this.image4Url,
        };
    }
}
