














import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: {
        invalid: Boolean,
        hideCancel: Boolean,
    },
})
export default class CancelSaveButtonBarDesktop extends Vue {
    public name = "CancelSaveButtonBarDesktop";
    public chipplyIcons = chipplyIcons;

    protected close(accepted: boolean) {
        this.$emit("close", accepted);
    }
}
