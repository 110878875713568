import IColorAssignmentsModel from "@/chipply/interface/i-color-assignments-dto";
import { IEventArtworkVariation } from "@/chipply/interface/i-event-artwork-variation";
import { IProductColorAssignment } from "@/chipply/interface/i-product-color-assignment";
import { IColorAssignment } from "@/chipply/interface/IColorAssignment";
import IArtwork from "@/chipply/process/IArtwork";
import { ColorAssignmentsViewModel } from "@/chipply/store/color-assignment/ColorAssignmentsViewModel";
import { IColorAssignmentGroup } from "@/chipply/store/color-assignment/IColorAssignmentGroup";
import { typeHint, viewModel } from "chipply-common";

@typeHint({ typeHint: "ColorAssignmentsModel" })
@viewModel(ColorAssignmentsViewModel)
export class ColorAssignmentsModel implements IColorAssignmentsModel {
    public artworks: IArtwork[] = [];
    public assignmentsByColor: IColorAssignmentGroup[] = [];
    public assignmentsByProduct: IProductColorAssignment[] = [];
    public variations: IEventArtworkVariation[] = [];
    public isArtwork = false;
    public warningMessage = "";
}
