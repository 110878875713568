


















import Vue from "vue";
import Component from "vue-class-component";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { EventBus } from "@/chipply/EventBus";

@Component({
    props: {
        navigationLocation: String,
        buttonText: String,
        icon: String,
        newTab: Boolean,
    },
})
export default class NavigationTopButton extends Vue {
    public name = "NavigationTopButton";
    public navigationLocation!: string;
    public iconColor = "";
    public newTab!: boolean;

    public async buttonClicked(): Promise<void> {
        const eventArgs: IAsyncEventArgs = { promises: [] };
        EventBus.$emit("top-navigation-started", this.navigationLocation, eventArgs);
        await Promise.all(eventArgs.promises);
        if (!eventArgs.cancel) {
            if (this.newTab) {
                window.open(this.navigationLocation, "_blank");
            } else {
                window.location.assign(this.navigationLocation);
            }
        }
    }

    protected mouseOver() {
        this.iconColor = "#d12229";
    }

    protected mouseLeave() {
        this.iconColor = "";
    }
}
