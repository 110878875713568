
















































































import { FieldCollectionViewModel } from "@/chipply/fields/FieldCollectionViewModel";
import { ListFieldViewModel } from "chipply-common";
import IOptionProcessItem from "@/chipply/process/IOptionProcessItem";
import { DragDropHelper } from "@/components/DragDropHelper";
import AddFieldCard from "@/components/fields/AddFieldCard.vue";
import FormValidationMixin from "@/components/FormValidationMixin";
import EditLayout from "@/components/layout/EditLayout.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CListItemEditor from "@/components/ui/CListItemEditor.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import Sortable, { SortableEvent } from "sortablejs";
import Component, { mixins } from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import CEditButton from "../ui/CEditButton.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import Validation from "@/validation";

@Component({
    components: {
        CListItemEditor,
        AddFieldCard,
        CToolbarBtn,
        CControlLabel,
        CAddButton,
        CDeleteButton,
        CEditButton,
        EditLayout,
    },
})
export default class FieldCollectionDesigner extends mixins(FormValidationMixin) {
    public ListFieldViewModel = ListFieldViewModel;
    public Validation = Validation;

    public $refs!: {
        table: HTMLElement;
        form: any;
    };

    @Prop({
        type: Object,
    })
    public vm!: FieldCollectionViewModel | null;
    public chipplyIcons = chipplyIcons;

    public async addField() {
        await this.vm!.addField();
        this.validate();
    }
    public raiseSave() {
        this.$emit("save");
    }

    public mounted() {
        DragDropHelper.initializeDragAndDrop(this.$refs.table, this.vm, "fields", this, null);
    }
}
