










































import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: {
        text: String,
        noOverflow: Boolean,
    },
})
export default class NavigationSideHeader extends Vue {
    public name = "NavigationSideHeader";
    public text!: string;
}
