import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import IDealerEcertMergeTemplate from "@/chipply/merge/IDealerEcertMergeTemplate";
import EcertMergeTemplateViewModel from "@/chipply/merge/EcertMergeTemplateViewModel";
import { MessageType } from "@/chipply/merge/MessageType";

export default class DealerEcertMergeTemplateViewModel extends EcertMergeTemplateViewModel {
    public $typeHint = "DealerEcertMergeTemplate";

    public created(dto: IDealerEcertMergeTemplate) {
        this.setTemplate(dto);
    }
    public setTemplate(dto?: IDealerEcertMergeTemplate) {
        super.setTemplate(dto);
        this.messageTypes = [MessageType.Email];
        this.trigger = dto ? dto.trigger : EmailTemplateTriggerTypeEnum.StaticDate;
    }

    public toModel(): IDealerEcertMergeTemplate {
        const baseModel = super.toModel();
        return {
            ...baseModel,
            $typeHint: this.$typeHint,
        };
    }

    public clone() {
        const clone = new DealerEcertMergeTemplateViewModel();
        clone.setTemplate(this.toModel());
        return clone;
    }
}
