import { ActionContext } from "vuex";
import { IRootState } from "@/store/RootStore";
import { getStoreAccessors } from "vuex-typescript";

type EnvironmentContext = ActionContext<IEnvironmentState, IRootState>;

export const environmentStore = {
    namespaced: true,

    state: {
        chipplyEventId: undefined,
        previewWebsitePath: undefined,
    },

    getters: {
        getPreviewWebsitePath(state: IEnvironmentState) {
            return state.previewWebsitePath;
        },
        getChipplyEventId(state: IEnvironmentState) {
            return state.chipplyEventId;
        },
    },

    mutations: {
        setEnvironment(state: IEnvironmentState, item: IEnvironmentState) {
            state.previewWebsitePath = item.previewWebsitePath;
            state.chipplyEventId = item.chipplyEventId;
        },
    },

    actions: {
        initializeEnvironment(context: EnvironmentContext) {
            const environmentString = localStorage.getItem("envInfo");
            if (!environmentString) {
                return;
            }

            const environment = JSON.parse(environmentString);
            commitEnvironment(context, environment);
        },
    },
};

const { commit, read, dispatch } = getStoreAccessors<IEnvironmentState, IRootState>("envInfo");

const mutations = environmentStore.mutations;

export const commitEnvironment = commit(mutations.setEnvironment);

const getters = environmentStore.getters;

export const getPreviewWebsitePath = read(getters.getPreviewWebsitePath);
export const getChipplyEventId = read(getters.getChipplyEventId);
export const initialize = dispatch(environmentStore.actions.initializeEnvironment);
