






























import OrganizationContactEditViewModel from "@/chipply/organization/OrganizationContactEditViewModel";
import CCard from "@/components/ui/CCard.vue";
import CPhone from "@/components/ui/CPhone.vue";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Validation from "@/validation";
import Component from "vue-class-component";
import { mask } from "vue-the-mask";

@Component({
    components: {
        CCard,
        CPhone,
    },
    directives: {
        mask,
    },
})
export default class EditOrganizationContact extends Vue {
    @Prop({
        type: Object,
    })
    public vm!: OrganizationContactEditViewModel;
    @Prop({
        type: Boolean,
    })
    public required!: boolean;
    public Validation = Validation;
}
