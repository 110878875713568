import OrderAdjustmentViewModel from "@/chipply/ecom-orders/OrderAdjustmentViewModel";
import OrderItemViewModel from "@/chipply/ecom-orders/OrderItemViewModel";
import OrderTotalsViewModel from "@/chipply/ecom-orders/OrderTotalsViewModel";
import { IAddress, IOrder, IOrderTax } from "chipply-common";

export default class OrderViewModel {
    public orderNumber!: number;
    public voidable!: boolean;
    public isVoided!: boolean;
    public isComplete!: boolean;
    public refundable!: boolean;
    public poNumber!: string;
    public isPoOrder!: boolean;
    public couponId: number | null = null;
    public couponCode: string | null = null;
    public pickupBranchId: number | null = null;
    public organizationBranchId = 0;
    public smsOptIn!: boolean;
    public eventId!: number;
    public billingAddress!: IAddress;
    public shippingAddress!: IAddress;
    public shippingType!: "Shipping" | "Organization Delivery" | "In Store Pickup";

    public adjustments!: OrderAdjustmentViewModel[];

    public totals!: OrderTotalsViewModel;
    public originalTotals!: OrderTotalsViewModel;

    public products: OrderItemViewModel[];

    public tax!: IOrderTax;

    public forceTransaction!: boolean;
    public failedAmount!: number;

    public billingPhone!: string;
    public billingEmail!: string;
    public shipComment!: string;
    public shipError!: string;
    public useBillingAddress!: boolean;

    public get totalsEqual() {
        if (this.originalTotals.cartTotal !== this.totals.cartTotal) {
            return false;
        }
        if (this.originalTotals.couponTotal !== this.totals.couponTotal) {
            return false;
        }
        if (this.originalTotals.ecertTotal !== this.totals.ecertTotal) {
            return false;
        }
        if (this.originalTotals.orderTotal !== this.totals.ecertTotal) {
            return false;
        }
        if (this.originalTotals.chipplyFeeTotal !== this.totals.chipplyFeeTotal) {
            return false;
        }
        if (this.originalTotals.taxTotal !== this.totals.taxTotal) {
            return false;
        }
        if (this.originalTotals.shippingTotal !== this.totals.shippingTotal) {
            return false;
        }
        if (this.originalTotals.handlingTotal !== this.totals.handlingTotal) {
            return false;
        }
        return true;
    }

    constructor(order: IOrder) {
        Object.assign(this, order);

        this.totals = new OrderTotalsViewModel(order.totals);

        if (!this.isComplete && !this.isVoided) {
            this.originalTotals = new OrderTotalsViewModel();
        } else {
            this.originalTotals = new OrderTotalsViewModel(order.totals);
        }

        this.products = [];
        for (const product of order.products) {
            this.products.push(new OrderItemViewModel(product));
        }

        this.adjustments = [];
        for (const adj of order.adjustments) {
            this.adjustments.push(new OrderAdjustmentViewModel(adj));
        }
    }

    public get isEditable() {
        return !this.isVoided && !this.hasChargeLaterAdjustment && !this.hasRefundLaterAdjustment;
    }

    public get hasRefundLaterAdjustment() {
        for (const adj of this.adjustments) {
            if (adj.isRefundLater) {
                return true;
            }
        }
        return false;
    }

    public get hasChargeLaterAdjustment() {
        for (const adj of this.adjustments) {
            if (adj.isPayLater) {
                return true;
            }
        }
        return false;
    }

    public toModel(): IOrder {
        const products = [];
        for (const product of this.products) {
            products.push(product.toModel());
        }

        const adjustments = [];
        for (const adjustment of this.adjustments) {
            adjustments.push(adjustment.toModel());
        }

        return {
            adjustments,
            billingAddress: this.billingAddress,
            billingEmail: this.billingEmail,
            billingPhone: this.billingPhone,
            couponCode: this.couponCode,
            couponId: this.couponId,
            eventId: this.eventId,
            failedAmount: this.failedAmount,
            forceTransaction: this.forceTransaction,
            isComplete: this.isComplete,
            isPoOrder: this.isPoOrder,
            isVoided: this.isVoided,
            orderNumber: this.orderNumber,
            pickupBranchId: this.pickupBranchId,
            organizationBranchId: this.organizationBranchId,
            poNumber: this.poNumber,
            products,
            refundable: this.refundable,
            shipComment: this.shipComment,
            shipError: this.shipError,
            useBillingAddress: this.useBillingAddress,
            shippingAddress: this.shippingAddress,
            shippingType: this.shippingType,
            smsOptIn: this.smsOptIn,
            tax: this.tax,
            totals: this.totals.toModel(),
            voidable: this.voidable,
        };
    }
}
