















import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    props: {
        icon: Boolean,
        tooltip: String,
    },
})
export default class CAddButton extends Vue {
    public chipplyIcons = chipplyIcons;
    public tooltip!: string | null;

    @Prop({
        default: false,
        type: Boolean,
    })
    public disabled!: boolean;

    protected computedTooltip() {
        return this.tooltip || "Add";
    }
}
