






import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class TextHeading extends Vue {
    public name = "TextHeading";
    public data() {
        return {};
    }
}
