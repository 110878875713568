import { ZeroSellPriceDisplay } from "@/chipply/store/ZeroSellPriceDisplay";

export class ZeroSellPriceModel {
    public zeroSellPriceDisplay: ZeroSellPriceDisplay | null = null;
    public customLabel: string | null = null;

    public clone() {
        const clone = new ZeroSellPriceModel();
        clone.zeroSellPriceDisplay = this.zeroSellPriceDisplay;
        clone.customLabel = this.customLabel;
        return clone;
    }
}
