
























import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
import { ITextValue, WebHelper } from "chipply-common";
import _, { Cancelable } from "lodash";
import { AutocompleteType } from "@/chipply/Autocompletes";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CApiAutoComplete extends Vue {
    @Prop()
    preload!: boolean;
    @Prop()
    filters!: object;

    @Prop()
    label!: string;

    @Prop({
        type: Object,
        default: null,
    })
    type!: AutocompleteType;

    @Prop({ type: [String, Number, Object] })
    public value!: string | number | object | null;

    public name = "CApiAutoComplete";
    public items: Array<ITextValue<number>> = [];
    public selectedItem: string | number | object | null = null;
    public searchInput: string | null = null;
    public loading = false;
    protected isPreloaded = false;

    public async created() {
        if (this.preload) {
            await this.initializePreload();
        } else {
            await this.initializeServerSearch();
        }

        this.onValueChanged();
    }

    protected searchInputThrottle: ((() => Promise<void>) & Cancelable) | null = null;

    @Watch("searchInput")
    public async onSearchInputChanged() {
        await this.searchInputThrottle!();
    }

    @Watch("value")
    public onValueChanged() {
        this.selectedItem = this.value;
    }

    @Watch("selectedItem")
    public onSelectedChanged() {
        if (this.selectedItem === this.value) {
            return;
        }
        this.$emit("change", this.selectedItem);
    }

    public async initializeServerSearch() {
        this.searchInputThrottle = _.throttle(
            async () => {
                if (!this.searchInput) {
                    return;
                }
                if (this.selectedItem) {
                    return;
                }
                this.loading = true;
                const args = {
                    filters: this.filters,
                    searchInput: this.searchInput,
                };
                const response = await WebHelper.postJsonData(this.type.path, args);
                const responseJSON = JSON.parse(response);
                this.items = responseJSON.items;
                this.loading = false;
            },
            500,
            { trailing: true }
        );
    }

    public async initializePreload() {
        if (this.isPreloaded) return;
        this.loading = true;
        const args = {
            filters: this.filters,
            searchInput: this.searchInput,
            disablePaging: this.preload,
        };
        const response = await WebHelper.postJsonData(this.type.path, args);
        const responseJSON = JSON.parse(response);
        this.items = responseJSON.items;
        this.loading = false;
    }
    @Watch("preload")
    protected preloadChanged() {
        this.initializePreload();
    }
}
