
























import { ZeroSellPriceModel } from "@/chipply/store/ZeroSellPriceModel";
import { ZeroSellPriceDisplay, ZeroSellPriceDisplayHelper } from "@/chipply/store/ZeroSellPriceDisplay";
import Validation from "@/validation";
import { Utils } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
    props: {
        disabled: Boolean,
        value: Object,
    },
})
export default class ZeroSellPriceSelection extends Vue {
    public ZeroSellPriceDisplay = ZeroSellPriceDisplay;
    public Utils = Utils;
    public Validation = Validation;

    public zeroSellPriceTypes = ZeroSellPriceDisplayHelper.getList();

    public value!: ZeroSellPriceModel;
}
