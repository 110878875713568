import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import IAutomatedMergeTemplate from "@/chipply/merge/IAutomatedMergeTemplate";
import IMergeEmailRecipient from "@/chipply/merge/IMergeEmailRecipient";
import IMergeTemplateViewModel from "@/chipply/merge/IMergeTemplateViewModel";
import IMergeTemplateSms from "@/chipply/merge/IMergeTemplateSms";
import { EventType } from "@/chipply/event/EventType";
import { MessageType } from "@/chipply/merge/MessageType";
import { ITextValue } from "chipply-common";
import { DateTime } from "luxon";
import IStoreCustomerMergeTemplate from "@/chipply/merge/IStoreCustomerMergeTemplate";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";

export default abstract class AutomatedMergeTemplateViewModel implements IMergeTemplateViewModel {
    public bccRecipients: IMergeEmailRecipient[] = [];
    public replyToRecipients: IMergeEmailRecipient[] = [];
    public days = 0;
    public emailContent = "";
    public hours = 0;
    public id = 0;
    public isMessageSms = false;
    public isMessageEmail = false;
    public messageTypes: MessageType[] = [];
    public prettyPrintDateTime: string | null = null;
    public staticDate: string | null = null;
    public staticTime: string | null = null;
    public subject = "";
    public sms: IMergeTemplateSms | null = null;
    public templateName = "";
    public toRecipients: IMergeEmailRecipient[] = [];
    public supportsSms = false;
    public copyToStore = false;
    public abstract templateType: MergeTemplateType;
    public abstract templateTypeDisplay: string;
    public trigger: EmailTemplateTriggerTypeEnum = EmailTemplateTriggerTypeEnum.AfterCloseDate;
    public abstract get smsMergeFields(): Array<ITextValue<string>>;

    public constructor() {}

    public getDate(date: string, time: string) {
        let countdownDate = DateTime.fromISO(date!);
        const offset = countdownDate.offset;
        const millis = countdownDate.toMillis();
        const hour = parseInt(time ? time.split(":")[0] : "0", 10);
        const minute = parseInt(time ? time.split(":")[1] : "0", 10);
        countdownDate = countdownDate.plus({ hours: hour, minutes: minute });
        if (countdownDate.offset !== offset) {
            // Needed to handle when the addition crosses a DST change, e.g. 3/14/2021, 11/7/2021
            const offsetAdjustment = offset - countdownDate.offset;
            countdownDate = countdownDate.plus({ minutes: offsetAdjustment });
        }
        return countdownDate.toISO();
    }

    public toModel(): IAutomatedMergeTemplate {
        const copy: IAutomatedMergeTemplate = {
            $typeHint: "",
            bccRecipients: [],
            replyToRecipients: [],
            days: this.days,
            emailContent: this.emailContent,
            hours: this.hours,
            id: this.id,
            messageTypes: this.messageTypes,
            prettyPrintDateTime: this.prettyPrintDateTime,
            sms: this.sms,
            staticDate: this.staticDate,
            staticTime: this.staticTime,
            subject: this.subject,
            templateName: this.templateName,
            toRecipients: [],
            trigger: this.trigger,
        };

        for (const toRecipient of this.toRecipients) {
            copy.toRecipients.push({
                customEmailAddress: toRecipient.customEmailAddress,
                recipientType: toRecipient.recipientType,
            });
        }

        for (const bccRecipient of this.bccRecipients) {
            copy.bccRecipients.push({
                customEmailAddress: bccRecipient.customEmailAddress,
                recipientType: bccRecipient.recipientType,
            });
        }

        for (const replyToRecipients of this.replyToRecipients) {
            copy.replyToRecipients.push({
                customEmailAddress: replyToRecipients.customEmailAddress,
                recipientType: replyToRecipients.recipientType,
            });
        }

        return copy;
    }
    public setTemplate(dto?: IAutomatedMergeTemplate) {
        this.bccRecipients = dto ? dto.bccRecipients : [];
        this.replyToRecipients = dto ? dto.replyToRecipients : [];
        this.days = dto ? dto.days : 0;
        this.emailContent = dto ? dto.emailContent : "";
        this.hours = dto ? dto.hours : 0;
        this.id = dto ? dto.id : 0;
        this.templateName = dto ? dto.templateName : "";

        if (dto) {
            this.prettyPrintDateTime = null;
            this.staticDate = null;
            this.staticTime = null;
            this.messageTypes = dto.messageTypes;
            this.subject = dto.subject;
            this.sms = dto.sms || { body: "", recipients: [] };
            this.toRecipients = dto.toRecipients;
            this.trigger = dto.trigger;

            this.isMessageEmail = dto.messageTypes ? dto.messageTypes.indexOf(MessageType.Email) > -1 : true;
            this.isMessageSms = dto.messageTypes ? dto.messageTypes.indexOf(MessageType.Sms) > -1 : true;

            if (dto.staticDate) {
                this.prettyPrintDateTime = DateTime.fromISO(dto.staticDate).toLocaleString(DateTime.DATETIME_MED);
                this.staticDate = DateTime.fromISO(dto.staticDate!).toFormat("yyyy-MM-dd");
                this.staticTime = DateTime.fromISO(dto.staticDate!).toLocaleString(DateTime.TIME_24_SIMPLE);
            }
        } else {
            this.prettyPrintDateTime = null;
            this.staticDate = null;
            this.staticTime = null;
            this.messageTypes = [MessageType.Email, MessageType.Sms];
            this.subject = "";
            this.sms = { body: "", recipients: [] };
            this.templateName = "";
            this.toRecipients = [];
            this.trigger = EmailTemplateTriggerTypeEnum.AfterCloseDate;
            this.isMessageEmail = true;
            this.isMessageSms = true;
        }
    }
    public abstract clone(): IMergeTemplateViewModel;

    public loadEditData(): Promise<void> {
        return Promise.resolve(undefined);
    }
    protected setDate(model: IAutomatedMergeTemplate) {
        if (model.trigger !== EmailTemplateTriggerTypeEnum.StaticDate) {
            return;
        }

        model.staticDate = this.getDate(model.staticDate!, model.staticTime!);
    }
    abstract get htmlBodyMergeFields(): Array<ITextValue<string>>;

    abstract get htmlSubjectMergeFields(): Array<ITextValue<string>>;
}
