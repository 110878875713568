
































import chipplyIcons from "@/chipply/ImportIcons";
import IMergeEmailRecipient from "@/chipply/merge/IMergeEmailRecipient";
import Validation from "@/validation";
import Component from "vue-class-component";
import Vue from "vue";
import { MergeTemplateRecipientType } from "@/chipply/merge/MergeTemplateRecipientType";
import { mask } from "vue-the-mask";
import CDeleteButton from "../ui/CDeleteButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import AddButton from "@/components/ui/CAddButton.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import { Utils } from "chipply-common";

@Component({
    components: {
        CAddButton,
        CControlLabel,
        CDeleteButton,
    },
    directives: { mask },
    props: {
        listTitle: String,
        recipients: Array,
    },
})
export default class PhoneRecipientListEditor extends Vue {
    public MergeTemplateRecipientType = MergeTemplateRecipientType;
    public Utils = Utils;
    public Validation = Validation;
    public chipplyIcons = chipplyIcons;
    public name = "PhoneRecipientListEditor";
    public recipients: IMergeEmailRecipient[] | undefined;
    public recipientTypes = [
        { text: "Custom Phone Number", value: 0 },
        { text: "Organization Contact", value: 1 },
        { text: "Store Salesperson", value: 2 },
        { text: "Contact Information", value: 3 },
    ];

    protected removeItem(itemIndex: number) {
        (this.recipients as IMergeEmailRecipient[]).splice(itemIndex, 1);
    }

    protected addItem() {
        (this.recipients as IMergeEmailRecipient[]).push({
            recipientType: 0,
            customEmailAddress: "",
        });
    }
}
